import { AfterViewInit, ChangeDetectorRef, DestroyRef, Directive, ElementRef, Input } from '@angular/core';
import { OverlayPanelComponent } from '../components/overlay-panel/overlay-panel.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
    selector: '[escOverlayPanelTriggerFor]',
    exportAs: 'escOverlayPanelTriggerFor',
    standalone: true,
    host: {
        '(click)': 'toggleDropdown()',
    },
})
export class OverlayPanelTriggerForDirective implements AfterViewInit {
    public get isDropdownOpen(): boolean {
        return this.overlayPanel?.open();
    }

    @Input('escOverlayPanelTriggerFor') public overlayPanel!: OverlayPanelComponent;

    constructor(
        private elementRef: ElementRef<HTMLElement>,
        private _cd: ChangeDetectorRef,
        private _destroyRef: DestroyRef
    ) {}

    public ngAfterViewInit(): void {
        this.overlayPanel.connectedTo.set(this.elementRef?.nativeElement);
        this.overlayPanel.closed.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
            this._cd.detectChanges();
        });
    }

    public toggleDropdown(): void {
        this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
    }

    openDropdown(): void {
        this.overlayPanel.open.set(true);
    }

    public destroyDropdown(): void {
        this.overlayPanel?.open.set(false);

        setTimeout(() => {
            this._cd.detectChanges();
        });
    }
}
