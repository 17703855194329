import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'esc-section',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './section.component.html',
    styleUrl: './section.component.scss',
    host: {
        class: 'esc-section',
        role: 'section',
        ariaLabel: 'section',
    },
})
export class SectionComponent {}

/**
 * escStateLoader
 *
 *
 */
