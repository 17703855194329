import { RowDefDirective } from '../../directives/row-def.directive';
import {
    AfterContentChecked,
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    EmbeddedViewRef,
    Inject,
    Input,
    IterableChangeRecord,
    IterableDiffer,
    IterableDiffers,
    OnDestroy,
    OnInit,
    QueryList,
    TemplateRef,
    TrackByFunction,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';

import { BehaviorSubject, fromEvent, isObservable, Observable, of as observableOf, Subject, Subscription, takeUntil } from 'rxjs';
import {
    _COALESCED_STYLE_SCHEDULER,
    _CoalescedStyleScheduler,
    BaseRowDef,
    CDK_TABLE,
    CdkCellOutlet,
    CdkTable,
    CdkTableModule,
    DataRowOutlet,
    FooterRowOutlet,
    HeaderRowOutlet,
    NoDataRowOutlet,
    RowOutlet,
    StickyStyler,
} from '@angular/cdk/table';

import _ from 'lodash';
import { ColumnDefDirective } from '../../directives/column-def.directive';
import { HeaderRowDefDirective } from '../../directives/header-row-def.directive';
import { FooterRowDefDirective } from '../../directives/footer-row-def.directive';
import { isDataSource } from '@angular/cdk/collections';
import { Direction } from '@angular/cdk/bidi';
import { Platform } from '@angular/cdk/platform';
import { CommonModule, DOCUMENT } from '@angular/common';
import { EscDataSource } from '../../../data-and-collections/classes/data-source/data-source';

import { Document } from 'postcss';
import { CdkScrollable } from '@angular/cdk/overlay';

export interface IRenderRow<T> {
    data: T;
    dataIndex: number;
}

export interface IRowContext<T> {
    $implicit?: T;
    index?: number;
    count?: number;
    first?: boolean;
    last?: boolean;
    even?: boolean;
    odd?: boolean;
}

export enum ETableStatus {
    None,
    Loading,
    Empty,
    Initialized,
}

@Component({
    selector: 'esc-table',
    exportAs: 'escTable',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss'],
    host: {
        class: 'esc-table esc-table__table',
        ngSkipHydration: 'true',
        '[class.is-full-width]': 'fullWidth',
        '[class.is-virtual-scroll]': 'enableVirtualScroll',
    },
    providers: [
        { provide: CdkTable, useExisting: TableComponent },
        { provide: CDK_TABLE, useExisting: TableComponent },
        { provide: _COALESCED_STYLE_SCHEDULER, useClass: _CoalescedStyleScheduler },
        // {provide: _VIEW_REPEATER_STRATEGY, useClass: _DisposeViewRepeaterStrategy},
        // {provide: STICKY_POSITIONING_LISTENER, useValue: null},
    ],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [CommonModule, CdkTableModule, CdkScrollable],
})
export class TableComponent<T> implements OnInit, OnDestroy, AfterContentInit, AfterContentChecked {
    public status: ETableStatus = ETableStatus.Empty;
    public useFilters = false;

    @Input()
    get dataSource(): readonly T[] | EscDataSource<T> | Observable<readonly T[]> {
        return this._dataSource;
    }

    set dataSource(dataSource: readonly T[] | EscDataSource<T> | Observable<readonly T[]>) {
        if (this._dataSource !== dataSource) {
            this._switchDataSource(dataSource);
        }
    }

    private _dataSource!: readonly T[] | EscDataSource<T> | Observable<readonly T[]>;

    @ViewChild('scroll', { static: true })
    public scrollElementRef!: ElementRef<HTMLElement>;
    @ViewChild('tableContentInner', { static: true })
    private _tableContentInner!: ElementRef<HTMLElement>;
    @ViewChild('tableContent', { static: true })
    private _tableContent!: ElementRef<HTMLElement>;

    private _data!: readonly T[];
    private _renderRows!: IRenderRow<T>[];

    private _dataDiffer!: IterableDiffer<IRenderRow<T>>;
    private _cachedRenderRowsMap = new Map<T, IRenderRow<T>>();
    // private _cachedRenderRowsMap = new Map<T, WeakMap<RowDefDirective<T>, IRenderRow<T>[]>>();
    private _columnDefsByName = new Map<string, ColumnDefDirective>();

    /** virtual scrolling */
    private _fromIndex = 0;
    private _toIndex = 20;
    public _virtualScrollHeight = 0;
    public _virtualScrollTopIndex = 0;
    @Input() public enableStickyHeader = true;
    @Input() public enableVirtualScroll = true;
    @Input() public virtualScrollOffset = 4;
    @Input() public columnHeight = 48;
    @Input() public emptyText = 'Brak danych';
    @Input() public emptyFilterText = 'Nie znaleziono pasujących wyników';
    @Input() public fullWidth = false;

    //
    private destroy$: Subject<boolean> = new Subject<boolean>();

    @Input()
    get trackBy(): TrackByFunction<T> {
        return this._trackByFn;
    }

    set trackBy(fn: TrackByFunction<T>) {
        if (fn != null && typeof fn !== 'function') {
            console.warn(`trackBy must be a function, but received ${JSON.stringify(fn)}.`);
        }
        this._trackByFn = fn;
    }

    private _trackByFn!: TrackByFunction<T>;

    //??
    readonly viewChange = new BehaviorSubject<{ start: number; end: number }>({
        start: 0,
        end: Number.MAX_VALUE,
    });
    private _renderChangeSubscription?: Subscription | null;

    // outlets
    @ViewChild(DataRowOutlet, { static: true }) _rowOutlet!: DataRowOutlet;
    @ViewChild(HeaderRowOutlet, { static: true }) _headerRowOutlet!: HeaderRowOutlet;
    @ViewChild(FooterRowOutlet, { static: true }) _footerRowOutlet!: FooterRowOutlet;
    @ViewChild(NoDataRowOutlet, { static: true }) _noDataRowOutlet!: NoDataRowOutlet;

    @ContentChildren(ColumnDefDirective, { descendants: true })
    _contentColumnDefs!: QueryList<ColumnDefDirective>;
    @ContentChild(RowDefDirective) _contentRowDef!: RowDefDirective<T>;
    @ContentChild(HeaderRowDefDirective)
    _contentHeaderRowDef!: HeaderRowDefDirective;
    @ContentChild(FooterRowDefDirective)
    _contentFooterRowDef!: FooterRowDefDirective;

    /**
     * sticky logic
     */
    private _stickyColumnStylesNeedReset = true;
    public headerRowWidth = 0;
    private _stickyStyler!: StickyStyler;

    /**
     *
     */
    private _headerRowDefChanged = true;
    private _footerRowDefChanged = true;

    constructor(
        protected readonly _differs: IterableDiffers,
        protected readonly _elementRef: ElementRef,
        @Inject(_COALESCED_STYLE_SCHEDULER)
        protected readonly _coalescedStyleScheduler: _CoalescedStyleScheduler,
        @Inject(DOCUMENT) _document: Document,
        private _platform: Platform,
        private _cd: ChangeDetectorRef
    ) {}

    public ngOnInit(): void {
        this._setupStickyStyler();

        this._dataDiffer = this._differs.find([]).create((_i: number, dataRow: IRenderRow<T>) => {
            return this.trackBy ? this.trackBy(dataRow.dataIndex, dataRow.data) : dataRow;
        });

        fromEvent(window, 'scroll')
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                // if (this._headerViewRef) {
                //     this._headerViewRef.detectChanges();
                // }

                if (this.enableVirtualScroll) {
                    if (this.parseVirtualScroll()) {
                        this.renderRows();
                    }
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();

        if (isDataSource(this.dataSource)) {
            this.dataSource.disconnect();
        }
    }

    public ngAfterContentInit(): void {
        if (this.scrollElementRef) {
            let ignoreScroll = false;
            fromEvent([this.scrollElementRef.nativeElement, this._tableContent.nativeElement], 'scroll')
                .pipe(takeUntil(this.destroy$))
                .subscribe(event => {
                    if (ignoreScroll) {
                        ignoreScroll = false;
                        return;
                    }

                    const target = event.target as HTMLElement;
                    const headerRow = this.getRenderedRows(this._headerRowOutlet)[0];

                    headerRow.scrollLeft = target.scrollLeft;
                    ignoreScroll = true;

                    if (this._tableContent.nativeElement.scrollLeft !== this.scrollElementRef.nativeElement.scrollLeft) {
                        this.scrollElementRef.nativeElement.scrollLeft = target.scrollLeft;
                        this._tableContent.nativeElement.scrollLeft = target.scrollLeft;
                    }
                });
        }
    }

    public ngAfterContentChecked() {
        this._cacheColumnDefs();

        if (this._headerRowDefChanged) {
            this.renderHeaderRow();
            this._headerRowDefChanged = false;
        }

        if (this.dataSource && !this._renderChangeSubscription) {
            this._observeRenderChanges();
        } else if (this._stickyColumnStylesNeedReset) {
            this.updateStickyColumnStyles();
        }

        if (this.enableStickyHeader) {
            this._checkStickyStates();
        }
        this.updateHeaderRowWidth();

        // if (this.parseVirtualScroll()) {
        //     this.renderRows();
        // }
    }

    public renderRows() {
        this._renderRows = this._getAllRenderRows();

        const changes = this._dataDiffer.diff(this._renderRows);
        if (!changes) {
            this.updateVirtualScrollHeight();

            return;
        }
        const viewContainer = this._rowOutlet.viewContainer;

        // this._viewRepeater.applyChanges(
        //     changes,
        //     viewContainer,
        //     (
        //         record: IterableChangeRecord<IRenderRow<T>>,
        //         _adjustedPreviousIndex: number | null,
        //         currentIndex: number | null,
        //     ) => this._getEmbeddedViewArgs(record.item, currentIndex!),
        //     record => record.item.data,
        //     (change: _ViewRepeaterItemChange<IRenderRow<T>, IRowContext<T>>) => {
        //         if (change.operation === _ViewRepeaterOperation.INSERTED && change.context) {
        //             setTimeout(() => {
        //                 this._renderCellTemplateForItem(change.record.item.row, change.context);
        //             })
        //         }
        //     },
        // );

        changes.forEachOperation((record: IterableChangeRecord<IRenderRow<T>>, prevIndex: number | null, currentIndex: number | null) => {
            if (record.previousIndex === null) {
                this.renderRow(record.item, currentIndex!);
            } else if (currentIndex === null) {
                viewContainer.remove(prevIndex!);
            } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const view = viewContainer.get(prevIndex!) as any;
                view.context.index = currentIndex + 1;
                viewContainer.move(view!, currentIndex);
            }
        });

        // Update the meta context of a row's context data (index, count, first, last, ...)
        this._updateRowIndexContext();

        // Update rows that did not get added/removed/moved but may have had their identity changed,
        // e.g. if trackBy matched data on some property but the actual data reference changed.
        changes.forEachIdentityChange((record: IterableChangeRecord<IRenderRow<T>>) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const rowView = viewContainer.get(record.currentIndex!) as any;
            rowView.context.$implicit = record.item.data;
            rowView.detectChanges();
        });

        this.updateVirtualScrollHeight();
        this.updateStickyColumnStyles();

        this._cd.detectChanges();
        // this.contentChanged.next();
    }

    public updateVirtualScrollHeight(): void {
        if (this.enableVirtualScroll) {
            if (this._tableContentInner) {
                this._tableContentInner.nativeElement.style.top = this._virtualScrollTopIndex + 'px';
            }

            if (this._tableContent) {
                this._tableContent.nativeElement.style.height = this.status === ETableStatus.Initialized ? this._virtualScrollHeight + 'px' : 'auto';
            }
        }
    }

    public updateStickyHeaderRowStyles(): void {
        const headerRows = this.getRenderedRows(this._headerRowOutlet);

        for (const row of headerRows) {
            row.classList.add('is-sticky');
        }
        // const stickyStates = this._headerRowDefs.map(def => def.sticky);
        // this._stickyStyler.clearStickyPositioning(headerRows, ['top']);
        // this._stickyStyler.stickRows(headerRows, stickyStates, 'top');

        // Reset the dirty state of the sticky input change since it has been used.
        this._contentHeaderRowDef.resetStickyChanged();
    }

    public updateHeaderRowWidth(): void {
        const headerRows = this.getRenderedRows(this._headerRowOutlet);

        for (const row of headerRows) {
            this.headerRowWidth = row.scrollWidth - 8;
        }
    }

    public getRenderedRows(rowOutlet: RowOutlet): HTMLElement[] {
        const renderedRows: HTMLElement[] = [];

        for (let i = 0; i < rowOutlet.viewContainer.length; i++) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const viewRef = rowOutlet.viewContainer.get(i)! as EmbeddedViewRef<any>;
            renderedRows.push(viewRef.rootNodes[0]);
        }

        return renderedRows;
    }

    private _getAllRenderRows(): IRenderRow<T>[] {
        if (!this._data) {
            return [];
        }

        const rows: IRenderRow<T>[] = [];

        const prevCachedRenderRows = this._cachedRenderRowsMap;
        this._cachedRenderRowsMap = new Map();

        const toIndex = Math.min(this._toIndex > 0 ? this._toIndex : this._data.length, this._data.length);

        for (let i = this._fromIndex; i < toIndex; i++) {
            const { key, row } = this._getRenderRowFromCache(i, this._data[i], prevCachedRenderRows);
            this._cachedRenderRowsMap.set(key, row);
            rows.push(row);
        }

        return rows;
    }

    private _getRenderRowFromCache(
        index: number,
        data: T,
        cache: Map<T, IRenderRow<T>>
    ): {
        key: T;
        row: IRenderRow<T>;
    } {
        if (cache) {
            let cachedRow = cache.get(data);

            if (!cachedRow) {
                cache.forEach((row, key) => {
                    if (cachedRow) {
                        return;
                    }

                    if (_.isEqual(data, key)) {
                        cachedRow = row;
                    }
                });
            }

            if (cachedRow) {
                return { key: data, row: cachedRow };
            }
        }

        return {
            key: data,
            row: {
                data,
                dataIndex: index,
            },
        };
    }

    private parseVirtualScroll(): boolean {
        const length = this._data ? this._data.length : 0;

        if (!this.enableVirtualScroll) {
            this._virtualScrollHeight = length * this.columnHeight;
            this._virtualScrollTopIndex = 0;

            this._fromIndex = 0;
            this._toIndex = length;

            return true;
        }

        const maxVisibleElement = Math.floor(window.innerHeight / this.columnHeight) + this.virtualScrollOffset * 2;
        const element = this._elementRef.nativeElement;
        const rect = element.getBoundingClientRect();

        const top = rect.top * -1;

        const fromIndex = Math.floor(top / this.columnHeight - this.virtualScrollOffset);

        const toIndex = Math.min(fromIndex + maxVisibleElement, length);

        this._virtualScrollHeight = length * this.columnHeight;
        this._virtualScrollTopIndex = Math.max(fromIndex, 0) * this.columnHeight;

        if (fromIndex === this._fromIndex && toIndex === this._toIndex) {
            return false;
        }
        const cachedFromIndex = this._fromIndex;
        const cachedToIndex = this._toIndex;

        this._fromIndex = Math.max(0, fromIndex);
        this._toIndex = Math.max(2, toIndex);

        return cachedFromIndex != this._fromIndex || cachedToIndex != this._toIndex;
    }

    private _switchDataSource(dataSource: readonly T[] | EscDataSource<T> | Observable<readonly T[]>) {
        this._data = [];

        if (isDataSource(this.dataSource)) {
            this.dataSource.disconnect();
        }

        // Stop listening for data from the previous data source.
        if (this._renderChangeSubscription) {
            this._renderChangeSubscription.unsubscribe();
            this._renderChangeSubscription = null;
        }

        if (!dataSource) {
            if (this._dataDiffer) {
                this._dataDiffer.diff([]);
            }
            this._rowOutlet.viewContainer.clear();
        }

        this._dataSource = dataSource;
    }

    private _observeRenderChanges() {
        if (!this.dataSource) {
            return;
        }

        let dataStream: Observable<readonly T[]> | undefined;

        if (isDataSource(this.dataSource)) {
            dataStream = this.dataSource.connect();
        } else if (isObservable(this.dataSource)) {
            dataStream = this.dataSource;
        } else if (Array.isArray(this.dataSource)) {
            dataStream = observableOf(this.dataSource);
        }

        if (dataStream === undefined) {
            throw '';
        }

        this._renderChangeSubscription = dataStream!.pipe(takeUntil(this.destroy$)).subscribe(data => {
            this._data = data || [];

            const cachedStatus = this.status;

            if (!data) {
                this.status = ETableStatus.Loading;
            } else if (data.length === 0) {
                this.status = ETableStatus.Empty;
            } else {
                this.status = ETableStatus.Initialized;
            }

            if (cachedStatus !== this.status) {
                this._cd.detectChanges();
            }

            if (isDataSource(this.dataSource)) {
                this.useFilters =
                    this.dataSource.fullLength > 0 &&
                    !!(this.dataSource.currentSearchQuery || this.dataSource.getActiveFilterKeys() || this.dataSource.getEnabledFilters());
            }

            // TODO: ???
            this.parseVirtualScroll();
            this.renderRows();

            setTimeout(() => {
                this.parseVirtualScroll();
                this.renderRows();
            });
        });
    }

    private _updateRowIndexContext() {
        const viewContainer = this._rowOutlet.viewContainer;
        for (let renderIndex = 0, count = viewContainer.length; renderIndex < count; renderIndex++) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const viewRef = viewContainer.get(renderIndex) as any;
            const context = viewRef.context as IRowContext<T>;
            context.count = count;
            context.first = renderIndex === 0;
            context.last = renderIndex === count - 1;
            context.even = renderIndex % 2 === 0;
            context.odd = !context.even;
            context.index = renderIndex;
        }
    }

    private _cacheColumnDefs() {
        this._columnDefsByName.clear();

        const columnDefs = this._contentColumnDefs;

        columnDefs.forEach(columnDef => {
            if (this._columnDefsByName.has(columnDef.name)) {
                throw '';
            }
            this._columnDefsByName.set(columnDef.name, columnDef);
        });
    }

    public baseRenderRow(index: number, rowDef: BaseRowDef, outlet: RowOutlet, context: IRowContext<T> = {}) {
        const rowRef = outlet.viewContainer.createEmbeddedView(rowDef.template, context, index);

        if (CdkCellOutlet.mostRecentCellOutlet) {
            const outs = CdkCellOutlet.mostRecentCellOutlet;
            for (const cellTemplate of this._getCellTemplates(rowDef)) {
                // if (row.dataIndex < this._fromIndex || row.dataIndex > this._toIndex) {
                //     return;
                // }
                outs._viewContainer.createEmbeddedView(cellTemplate, context);
            }
        }

        rowRef.detectChanges();
    }

    /** Update the list of all available row definitions that can be used. */
    public renderRow(row: IRenderRow<T>, index: number): void {
        const context = {
            $implicit: row.data,
        };

        return this.baseRenderRow(index, this._contentRowDef, this._rowOutlet, context);
    }

    public renderHeaderRow(): void {
        return this.baseRenderRow(0, this._contentHeaderRowDef, this._headerRowOutlet);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _getCellTemplates(rowDef: BaseRowDef): TemplateRef<any>[] {
        if (!rowDef || !rowDef.columns) {
            return [];
        }
        return Array.from(rowDef.columns, columnId => {
            const column = this._columnDefsByName.get(columnId);

            if (!column) {
                throw '';
            }

            return rowDef.extractCellTemplate(column!);
        });
    }

    private _checkStickyStates() {
        const stickyCheckReducer = (acc: boolean, d: HeaderRowDefDirective | FooterRowDefDirective | ColumnDefDirective) => {
            return acc || d.hasStickyChanged();
        };

        // Note that the check needs to occur for every definition since it notifies the definition
        // that it can reset its dirty state. Using another operator like `some` may short-circuit
        // remaining definitions and leave them in an unchecked state.

        if (this._contentHeaderRowDef.hasStickyChanged()) {
            this.updateStickyHeaderRowStyles();
        }

        // if (this._contentFooterRowDef.hasStickyChanged()) {
        //     this.updateStickyFooterRowStyles();
        // }

        if (Array.from(this._columnDefsByName.values()).reduce(stickyCheckReducer, false)) {
            this._stickyColumnStylesNeedReset = true;
            this.updateStickyColumnStyles();
        }
    }

    updateStickyColumnStyles() {
        const headerRows = this.getRenderedRows(this._headerRowOutlet);
        const dataRows = this.getRenderedRows(this._rowOutlet);
        const footerRows = this.getRenderedRows(this._footerRowOutlet);

        // For tables not using a fixed layout, the column widths may change when new rows are rendered.
        // In a table using a fixed layout, row content won't affect column width, so sticky styles
        // don't need to be cleared unless either the sticky column config changes or one of the row
        // defs change.
        if (this._stickyColumnStylesNeedReset) {
            // Clear the left and right positioning from all columns in the table across all rows since
            // sticky columns span across all table sections (header, data, footer)
            this._stickyStyler.clearStickyPositioning([...headerRows, ...dataRows, ...footerRows], ['left', 'right']);
            this._stickyColumnStylesNeedReset = false;
        }

        // Update the sticky styles for each header row depending on the def's sticky state
        // headerRows.forEach((headerRow, i) => {});
        this._addStickyColumnStyles(headerRows, this._contentHeaderRowDef);
        this._addStickyColumnStyles(dataRows, this._contentRowDef);

        if (this._contentFooterRowDef) {
            this._addStickyColumnStyles(footerRows, this._contentFooterRowDef);
        }

        // Reset the dirty state of the sticky input change since it has been used.
        Array.from(this._columnDefsByName.values()).forEach(def => def.resetStickyChanged());
    }

    private _addStickyColumnStyles(rows: HTMLElement[], rowDef: BaseRowDef) {
        const columnDefs = Array.from(rowDef.columns || []).map(columnName => {
            const columnDef = this._columnDefsByName.get(columnName);
            if (!columnDef) {
                return;
            }
            return columnDef!;
        });
        const stickyStartStates = columnDefs.map(columnDef => columnDef!.sticky);
        const stickyEndStates = columnDefs.map(columnDef => columnDef!.stickyEnd);
        this._stickyStyler.updateStickyColumns(rows, stickyStartStates, stickyEndStates, false);
    }

    /*
     * Sticky logic
     **/

    private _setupStickyStyler() {
        const direction: Direction = 'ltr';
        this._stickyStyler = new StickyStyler(false, 'esc-table-sticky', direction, this._coalescedStyleScheduler, this._platform.isBrowser, true);
    }

    public _outletAssigned(): void {}
    public _getCellRole(): void {}
}
