import { Component, Input } from '@angular/core';

@Component({
    selector: 'esc-collection-capsule-summary',
    templateUrl: './collection-capsule-summary.component.html',
    styleUrls: ['./collection-capsule-summary.component.scss'],
    standalone: true,
})
export class CollectionCapsuleSummaryComponent {
    @Input({ required: true }) public escTitle!: string;
}
