<label
    #label
    class="esc-checkbox-wrapper"
    [ngClass]="checkboxClass"
    [class.is-checked]="checked"
    [class.is-checkbox-hidden]="checkboxHidden"
    [class.is-disabled]="escDisabled">
    <div class="esc-checkbox">
        <input
            #input
            class="checkbox"
            type="checkbox"
            [class.is-checked]="checked"
            [class.is-disabled]="escDisabled"
            [required]="required"
            [disabled]="escDisabled"
            (blur)="_onBlur()"
            (change)="_onInteractionEvent($event)"
            (click)="_handleInputClick()" />
    </div>

    <div class="esc-checkbox-wrapper__content">
        <ng-content></ng-content>
    </div>
</label>
