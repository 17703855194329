import { AfterViewInit, Component, DestroyRef, ElementRef, Input, ViewChild } from '@angular/core';
import { Observable, of, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { EscSelectionModelDeprecated } from '../../classes/esc-selection-model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from '../../../common/directives/tooltip.directive';

@Component({
    selector: 'esc-collection-item-checkbox',
    templateUrl: './collection-item-checkbox.component.html',
    standalone: true,
    imports: [CommonModule, TooltipDirective],
})
export class CollectionItemCheckboxComponent<T> implements AfterViewInit {
    @ViewChild('checkbox', { static: true })
    private _checkbox?: ElementRef<HTMLInputElement>;

    @Input({ required: true }) public selectionModel!: EscSelectionModelDeprecated<T>;
    @Input() public value!: T;

    protected disabled$!: Observable<boolean>;
    protected disabledTooltip$!: Observable<string | undefined>;

    constructor(private _destroyRef: DestroyRef) {}

    public ngAfterViewInit(): void {
        if (this.selectionModel) {
            this.selectionModel.changed.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(() => {
                if (this._checkbox) {
                    if (this.value) {
                        this._checkbox.nativeElement.checked = this.selectionModel.isSelected(this.value);
                    } else {
                        this._checkbox.nativeElement.checked = this.selectionModel.availableCollectionLength > 0 && this.selectionModel.isSelectedAll;
                    }
                }
            });

            this.disabled$ =
                this.selectionModel.source?.connect().pipe(
                    startWith(null),
                    map(() => {
                        if (this.value) {
                            if (this.selectionModel.disableFunc) {
                                return this.selectionModel.disableFunc(this.value);
                            } else {
                                return false;
                            }
                        } else {
                            return !(this.selectionModel.availableCollectionLength > 0);
                        }
                    })
                ) || of(false);

            this.disabledTooltip$ =
                this.disabled$.pipe(
                    map(v => (v && this.value && this.selectionModel.disableMessageFunc ? this.selectionModel.disableMessageFunc(this.value) : undefined))
                ) || of(undefined);
        }
    }

    public onCheckboxChange(event: Event): void {
        const checkbox = event.target as HTMLInputElement;

        if (this.value) {
            this.selectionModel.toggle(this.value, checkbox.checked);
        } else {
            this.selectionModel.toggleAll();
        }
    }
}
