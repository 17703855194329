@if (parent.multiple()) {
    <div class="esc-option__checkbox">
        <div class="esc-checkbox">
            <input #checkbox class="checkbox" type="checkbox" [checked]="isSelected()" tabindex="-1" />
        </div>
    </div>
}
<div class="esc-option__content" #content>
    <ng-content></ng-content>
</div>

@if (!parent.multiple()) {
    <div class="esc-option__checkbox">
        @if (isSelected()) {
            <esc-icon [icon]="'check'" small></esc-icon>
        }
    </div>
}
