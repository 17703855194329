import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'esc-section-content, esc-section-header',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './section-content.component.html',
    styleUrl: './section-content.component.scss',
    host: {
        class: 'esc-section-content',
    },
})
export class SectionContentComponent {
    @Input() public fluid: boolean = false;
}
