import { Component, ElementRef, input, InputSignal, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EscToastsStateService, IToast } from '../toasts-state.service';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'esc-toast',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './toast.component.html',
    styleUrl: './toast.component.scss',
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class]': '"esc-toast is-" + data().type',
    },
})
export class ToastComponent {
    public data: InputSignal<IToast> = input.required();

    constructor(
        private _toastsState: EscToastsStateService,
        private _elementRef: ElementRef<HTMLElement>
    ) {
        fromEvent(this._elementRef.nativeElement, 'mouseenter')
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                if (this.data()) {
                    this.data().timer.pause();
                }
            });

        fromEvent(this._elementRef.nativeElement, 'mouseleave')
            .pipe(takeUntilDestroyed())
            .subscribe(() => {
                if (this.data()) {
                    this.data().timer.resume();
                }
            });
    }

    close() {
        this._toastsState.remove(this.data().id);
    }
}
