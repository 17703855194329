import {
    booleanAttribute,
    Component,
    computed,
    ElementRef,
    inject,
    input,
    InputSignal,
    InputSignalWithTransform,
    signal,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ESC_OPTION_PARENT_COMPONENT, EscOptionParentComponent } from './option-parent';
import { IconComponent } from '../../../../common/components/icon/icon.component';
import { DisabledStateDirective } from '../../../directives/disabled-state.directive';

let nextId = 0;

@Component({
    selector: 'esc-option',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule, IconComponent],
    host: {
        class: 'esc-option',
        role: 'option',
        '[class.is-sticked]': 'sticked()',
        '[id]': 'id',
        '[attr.aria-selected]': 'isSelected()',
        '[attr.tabindex]': '-1',
        '[attr.aria-disabled]': 'escDisabled()',
        '[class.is-hidden]': 'isHidden()',
        '(click)': 'onClick($event)',
        '(keydown)': 'onKeydown($event)',
    },
    templateUrl: './option.component.html',
    styleUrl: './option.component.scss',
    hostDirectives: [
        {
            directive: DisabledStateDirective,
            inputs: ['escDisabled'],
        },
    ],
})
export class OptionComponent<T> {
    public readonly disabledState = inject(DisabledStateDirective, { optional: true, self: true });
    escDisabled = computed(() => {
        return this.disabledState?.escDisabled() || false;
    });

    protected parent: EscOptionParentComponent<T> = inject(ESC_OPTION_PARENT_COMPONENT);
    public value: InputSignal<T> = input.required();
    public sticked: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute });
    public initialized = signal(false);
    public elementRef: ElementRef<HTMLElement> = inject(ElementRef);

    public get renderValue(): string {
        return (this.elementRef?.nativeElement.textContent || '').trim();
    }

    public isSelected = computed(() => {
        if (this.parent) {
            return this.parent.selectionModel.selected().some(s => this.parent.selectionModel.compareSingle(s, this.value()));
        }

        return false;
    });

    public isHidden = computed(() => {
        if (this.parent && this.parent.selectionModel.availableCollection) {
            return !this.parent.selectionModel.availableCollection().some(s => this.parent.selectionModel.compareSingle(s, this.value()));
        }

        return false;
    });

    get id() {
        return this._generatedId;
    }

    private _generatedId = `esc-option-${nextId++}`;

    constructor() {}

    public ngOnInit(): void {
        this.initialized.set(true);
    }

    public toggle(): void {
        this.parent?.selectionModel.toggle(this.value(), this.parent.multiple() ? undefined : true);
    }

    public focus(): void {
        this.elementRef.nativeElement.focus();
    }

    public onClick(e: MouseEvent): void {
        if (!this.escDisabled()) {
            this.toggle();
            this.parent.onOptionClick(e, this);
        }
    }

    public onKeydown(event: KeyboardEvent) {
        if (!this.escDisabled()) {
            this.parent?.onOptionKeydown(event, this);
        }
    }
}
