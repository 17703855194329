import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Input, OnDestroy, QueryList } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CollectionCapsuleButtonComponent } from '../collection-capsule-button/collection-capsule-button.component';
import { EscSelectionModelDeprecated } from '../../classes/esc-selection-model';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'esc-collection-capsule',
    templateUrl: './collection-capsule.component.html',
    styleUrls: ['./collection-capsule.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule],
})
export class CollectionCapsuleComponent<T> implements AfterViewInit, OnDestroy {
    @Input({ required: true }) public selectionModel!: EscSelectionModelDeprecated<T>;
    protected destroy$: Subject<boolean> = new Subject<boolean>();
    @ContentChildren(CollectionCapsuleButtonComponent, { descendants: true })
    _buttonComponents?: QueryList<CollectionCapsuleButtonComponent<T>>;

    public collectionLength: number = 0;

    constructor(private _cd: ChangeDetectorRef) {}

    public ngAfterViewInit(): void {
        if (this._buttonComponents) {
            for (const button of this._buttonComponents.toArray()) {
                button.initCollection(this.selectionModel);
            }
        }

        this.selectionModel.changed.pipe(takeUntil(this.destroy$)).subscribe(a => {
            this.collectionLength = a?.collection?.length || 0;

            this._cd.detectChanges();
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
