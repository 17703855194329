import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    contentChildren,
    Directive,
    effect,
    ElementRef,
    EventEmitter,
    forwardRef,
    inject,
    Input,
    model,
    Optional,
    output,
    Output,
    Provider,
    Signal,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { CheckboxRequiredValidator, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { EscFormFieldControl, EscFormFieldControlWithValueAccessor } from '../../directives/form-field-control.directive';
import { DisabledStateDirective } from '../../directives/disabled-state.directive';

export class EscCheckboxChange<T = any> {
    source!: CheckboxComponent<T>;
    checked!: boolean;
}

export const CHECKBOX_CONTROL_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true,
};

export const ESC_CHECKBOX_REQUIRED_VALIDATOR: Provider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => EscCheckboxRequiredValidatorDirective),
    multi: true,
};

export class EscCheckboxGroupChange<T> {
    constructor(public value: T[]) {}
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'esc-checkbox-group',
    standalone: true,
    providers: [
        { provide: EscFormFieldControl, useExisting: CheckboxGroupDirective },
        { provide: EscFormFieldControlWithValueAccessor, useExisting: CheckboxGroupDirective },
    ],
    host: {
        class: 'esc-checkbox-group',
    },
    hostDirectives: [
        {
            directive: DisabledStateDirective,
            inputs: ['escDisabled'],
        },
    ],
})
export class CheckboxGroupDirective<T> extends EscFormFieldControlWithValueAccessor<T[]> implements ControlValueAccessor {
    //
    public readonly disabledState = inject(DisabledStateDirective, { optional: true, self: true });
    escDisabled = computed(() => {
        return this.disabledState?.escDisabled() || false;
    });
    value = model<T[]>([] as T[]);
    //

    private _isInitialized = false;

    readonly escChange = output<EscCheckboxGroupChange<T>>();
    protected _checkboxes: Signal<readonly CheckboxComponent<T>[]> = contentChildren(forwardRef(() => CheckboxComponent));

    constructor() {
        super();

        effect(() => {
            this._onChange(this.value());

            this.updateSelectedCheckboxesFromValue();
        });
    }

    public ngAfterContentInit(): void {
        this._isInitialized = true;
    }

    public updateSelectedCheckboxesFromValue(): void {
        if (this._checkboxes()) {
            for (const checkbox of this._checkboxes()) {
                if (
                    this.value()?.some(v => {
                        if ((v as { id: number })['id'] && (v as { id: number })['id'] === (checkbox.groupValue as { id: number })['id']) {
                            return true;
                        }
                        return v === checkbox.groupValue;
                    })
                ) {
                    checkbox.checked = true;
                } else {
                    checkbox.checked = false;
                }
            }
        }
    }

    public updateValueFromSelectedCheckboxes(): void {
        if (this._checkboxes()) {
            this.value.set([]);
            for (const checkbox of this._checkboxes()) {
                if (checkbox.checked && checkbox.groupValue) {
                    this.value()?.push(checkbox.groupValue);
                }
            }

            this.writeValue(this.value());
            this._onChange(this.value());
        }
    }

    emitChangeEvent(): void {
        if (this._isInitialized) {
            this.escChange.emit(new EscCheckboxGroupChange(this.value() || []));
        }
    }

    writeValue(value: T[]) {
        this.value.set(value);
    }
}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: `esc-checkbox[required][formControlName],
             esc-checkbox[required][formControl], esc-checkbox[required][ngModel]`,
    providers: [ESC_CHECKBOX_REQUIRED_VALIDATOR],
    standalone: true,
})
export class EscCheckboxRequiredValidatorDirective extends CheckboxRequiredValidator {}

// TODO: Aria, indeterminate, unique ID, validation

@Component({
    selector: 'esc-checkbox',
    templateUrl: './checkbox.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [CHECKBOX_CONTROL_VALUE_ACCESSOR],
    standalone: true,
    imports: [CommonModule],
    host: {
        '[class.is-checked]': 'checked',
        '[class.is-active]': 'checked',
        '[class.is-checkbox-hidden]': 'checkboxHidden',
    },
})
export class CheckboxComponent<T> implements ControlValueAccessor {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private _controlValueAccessorChangeFn: (value: unknown) => void = () => {};

    //
    @ViewChild('input') private _inputElement!: ElementRef<HTMLInputElement>;
    @ViewChild('label') private _labelElement!: ElementRef<HTMLInputElement>;
    //

    @Input() public checkboxHidden = false;

    @Output() readonly escChange: EventEmitter<EscCheckboxChange<T>> = new EventEmitter<EscCheckboxChange<T>>();

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get checkboxClass(): string | string[] | Set<string> | { [key: string]: any } {
        return this._checkboxClass;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set checkboxClass(value: string | string[] | Set<string> | { [key: string]: any }) {
        this._checkboxClass = value;
        this._changeDetectorRef.markForCheck();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _checkboxClass: string | string[] | Set<string> | { [key: string]: any } = '';

    @Input() public groupValue?: T;

    @Input()
    get checked(): boolean {
        return this._checked;
    }

    set checked(value: BooleanInput) {
        const checked = coerceBooleanProperty(value);

        if (checked != this.checked) {
            this._checked = checked;
            this._changeDetectorRef.markForCheck();
        }
    }

    private _checked = false;

    @Input()
    get escDisabled(): boolean {
        return this._escDisabled;
    }

    set escDisabled(value: BooleanInput) {
        const newValue = coerceBooleanProperty(value);

        if (newValue !== this.escDisabled) {
            this._escDisabled = newValue;
            this._changeDetectorRef.markForCheck();
        }
    }

    private _escDisabled = false;

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
    }

    private _required = false;

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        @Optional() public parentCheckboxGroup: CheckboxGroupDirective<T>
    ) {}

    /**
     * ControlValueAccessor implementation
     */
    public writeValue(value: unknown) {
        this.checked = !!value;

        if (this._inputElement) {
            this._inputElement.nativeElement.checked = this.checked;
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public _onTouched: () => unknown = () => {};

    public registerOnChange(fn: (value: unknown) => void) {
        this._controlValueAccessorChangeFn = fn;
    }

    public registerOnTouched(fn: () => unknown) {
        this._onTouched = fn;
    }

    public setDisabledState() {
        // this.escDisabled = isDisabled;
    }

    /** *** */

    public toggle(): void {
        this.checked = !this.checked;
        this._controlValueAccessorChangeFn(this.checked);
    }

    protected _handleInputClick() {
        // if (this.indeterminate) {
        //     Promise.resolve().then(() => {
        //         this._indeterminate = false;
        //         this.indeterminateChange.emit(this._indeterminate);
        //     });
        // }

        this._checked = !this._checked;
        this._emitChangeEvent();
    }

    protected _onBlur() {
        Promise.resolve().then(() => {
            this._onTouched();
            this._changeDetectorRef.markForCheck();
        });
    }

    protected _onInteractionEvent(event: Event) {
        event.stopPropagation();
    }

    protected _createChangeEvent(isChecked: boolean) {
        const event = new EscCheckboxChange<T>();
        event.source = this;
        event.checked = isChecked;

        return event;
    }

    private _emitChangeEvent() {
        this._controlValueAccessorChangeFn(this.checked);
        this.escChange.emit(this._createChangeEvent(this.checked));

        if (this._inputElement) {
            this._inputElement.nativeElement.checked = this.checked;
        }

        if (this.parentCheckboxGroup) {
            this.parentCheckboxGroup.updateValueFromSelectedCheckboxes();
        }
    }
}
