import { Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from '../progress-bar.component';

@Component({
    selector: 'esc-progress-bar-value',
    standalone: true,
    imports: [CommonModule],
    host: {
        tabindex: '-1',
        // '[attr.mode]': 'mode',
        '[class]': '"esc-progress-bar-value"',
        '[style.maxWidth]': 'barWidth()',
    },
    templateUrl: './progress-bar-value.component.html',
    styleUrl: './progress-bar-value.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class ProgressBarValueComponent {
    protected parent: ProgressBarComponent = inject(ProgressBarComponent);

    value = input(0);
    barWidth = computed(() => {
        if (!this.parent) {
            return 0;
        }

        const from = this.value() - this.parent.min();
        const to = this.parent.max() - this.parent.min();

        return `${Math.floor(Math.min(Math.max(from / to, this.parent.min()), this.parent.max()) * 100)}%`;
    });
}
