<div class="sorter-list">
    <p class="sorter-list__label">
        {{ label }} <span>({{ value?.length || 0 }})</span>
    </p>
    <ul
        class="sorter-list__items"
        [id]="dragDropId"
        [cdkDropListConnectedTo]="connectedTo"
        cdkDropList
        [cdkDropListData]="value"
        (cdkDropListExited)="exited($event)"
        (cdkDropListDropped)="drop($event)">
        <li
            *ngFor="let item of value; let i = index"
            class="sorter-item"
            cdkDrag
            escDragCursor
            [cdkDragDisabled]="disabledFunc(item)"
            [escTooltip]="tooltipFunc(item)"
            [escTooltipShowDelay]="600">
            <div class="custom-placeholder" *cdkDragPlaceholder></div>

            <div class="sorter-item__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13Z"
                        stroke="#CACFD8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6Z"
                        stroke="#CACFD8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M9 20C9.55228 20 10 19.5523 10 19C10 18.4477 9.55228 18 9 18C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20Z"
                        stroke="#CACFD8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13Z"
                        stroke="#CACFD8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M15 6C15.5523 6 16 5.55228 16 5C16 4.44772 15.5523 4 15 4C14.4477 4 14 4.44772 14 5C14 5.55228 14.4477 6 15 6Z"
                        stroke="#CACFD8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path
                        d="M15 20C15.5523 20 16 19.5523 16 19C16 18.4477 15.5523 18 15 18C14.4477 18 14 18.4477 14 19C14 19.5523 14.4477 20 15 20Z"
                        stroke="#CACFD8"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg>
            </div>

            <div class="sorter-item__content">
                <ng-container *ngTemplateOutlet="templateItem.template; context: { $implicit: item }"></ng-container>
            </div>
        </li>
    </ul>
</div>
