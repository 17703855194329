export class Timer {
    private _timerId: number | undefined;
    private _start: number = Date.now();

    constructor(
        public callback: () => unknown,
        public remaining: number
    ) {
        this.resume();
    }

    pause() {
        window.clearTimeout(this._timerId);
        this._timerId = undefined;
        this.remaining -= Date.now() - (this._start || 0);
    }

    resume() {
        if (this._timerId) {
            return;
        }

        this._start = Date.now();
        this._timerId = window.setTimeout(this.callback, this.remaining);
    }
}
