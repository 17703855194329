import { booleanAttribute, computed, Directive, ElementRef, EventEmitter, input, InputSignal, InputSignalWithTransform, Output, Signal } from '@angular/core';
import { EFormState } from '../services/form-actions.service';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
    selector: '[escButton], [esc-button]',
    standalone: true,
    host: {
        class: 'esc-button',
        '[class.is-loading]': 'loading()',
        '[class.is-secondary]': 'styleSecondary()',
        '[class.is-small]': 'styleSmall()',
        '[class.is-tertiary]': 'styleTertiary()',
        '[class.is-shadowed]': 'styleShadowed()',
        '[class.is-circle]': 'styleCircle()',
        '[class.is-white-shadowed]': 'styleWhiteShadowed()',
        '[class.is-disabled]': 'disabled()',
        '[attr.aria-disabled]': 'disabled()',
        '[attr.tabindex]': 'disabled() ? -1 : 0',
        '[attr.disabled]': 'null',
    },
})
export class ButtonDirective {
    public styleSecondary = input(false, { transform: booleanAttribute, alias: 'secondary' });
    public styleSmall: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute, alias: 'small' });
    public styleTertiary: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute, alias: 'tertiary' });
    public styleShadowed: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute, alias: 'shadowed' });
    public styleWhiteShadowed: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute, alias: 'white-shadowed' });
    public styleCircle: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute, alias: 'circle' });
    public styleLoading: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute, alias: 'loading' });

    public disabled: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute });
    public state: InputSignal<EFormState> = input<EFormState>(EFormState.StateInitial);
    public enableAfterSuccess: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute });

    public loading: Signal<boolean> = computed(() => {
        return this.styleLoading() || this.state() === 'loading' || (this.state() === 'success' && this.enableAfterSuccess());
    });

    /**
     * @deprecated use 'escClick' instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() public click: EventEmitter<MouseEvent> = new EventEmitter();
    @Output() public escClick: EventEmitter<MouseEvent> = new EventEmitter();

    constructor(_button: ElementRef<HTMLButtonElement>) {
        fromEvent(_button.nativeElement, 'click')
            .pipe(takeUntilDestroyed())
            .subscribe(e => {
                if (!this.disabled() && !this.loading()) {
                    this.escClick.emit(e as MouseEvent);
                }
            });
    }
}
