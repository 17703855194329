export class DataSourceSearch<T> {
    private _parseItemFunc?: (item: T) => string[];

    public parseItemKeys?: Array<keyof T>;

    constructor() {}

    public setCustomParseFunc(fn: (item: T) => string[]): void {
        this._parseItemFunc = fn;
    }

    public search(data: readonly T[] | null, query: string): T[] | null {
        if (!data || !query) {
            return data as T[];
        }

        const searchValues = query.toLowerCase().replace(/,/g, '').split(' ');
        const searchedData = [...data].filter(d => {
            const values: string[] = [];

            if (this.parseItemKeys) {
                for (const key of this.parseItemKeys) {
                    values.push(...this.getValues(d[key]));
                }
            } else {
                values.push(...this.getValues(d));
            }

            if (this._parseItemFunc) {
                const additionalValues = this._parseItemFunc(d);

                if (additionalValues) {
                    values.push(...additionalValues);
                }
            }

            if ((d as { id: number })['id'] && !isNaN(Number(query)) && Number(query) === (d as { id: number })['id']) {
                return true;
            }

            const valueResults: boolean[] = searchValues.map(value => {
                for (let element of values) {
                    element = element?.toLowerCase();
                    if (element?.indexOf(value) >= 0) {
                        return true;
                    }
                }

                return false;
            });

            return valueResults.indexOf(false) === -1;
        });

        return searchedData;
    }

    private getValues(object: unknown): string[] {
        if (!object) {
            return [];
        }

        if (typeof object === 'string') {
            return [object];
        }

        if (typeof object === 'number') {
            return [object.toString()];
        }

        if (object instanceof Date) {
            return [object.toLocaleDateString()];
        }

        if (Array.isArray(object)) {
            const data = [];
            for (const inner of object) {
                data.push(...this.getValues(inner));
            }

            return data;
        }

        if (object.constructor === Object) {
            const data = [];
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            for (const key in object as any) {
                if (key === 'id') {
                    continue;
                }

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const element = (object as any)[key] as unknown;

                data.push(...this.getValues(element));
            }

            return data;
        }

        return [];
    }
}
