import { IProfile, IProfileRole } from '../../models/profile.interface';
import { TPhoneChangeDTO } from '../../authentication/profile.service';

export class GetProfile {
    public static readonly type = '[Profile] GetProfile';
}

export class SetProfile {
    public static readonly type = '[Profile] SetProfile';
    constructor(public payload: { profile: IProfile }) {}
}

export class GetPermissions {
    public static readonly type = '[Profile] GetPermissions';
    constructor() {}
}

export class SetCurrentRole {
    public static readonly type = '[Profile] Set current role';
    constructor(public payload: { role: IProfileRole }) {}
}

export class SetCurrentRoleByUrl {
    public static readonly type = '[Profile] Set current role by url';
    constructor() {}
}

export class ChangePhone {
    public static readonly type = '[Profile] ChangePhone';
    constructor(public payload: { data: TPhoneChangeDTO }) {}
}

export class ResetProfileState {
    public static readonly type = '[Profile] ResetProfileState';
    constructor() {}
}
