import { InjectionToken } from '@angular/core';

export type TTooltipPosition = 'left' | 'right' | 'above' | 'below' | 'before' | 'after';
export type TTooltipVisibility = 'initial' | 'visible' | 'hidden';
export const SCROLL_THROTTLE_MS = 20;
export const LONG_PRESS_DELAY = 100;
export const UNBOUNDED_ANCHOR_GAP = 8;

export interface ITooltipDefaultOptions {
    showDelay: number;
    hideDelay: number;
    touchendHideDelay: number;
    position?: TTooltipPosition;
    disableTooltipInteractivity?: boolean;
}

export function TOOLTIP_DEFAULT_OPTIONS_FACTORY(): ITooltipDefaultOptions {
    return {
        showDelay: 100,
        hideDelay: 100,
        touchendHideDelay: 500,
        position: 'above',
    };
}

export const TOOLTIP_DEFAULT_OPTIONS = new InjectionToken<ITooltipDefaultOptions>('esc-tooltip-default-options', {
    providedIn: 'root',
    factory: TOOLTIP_DEFAULT_OPTIONS_FACTORY,
});
