import { ChangeDetectionStrategy, Component, ComponentRef, ElementRef, EmbeddedViewRef, Inject, NgZone, ViewChild, ViewEncapsulation } from '@angular/core';
import { BasePortalOutlet, CdkPortalOutlet, ComponentPortal, PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { EscModalRef } from '../../classes/modal-ref';
import { NgIf } from '@angular/common';
import { IModalConfig } from '../../classes/modal-config';
import { MODAL_CONFIG } from '../../services/modal.service';

export function throwDialogContentAlreadyAttachedError() {
    throw Error('Attempting to attach dialog content after content is already attached');
}

@Component({
    selector: 'esc-modal-container',
    templateUrl: './modal-container.component.html',
    styleUrl: './modal-container.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [PortalModule, NgIf],
    host: {
        class: 'esc-modal',
        tabindex: '-1',
        // '[attr.aria-modal]': '_config.ariaModal',
        // '[id]': '_config.id',
        // '[attr.role]': '_config.role',
        // '[attr.aria-labelledby]': '_config.ariaLabel ? null : _ariaLabelledByQueue[0]',
        // '[attr.aria-label]': '_config.ariaLabel',
        // '[attr.aria-describedby]': '_config.ariaDescribedBy || null',
        // '[class._mat-animation-noopable]': '!_animationsEnabled',
    },
})
export class ModalContainerComponent extends BasePortalOutlet {
    @ViewChild(CdkPortalOutlet, { static: true }) private _portalOutlet!: CdkPortalOutlet;

    private _hostElement: HTMLElement = this._elementRef.nativeElement;

    constructor(
        public _modalRef: EscModalRef,
        private _elementRef: ElementRef,
        public _ngZone: NgZone,
        @Inject(MODAL_CONFIG) public modalConfig: IModalConfig
    ) {
        super();
    }

    attachComponentPortal<T>(portal: ComponentPortal<T>): ComponentRef<T> {
        if (this._portalOutlet.hasAttached()) {
            throwDialogContentAlreadyAttachedError();
        }

        const result = this._portalOutlet.attachComponentPortal(portal);
        this._contentAttached();
        return result;
    }

    /**
     * Attach a TemplatePortal as content to this dialog container.
     * @param portal Portal to be attached as the dialog content.
     */
    attachTemplatePortal<T>(portal: TemplatePortal<T>): EmbeddedViewRef<T> {
        if (this._portalOutlet.hasAttached()) {
            throwDialogContentAlreadyAttachedError();
        }

        const result = this._portalOutlet.attachTemplatePortal(portal);
        this._contentAttached();
        return result;
    }

    protected _contentAttached() {
        // TODO:
        this._requestAnimationFrame(() => this._hostElement.classList.add('is-opening'));
    }

    public closeClick(): void {
        this._modalRef.close(true);
    }

    private _requestAnimationFrame(callback: () => void) {
        this._ngZone.runOutsideAngular(() => {
            if (typeof requestAnimationFrame === 'function') {
                requestAnimationFrame(callback);
            } else {
                callback();
            }
        });
    }
}
