import { INavigationItem } from '../../models/navigation.interface';
import { UntypedFormGroup } from '@angular/forms';
import { SiteContext } from './layout.state';
import { ITableSnapshot } from '../../models/table-snapshot.interface';
import { ITabsSnapshot } from '../../models/tabs-snapshot.interface';
import { ISingleFranchise } from '../../models/franchise.interface';

export class MenuExpand {
    public static readonly type = '[Layout] MenuExpand';
    constructor(public payload: { isExpand: boolean }) {}
}

export class ToggleMenuExpand {
    public static readonly type = '[Layout] ToggleMenuExpand';
}

export class CloseMenu {
    public static readonly type = '[Layout] CloseMenu';
}

export class AddNavigationData {
    public static readonly type = '[Layout] AddNavigationData';
    constructor(public payload: { data: { [key: string]: string | number } }) {}
}

export class RemoveNavigationItems {
    public static readonly type = '[Layout] RemoveNavigationItems';
    constructor(public payload: { items: Partial<INavigationItem>[] }) {}
}

export class ResetNavigationItems {
    public static readonly type = '[Layout] ResetNavigationItems';
}

export class SaveReturnUrl {
    public static readonly type = '[Layout] SaveReturnUrl';
    constructor(public payload?: { url: string }) {}
}

export class SaveReturnUrlAfterLogin {
    public static readonly type = '[Layout] SaveReturnUrlAfterLogin';
    constructor(public payload?: { url: string }) {}
}

export class SetCurrentlyEditedForm {
    public static readonly type = '[Layout] SetCurrentlyEditedForm';
    constructor(public payload: { form: UntypedFormGroup }) {}
}

export class SetIsMobile {
    public static readonly type = '[Layout] SetIsMobile';
    constructor(public payload: { isMobile: boolean }) {}
}

export class SetIsPageWithFooter {
    public static readonly type = '[Layout] SetIsPageWithFooter';
    constructor(public payload: { isPageWithFooter: boolean }) {}
}

export class AddCurrentScrollableElement {
    public static readonly type = '[Layout] AddCurrentScrollableElement';
    constructor(public payload: { element: HTMLElement | Window | Document }) {}
}

export class RemoveCurrentScrollableElement {
    public static readonly type = '[Layout] RemoveCurrentScrollableElement';
    constructor(public payload: { element: HTMLElement | Window | Document }) {}
}

export class SetPreviousUrl {
    public static readonly type = '[Layout] SetPreviousUrl';
    constructor(public payload: { url: string }) {}
}

export class SetCurrentContext {
    public static readonly type = '[Layout] SetCurrentContext';
    constructor(public payload: { context: SiteContext }) {}
}

export class SetCurrentContextFranchise {
    public static readonly type = '[Layout] SetCurrentContextFranchise';
    constructor(public payload: { context: ISingleFranchise }) {}
}

export class SetTableSnapshot {
    public static type: string = '[Layout] SetTableSnapshot';

    constructor(public payload: { name: string; data: ITableSnapshot }) {}
}

export class SetTabsSnapshot {
    public static type: string = '[Layout] SetTabsSnapshot';

    constructor(public payload: { name: string; data: ITabsSnapshot }) {}
}

export class SetTableSnapshotFilterQuery {
    public static type: string = '[Layout] SetTableSnapshotFilterQuery';

    constructor(public payload: { name: string; searchQuery: string }) {}
}

export class ResetLayoutState {
    public static type: string = '[Layout] ResetLayoutState';

    constructor() {}
}
