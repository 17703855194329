<div [@fromTopAnimation] *ngIf="!wasClosed" class="notification-container is-{{ config.type }}" (click)="closeClick()">
    <div class="notification-container__content">
        <div class="notification-container__icon">
            <esc-icon [icon]="'info'" class="text-color-white"></esc-icon>
        </div>
        <p>
            {{ config.text }}
        </p>
    </div>
</div>
