import { inject, Injectable } from '@angular/core';
import { PrivacyConsentsInfoModalComponent } from '../modals/privacy-consents-info-modal/privacy-consents-info-modal.component';
import { PrivacyConsentsEditModalComponent } from '../modals/privacy-consents-edit-modal/privacy-consents-edit-modal.component';
import { Overlay } from '@angular/cdk/overlay';
import { EscModalService } from '../../modal/services/modal.service';
import { PrivacyConsentsService } from './privacy-consents.service';

@Injectable({
    providedIn: 'root',
})
export class PrivacyConsentsModalsService {
    private _modals = inject(EscModalService);
    private _consentsService = inject(PrivacyConsentsService);
    private _overlay = inject(Overlay);

    public openConsentsInfoModalIfNeeded(): void {
        if (!this._consentsService.areAllConsentSaved()) {
            this.openConsentsInfoModal();
        }
    }
    public openConsentsInfoModal(): void {
        this._modals.open(
            PrivacyConsentsInfoModalComponent,
            {},
            {
                positionStrategy: this._overlay.position().global().bottom().centerHorizontally(),
                closeOnNavigation: false,
                scrollStrategy: this._overlay.scrollStrategies.block(),
                enableCloseButton: false,
                overlayClass: ['overlay-panel', 'consents-overlay-panel'],
            }
        );
    }

    public openConsentsSettingsModal(): void {
        this._modals.open(PrivacyConsentsEditModalComponent);
    }
}
