<div class="esc-modal__container">
    <ng-template cdkPortalOutlet />
</div>

@if (modalConfig.enableCloseButton) {
    <button class="esc-modal__close esc-button is-shadowed" (click)="closeClick()" [attr.aria-label]="'Zamknij'">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5">
            <path
                d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
        </svg>
    </button>
}
