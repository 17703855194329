import { Directive } from '@angular/core';
import { CdkScrollable } from '@angular/cdk/overlay';

@Directive({
    selector: `[esc-modal-header], esc-modal-header, [escModalHeader]`,
    host: { class: 'esc-modal__header' },
    standalone: true,
})
export class EscModalHeaderDirective {}

@Directive({
    selector: `[esc-modal-content], esc-modal-content, [escModalContent]`,
    host: { class: 'esc-modal__content' },
    standalone: true,
    hostDirectives: [CdkScrollable],
})
export class EscModalContentDirective {}

@Directive({
    selector: `[esc-modal-footer], esc-modal-footer, [escModalFooter]`,
    host: { class: 'esc-modal__footer' },
    standalone: true,
})
export class EscModalFooterDirective {}
