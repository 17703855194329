import {
    AfterViewInit,
    booleanAttribute,
    ChangeDetectorRef,
    Component,
    contentChildren,
    Directive,
    effect,
    ElementRef,
    HostBinding,
    inject,
    input,
    InputSignalWithTransform,
    output,
    signal,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { fromEvent, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TooltipDirective } from '../../directives/tooltip.directive';

@Directive({
    selector: '[escContextMenuButton]',
    standalone: true,
    host: {
        class: 'esc-context-menu-button',
        '[class.is-disabled]': 'disabled()',
        '[attr.aria-disabled]': 'disabled()',
        '[attr.tabindex]': 'disabled() ? -1 : 0',
        '[attr.disabled]': 'null',
    },
    hostDirectives: [TooltipDirective],
})
export class ContextMenuButtonDirective {
    private _parent = inject(ContextMenuComponent);
    private _tooltip = inject(TooltipDirective);

    disabled: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute });
    disabledTooltip = input('');
    preventClose: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute });

    /**
     * @deprecated use 'escClick' instead
     */
    // eslint-disable-next-line @angular-eslint/no-output-native
    click = output<MouseEvent>();
    escClick = output<MouseEvent>();

    constructor(_button: ElementRef<HTMLButtonElement>) {
        fromEvent(_button.nativeElement, 'click')
            .pipe(takeUntilDestroyed())
            .subscribe(e => {
                if (!this.disabled()) {
                    this.escClick.emit(e as MouseEvent);
                    if (this._parent) {
                        this._parent.onButtonClick(this);
                    }
                }
            });

        effect(() => {
            if (this._tooltip) {
                this._tooltip.message = this.disabledTooltip();
                this._tooltip.enabled = this.disabled();
            }
        });
    }
}

@Component({
    selector: 'esc-context-menu',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './context-menu.component.html',
    styleUrl: './context-menu.component.scss',
    host: {
        class: 'esc-context-menu',
    },
    encapsulation: ViewEncapsulation.None,
})
export class ContextMenuComponent {
    buttons = contentChildren(ContextMenuButtonDirective);

    closeRequest = new Subject<boolean>();

    public onButtonClick(button: ContextMenuButtonDirective): void {
        if (!button.preventClose()) {
            this.closeRequest.next(true);
        }
    }
}
