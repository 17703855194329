import { Injectable } from '@angular/core';
import { ApiClientService } from 'apps/early-stage-office/src/app/core/services/api.service';
import { IUser, IUserAddDTO, IUserDTO, IUserListItem } from 'apps/early-stage-office/src/app/core/models/user.interface';
import { TArchived } from '../models/archived.type';
import { Observable } from 'rxjs';
import { IResults } from '../models/results.interface';
import { map } from 'rxjs/operators';
import { ISimplifiedGroup } from '../models/group.interface';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    public path: string = 'users';

    constructor(private api: ApiClientService) {}

    public getPath(type?: string) {
        return `users${type ? '/' + type : ''}`;
    }

    public get(params: { [key: string]: unknown } = {}): Observable<IUserListItem[]> {
        return this.api
            .get<IResults<IUserListItem[]>>(this.getPath() + '/plain', { params })
            .pipe(map(dtos => dtos.results.map(item => this.parseListItem(item))));
    }

    public getArchive(params: { [key: string]: unknown } = { limit: 150 }): Observable<TArchived<IUser>[]> {
        return this.api.get<IResults<TArchived<IUserDTO>[]>>(this.getPath('archive'), { params }).pipe(map(dtos => dtos.results.map(item => this.parse(item))));
    }

    public getOne(id): Observable<IUser> {
        return this.api.get<IResults<IUserDTO>>(this.getPath() + '/' + id).pipe(map(dto => this.parse(dto.results)));
    }

    public create(data: IUserAddDTO): Observable<IUser> {
        return this.api.post<IResults<IUserDTO>>(this.getPath(), data).pipe(map(response => this.parse(response.results)));
    }

    public edit(data: IUserAddDTO): Observable<IUser> {
        return this.api.patch<IResults<IUserDTO>>(this.getPath() + '/' + data.id, data).pipe(map(response => this.parse(response.results)));
    }

    public archive(id: number): Observable<void> {
        return this.api.patch<IResults<IUserDTO>>(this.getPath() + '/' + id + '/archive').pipe(map(() => undefined));
    }

    public delete(id: number): Observable<void> {
        return this.api.delete<IResults<IUserDTO>>(this.getPath() + '/' + id).pipe(map(() => undefined));
    }

    public restore(id: number): Observable<void> {
        return this.api.patch<void>(this.getPath() + '/' + id + '/restore');
    }

    public deleteAt(id: number, deleteAt: string): Observable<IUser> {
        return this.api.patch<IResults<IUserDTO>>(this.getPath() + '/' + id + '/deleteAt', { deleteAt }).pipe(map(response => this.parse(response.results)));
    }

    public parseListItem(userDto: IUserListItem): IUserListItem {
        const user = {
            id: userDto.id,
            email: userDto.email,
            firstName: userDto.firstName,
            lastName: userDto.lastName,
            phone: userDto.phone,
            roles: userDto.roles,
        } as IUserListItem;

        return user;
    }

    public parse(userDto: TArchived<IUserDTO>): TArchived<IUser>;
    public parse(userDto: IUserDTO): IUser;
    public parse(userDto: IUserDTO & TArchived<IUserDTO>): IUser & TArchived<IUser> {
        const user = {
            id: userDto.id,
            email: userDto.email,
            firstName: userDto.firstName,
            lastName: userDto.lastName,
            phone: userDto.phone,
            roles: userDto.roles,
        } as IUser & TArchived<IUser>;

        if (userDto.groups) {
            user.groups = userDto.groups.map(g => this.parseGroup(g, userDto));
        } else {
            user.groups = [];
        }

        if (userDto.archivedBy) {
            user.archivedAt = userDto.archivedAt;
            user.deleteAt = userDto.deleteAt;
            user.archivedBy = userDto.archivedBy;
        }

        return user;
    }

    private parseGroup(dto: ISimplifiedGroup, userDto: IUserDTO): ISimplifiedGroup {
        if (dto.substituteLector && userDto.id === dto.substituteLector.id) {
            dto.color = {
                background: '#7510C7',
            };
        }

        return dto;
    }
}
