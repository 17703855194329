import { signal, Signal } from '@angular/core';

export function replacePolishAccents(value: string): string {
    const accents = 'ąĄćĆęĘłŁńŃóÓśŚźŹżŻ';
    const accentsOut = 'aAcCeElLnNoOsSzZzZ';

    return value
        .split('')
        .map(letter => {
            const accentIndex = accents.indexOf(letter);
            return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
        })
        .join('');
}

export function getPolishDayOfWeek(day: number): string {
    return ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'][day - 1];
}

export function getPolishShortDayOfWeek(day: number): string {
    return ['Pon.', 'Wt.', 'Śr.', 'Czw.', 'Pt.', 'Sob.', 'Nd.'][day - 1];
}

export function getPolishMonth(month: number): string {
    return ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'][month - 1];
}

export function getPolishPlural(names: string[], l: number): string {
    if (l === 1) {
        return names[0];
    }

    if (l % 10 >= 5 || l % 10 === 0 || l % 10 === 1 || (l > 9 && l < 20) || l % 10 === 0) {
        return names[2] || names[1];
    }
    return names[1];
}

export const slugify = (text: string) => {
    return text
        .toString() // Cast to string (optional)
        .replace('ł', 'l')
        .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/_/g, '-') // Replace _ with -
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/-$/g, ''); // Remove trailing -
};

export function signalInput<Value, U extends Value | undefined = Value | undefined>(initialValue?: U): (value: Value | U) => Signal<Value | U>;
export function signalInput<Value>(initialValue: Value) {
    const signalInput = signal<Value>(initialValue);
    return (value: Value) => {
        signalInput.set(value);
        return signalInput;
    };
}
