import { CdkColumnDef } from '@angular/cdk/table';
import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[escColumnDef]',
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['sticky'],
    providers: [
        { provide: CdkColumnDef, useExisting: ColumnDefDirective },
        { provide: 'MAT_SORT_HEADER_COLUMN_DEF', useExisting: ColumnDefDirective },
    ],
    standalone: true,
})
export class ColumnDefDirective extends CdkColumnDef {
    /** Unique name for this column. */
    @Input('escColumnDef')
    override get name(): string {
        return this._name;
    }

    override set name(name: string) {
        this._setNameInput(name);
    }

    @Input('escColumnDefGroupName') public groupName!: string;

    protected override _updateColumnCssClassName() {
        super._updateColumnCssClassName();
        this._columnCssClassName!.push(`esc-column`);
        this._columnCssClassName!.push(`esc-column-${this.cssClassFriendlyName}`);

        if (this.groupName) {
            this._columnCssClassName!.push(`esc-column-${this.groupName}`);
        }
    }
}
