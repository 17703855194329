import { Component, ViewEncapsulation } from '@angular/core';
import { CdkHeaderCell } from '@angular/cdk/table';

@Component({
    selector: 'esc-header-cell',
    templateUrl: './header-cell.component.html',
    styleUrls: ['./header-cell.component.scss'],
    host: {
        class: 'esc-header-cell esc-table__header-cell',
        role: 'columnheader',
    },
    encapsulation: ViewEncapsulation.None,
    exportAs: 'escHeaderCell',
    providers: [{ provide: HeaderCellComponent, useExisting: CdkHeaderCell }],
    standalone: true,
})
export class HeaderCellComponent extends CdkHeaderCell {}
