<div class="esc-toast__top">
    <div class="esc-toast__icon">
        @switch (data().type) {
            @case ('success') {
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM13.857 8.191C13.9149 8.11129 13.9566 8.02095 13.9796 7.92514C14.0026 7.82933 14.0065 7.72994 13.991 7.63262C13.9756 7.5353 13.9412 7.44198 13.8897 7.35797C13.8382 7.27396 13.7707 7.20091 13.691 7.143C13.6113 7.08509 13.5209 7.04344 13.4251 7.02044C13.3293 6.99744 13.2299 6.99354 13.1326 7.00895C13.0353 7.02437 12.942 7.0588 12.858 7.11028C12.774 7.16176 12.7009 7.22929 12.643 7.309L9.16 12.099L7.28 10.219C7.21078 10.1474 7.128 10.0903 7.03647 10.051C6.94495 10.0118 6.84653 9.99114 6.74694 9.99032C6.64736 9.9895 6.54861 10.0085 6.45646 10.0463C6.3643 10.084 6.28059 10.1398 6.2102 10.2102C6.13982 10.2807 6.08417 10.3644 6.0465 10.4566C6.00883 10.5488 5.9899 10.6476 5.99081 10.7472C5.99173 10.8467 6.01246 10.9451 6.05181 11.0366C6.09116 11.1281 6.14834 11.2108 6.22 11.28L8.72 13.78C8.79663 13.8567 8.88896 13.9158 8.99065 13.9534C9.09233 13.9909 9.20094 14.006 9.30901 13.9975C9.41708 13.9891 9.52203 13.9573 9.61663 13.9044C9.71123 13.8515 9.79324 13.7787 9.857 13.691L13.857 8.191Z"
                        fill="#1F9F43" />
                </svg>
            }

            @case ('error') {
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18ZM8.28 7.22C8.13782 7.08752 7.94978 7.0154 7.75548 7.01883C7.56118 7.02225 7.37579 7.10097 7.23838 7.23838C7.10097 7.37579 7.02225 7.56118 7.01883 7.75548C7.0154 7.94978 7.08752 8.13782 7.22 8.28L8.94 10L7.22 11.72C7.14631 11.7887 7.08721 11.8715 7.04622 11.9635C7.00523 12.0555 6.98319 12.1548 6.98141 12.2555C6.97963 12.3562 6.99816 12.4562 7.03588 12.5496C7.0736 12.643 7.12974 12.7278 7.20096 12.799C7.27218 12.8703 7.35701 12.9264 7.4504 12.9641C7.54379 13.0018 7.64382 13.0204 7.74452 13.0186C7.84523 13.0168 7.94454 12.9948 8.03654 12.9538C8.12854 12.9128 8.21134 12.8537 8.28 12.78L10 11.06L11.72 12.78C11.7887 12.8537 11.8715 12.9128 11.9635 12.9538C12.0555 12.9948 12.1548 13.0168 12.2555 13.0186C12.3562 13.0204 12.4562 13.0018 12.5496 12.9641C12.643 12.9264 12.7278 12.8703 12.799 12.799C12.8703 12.7278 12.9264 12.643 12.9641 12.5496C13.0018 12.4562 13.0204 12.3562 13.0186 12.2555C13.0168 12.1548 12.9948 12.0555 12.9538 11.9635C12.9128 11.8715 12.8537 11.7887 12.78 11.72L11.06 10L12.78 8.28C12.9125 8.13782 12.9846 7.94978 12.9812 7.75548C12.9777 7.56118 12.899 7.37579 12.7616 7.23838C12.6242 7.10097 12.4388 7.02225 12.2445 7.01883C12.0502 7.0154 11.8622 7.08752 11.72 7.22L10 8.94L8.28 7.22Z"
                        fill="#EE3C31" />
                </svg>
            }

            @case ('warning') {
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M8.48495 2.49488C9.15795 1.32788 10.842 1.32788 11.515 2.49488L17.795 13.3699C18.468 14.5369 17.625 15.9949 16.279 15.9949H3.71995C2.37295 15.9949 1.53095 14.5369 2.20495 13.3699L8.48495 2.49488ZM9.99996 4.99988C10.1989 4.99988 10.3896 5.0789 10.5303 5.21955C10.6709 5.3602 10.75 5.55097 10.75 5.74988V9.24988C10.75 9.44879 10.6709 9.63956 10.5303 9.78021C10.3896 9.92086 10.1989 9.99988 9.99996 9.99988C9.80104 9.99988 9.61028 9.92086 9.46962 9.78021C9.32897 9.63956 9.24995 9.44879 9.24995 9.24988V5.74988C9.24995 5.55097 9.32897 5.3602 9.46962 5.21955C9.61028 5.0789 9.80104 4.99988 9.99996 4.99988ZM9.99996 13.9999C10.2652 13.9999 10.5195 13.8945 10.7071 13.707C10.8946 13.5194 11 13.2651 11 12.9999C11 12.7347 10.8946 12.4803 10.7071 12.2928C10.5195 12.1052 10.2652 11.9999 9.99996 11.9999C9.73474 11.9999 9.48038 12.1052 9.29285 12.2928C9.10531 12.4803 8.99995 12.7347 8.99995 12.9999C8.99995 13.2651 9.10531 13.5194 9.29285 13.707C9.48038 13.8945 9.73474 13.9999 9.99996 13.9999Z"
                        fill="#F6850B" />
                </svg>
            }

            @case ('info') {
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M18 10C18 12.1217 17.1571 14.1566 15.6569 15.6569C14.1566 17.1571 12.1217 18 10 18C7.87827 18 5.84344 17.1571 4.34315 15.6569C2.84285 14.1566 2 12.1217 2 10C2 7.87827 2.84285 5.84344 4.34315 4.34315C5.84344 2.84285 7.87827 2 10 2C12.1217 2 14.1566 2.84285 15.6569 4.34315C17.1571 5.84344 18 7.87827 18 10ZM11 6C11 6.26522 10.8946 6.51957 10.7071 6.70711C10.5196 6.89464 10.2652 7 10 7C9.73478 7 9.48043 6.89464 9.29289 6.70711C9.10536 6.51957 9 6.26522 9 6C9 5.73478 9.10536 5.48043 9.29289 5.29289C9.48043 5.10536 9.73478 5 10 5C10.2652 5 10.5196 5.10536 10.7071 5.29289C10.8946 5.48043 11 5.73478 11 6ZM9 9C8.80109 9 8.61032 9.07902 8.46967 9.21967C8.32902 9.36032 8.25 9.55109 8.25 9.75C8.25 9.94891 8.32902 10.1397 8.46967 10.2803C8.61032 10.421 8.80109 10.5 9 10.5H9.253C9.29041 10.5 9.32734 10.5084 9.36106 10.5246C9.39479 10.5408 9.42445 10.5643 9.44787 10.5935C9.47128 10.6227 9.48785 10.6567 9.49636 10.6932C9.50486 10.7296 9.50508 10.7675 9.497 10.804L9.038 12.87C8.98108 13.1259 8.98237 13.3913 9.04179 13.6466C9.10121 13.902 9.21723 14.1407 9.38129 14.3452C9.54535 14.5496 9.75325 14.7146 9.98963 14.828C10.226 14.9413 10.4848 15.0001 10.747 15H11C11.1989 15 11.3897 14.921 11.5303 14.7803C11.671 14.6397 11.75 14.4489 11.75 14.25C11.75 14.0511 11.671 13.8603 11.5303 13.7197C11.3897 13.579 11.1989 13.5 11 13.5H10.747C10.7096 13.5 10.6727 13.4916 10.6389 13.4754C10.6052 13.4592 10.5755 13.4357 10.5521 13.4065C10.5287 13.3773 10.5121 13.3433 10.5036 13.3068C10.4951 13.2704 10.4949 13.2325 10.503 13.196L10.962 11.13C11.0189 10.8741 11.0176 10.6087 10.9582 10.3534C10.8988 10.098 10.7828 9.8593 10.6187 9.65483C10.4547 9.45036 10.2468 9.28536 10.0104 9.17201C9.77398 9.05867 9.51515 8.99989 9.253 9H9Z"
                        fill="#1890FF" />
                </svg>
            }
        }
    </div>
    <div class="esc-toast__text" [innerHTML]="data().text"></div>
    <button (click)="close()" class="esc-toast__close esc-button is-shadowed is-icon-only">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M6.28003 5.22009C6.13786 5.08761 5.94981 5.01549 5.75551 5.01892C5.56121 5.02234 5.37582 5.10106 5.23841 5.23847C5.101 5.37588 5.02228 5.56127 5.01886 5.75557C5.01543 5.94987 5.08755 6.13792 5.22003 6.28009L8.94003 10.0001L5.22003 13.7201C5.14634 13.7888 5.08724 13.8716 5.04625 13.9636C5.00526 14.0556 4.98322 14.1549 4.98144 14.2556C4.97966 14.3563 4.99819 14.4563 5.03591 14.5497C5.07363 14.6431 5.12977 14.7279 5.20099 14.7991C5.27221 14.8703 5.35705 14.9265 5.45043 14.9642C5.54382 15.0019 5.64385 15.0205 5.74455 15.0187C5.84526 15.0169 5.94457 14.9949 6.03657 14.9539C6.12857 14.9129 6.21137 14.8538 6.28003 14.7801L10 11.0601L13.72 14.7801C13.7887 14.8538 13.8715 14.9129 13.9635 14.9539C14.0555 14.9949 14.1548 15.0169 14.2555 15.0187C14.3562 15.0205 14.4562 15.0019 14.5496 14.9642C14.643 14.9265 14.7279 14.8703 14.7991 14.7991C14.8703 14.7279 14.9264 14.6431 14.9642 14.5497C15.0019 14.4563 15.0204 14.3563 15.0186 14.2556C15.0168 14.1549 14.9948 14.0556 14.9538 13.9636C14.9128 13.8716 14.8537 13.7888 14.78 13.7201L11.06 10.0001L14.78 6.28009C14.9125 6.13792 14.9846 5.94987 14.9812 5.75557C14.9778 5.56127 14.8991 5.37588 14.7617 5.23847C14.6242 5.10106 14.4389 5.02234 14.2446 5.01892C14.0503 5.01549 13.8622 5.08761 13.72 5.22009L10 8.94009L6.28003 5.22009Z"
                fill="#111724" />
        </svg>
    </button>
</div>
@if (data().subtext) {
    <div class="esc-toast__bottom">
        <div class="esc-toast__sub-text" [innerHTML]="data().subtext"></div>
    </div>
}
