import { Component, ElementRef, inject, Input, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { CommonModule } from '@angular/common';

let nextId = 0;

@Component({
    selector: 'esc-dropdown-option',
    templateUrl: './dropdown-option.component.html',
    styleUrls: ['./dropdown-option.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule],
    host: {
        class: 'esc-dropdown-option',
        role: 'option',
        '[id]': 'id',
        '[attr.aria-selected]': 'isSelected()',
        '[attr.tabindex]': '-1',
        '[attr.aria-disabled]': 'disabled',
        '(click)': '_clicked.next($event)',
        '(keydown)': '_focused.next($event)',
    },
})
export class DropdownOptionComponent<T> {
    private _generatedId = `esc-option-${nextId++}`;

    get id() {
        return this._generatedId;
    }

    @Input('option') value!: T;

    readonly _clicked = new Subject<Event>();
    readonly _focused = new Subject<KeyboardEvent>();
    public readonly element: HTMLElement = inject(ElementRef).nativeElement;
    protected readonly dropdown: DropdownComponent<T> = inject(DropdownComponent);

    public disabled: boolean = false;

    constructor() {}

    isSelected() {
        return this.dropdown.isSelected(this);
    }

    /*
    TODO: skończyć implementację

        isActive() {
            return this.dropdown.isActive(this);
        }

        toggle() {
            this.dropdown.toggle(this);
        }

        select() {
            this.dropdown.select(this);
        }

        deselect() {
            this.dropdown.deselect(this);
        }*/
}
