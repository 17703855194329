import { TLoginDTO } from '../../authentication/authentication.service';
import { ILoginResponse } from '../../models/login-response.interface';

export class SetToken {
    public static readonly type = '[Auth] Set token';
    constructor(public payload: { token: string }) {}
}

export class SetIsLoggedIn {
    public static readonly type = '[Auth] Set is logged in';

    constructor(public payload: { isLoggedIn: boolean }) {}
}

export class Login {
    public static readonly type = '[User] Login';
    constructor(public loginDTO: TLoginDTO) {}
}

export class SetLogin {
    public static readonly type = '[User] SetLogin';
    constructor(public payload: { login: ILoginResponse }) {}
}

export class Logout {
    public static readonly type = '[User] Logout';
    constructor(public payload?: { navigate: boolean; sendLogout: boolean; notify?: string }) {}
}
