import { CDK_ROW_TEMPLATE, CdkRow, CdkTableModule } from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'esc-row, tr[esc-row]',
    template: CDK_ROW_TEMPLATE,
    host: {
        class: 'esc-row esc-table__row',
        role: 'row',
    },
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    exportAs: 'escRow',
    providers: [{ provide: CdkRow, useExisting: RowComponent }],
    standalone: true,
    imports: [CdkTableModule],
})
export class RowComponent extends CdkRow {}
