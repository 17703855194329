import { toSignal } from '@angular/core/rxjs-interop';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRoute, ActivatedRouteSnapshot, Data } from '@angular/router';
import { merge, Observable } from 'rxjs';

export function decodeBase64(base64) {
    const text = atob(base64);
    const length = text.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = text.charCodeAt(i);
    }
    const decoder = new TextDecoder(); // default is utf-8
    return decoder.decode(bytes);
}

export function replacePolishAccents(value: string): string {
    const accents = 'ąĄćĆęĘłŁńŃóÓśŚźŹżŻ';
    const accentsOut = 'aAcCeElLnNoOsSzZzZ';

    return value
        .split('')
        .map(letter => {
            const accentIndex = accents.indexOf(letter);
            return accentIndex !== -1 ? accentsOut[accentIndex] : letter;
        })
        .join('');
}

export function getPolishDayOfWeek(day: number): string {
    return ['Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota', 'Niedziela'][day - 1];
}

export function getPolishShortDayOfWeek(day: number): string {
    return ['Pon.', 'Wt.', 'Śr.', 'Czw.', 'Pt.', 'Sob.', 'Nd.'][day - 1];
}

export function getPolishMonth(month: number): string {
    return ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'][month - 1];
}

export function getPolishPlural(names: string[], l: number): string {
    if (l === 1) {
        return names[0];
    }

    if (l % 10 >= 5 || l % 10 === 0 || l % 10 === 1 || (l > 9 && l < 20) || l % 10 === 0) {
        return names[2] || names[1];
    }
    return names[1];
}

export const slugify = text => {
    return text
        .toString() // Cast to string (optional)
        .replace('ł', 'l')
        .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase() // Convert the string to lowercase letters
        .trim() // Remove whitespace from both sides of a string (optional)
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\_/g, '-') // Replace _ with -
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/\-$/g, ''); // Remove trailing -
};

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text: string, font: string = 'normal 16px GTWalsheimPro'): number {
    // re-use canvas object for better performance
    const canvas = (getTextWidth as any).canvas || ((getTextWidth as any).canvas = document.createElement('canvas'));
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

// temporary TODO:
export function select<T>(selector: (state: any, ...states: any[]) => T) {
    return toSignal(inject(Store).select(selector));
}

export function getMergedParamsFromRouteSnapshot(snapshot: ActivatedRouteSnapshot): { [key: string]: string } {
    let params = {};

    if (snapshot.params) {
        params = Object.assign(params, snapshot.params);
    }

    for (const child of snapshot.children) {
        params = Object.assign(params, getMergedParamsFromRouteSnapshot(child));
    }

    return params;
}

export function getMergedDataFromRouteSnapshot(snapshot: ActivatedRouteSnapshot): Data {
    let data = {};

    if (snapshot.data) {
        data = Object.assign(data, snapshot.data);
    }

    for (const child of snapshot.children) {
        data = Object.assign(data, getMergedDataFromRouteSnapshot(child));
    }

    return data;
}
//
// function _getMergedDataFromRouteArray(route: ActivatedRoute): Observable<Data>[] {
//     let data: Observable<Data>[] = [];
//
//     if (route.data) {
//         data.push(route.data);
//     }
//
//     for (const child of route.children) {
//         data = [...data, ..._getMergedDataFromRouteArray(child)];
//     }
//
//     return data;
// }
//
// export function getMergedDataFromRoute(route: ActivatedRoute): Observable<Data> {
//     const data = _getMergedDataFromRouteArray(route);
//
//     return merge(...data);
// }
