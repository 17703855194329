<div class="esc-dropdown__content">
    <ng-content></ng-content>
</div>
<div class="esc-dropdown__options">
    <ng-container *ngIf="selectAllOption && options && options.length > 0">
        <ng-content select="esc-dropdown-select-all"></ng-content>
        <div class="hr"></div>
    </ng-container>
    <div class="esc-dropdown__list">
        <ng-content select="esc-dropdown-option"></ng-content>
    </div>
</div>
