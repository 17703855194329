<div class="esc-table__container">
    <ng-content select="caption"></ng-content>
    <ng-content select="colgroup, col"></ng-content>
    @if (enableStickyHeader) {
        <div class="esc-table__sticky-cover"></div>
    }

    <ng-container headerRowOutlet></ng-container>
    <div class="esc-table__content" cdkScrollable #tableContent>
        <div class="esc-table__empty" *ngIf="status === 2">
            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Interface/cancel circle">
                    <path
                        id="Vector (Stroke)"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M31.9997 7.3335C18.3767 7.3335 7.33301 18.3771 7.33301 32.0002C7.33301 45.6232 18.3767 56.6668 31.9997 56.6668C45.6227 56.6668 56.6663 45.6232 56.6663 32.0002C56.6663 18.3771 45.6227 7.3335 31.9997 7.3335ZM3.33301 32.0002C3.33301 16.168 16.1675 3.3335 31.9997 3.3335C47.8318 3.3335 60.6663 16.168 60.6663 32.0002C60.6663 47.8323 47.8318 60.6668 31.9997 60.6668C16.1675 60.6668 3.33301 47.8323 3.33301 32.0002Z"
                        fill="#CACFD8" />
                    <path
                        id="Vector (Stroke)_2"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M41.4142 22.5858C42.1953 23.3668 42.1953 24.6332 41.4142 25.4142L25.4142 41.4142C24.6332 42.1953 23.3668 42.1953 22.5858 41.4142C21.8047 40.6332 21.8047 39.3668 22.5858 38.5858L38.5858 22.5858C39.3668 21.8047 40.6332 21.8047 41.4142 22.5858Z"
                        fill="#CACFD8" />
                    <path
                        id="Vector (Stroke)_3"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M22.5858 22.5858C23.3668 21.8047 24.6332 21.8047 25.4142 22.5858L41.4142 38.5858C42.1953 39.3668 42.1953 40.6332 41.4142 41.4142C40.6332 42.1953 39.3668 42.1953 38.5858 41.4142L22.5858 25.4142C21.8047 24.6332 21.8047 23.3668 22.5858 22.5858Z"
                        fill="#CACFD8" />
                </g>
            </svg>

            <p>{{ useFilters ? emptyFilterText : emptyText }}</p>
        </div>

        <div class="esc-table__loading" *ngIf="status === 1">
            <div class="loader-icon"></div>
        </div>
        <div class="esc-table__content-inner" #tableContentInner>
            <ng-container rowOutlet></ng-container>
        </div>
    </div>

    <div class="esc-table__scroll" #scroll>
        <div class="esc-table__scroll-inner" [ngStyle]="{ 'width.px': headerRowWidth }"></div>
    </div>

    <ng-container noDataRowOutlet></ng-container>
    <ng-container footerRowOutlet></ng-container>
</div>
