import { AfterViewInit, ChangeDetectionStrategy, Component, input, InputSignal, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EscModalContentDirective, EscModalFooterDirective } from '../directives/modal-directives';
import { EscModalRef } from '../classes/modal-ref';
import { IconComponent, TIconName } from '../../common/components/icon/icon.component';

export type TConfirmModalType = 'error' | 'warning' | 'info';

export interface IConfirmModalData {
    title: string;
    text?: string;
    buttonText: string;
    type: TConfirmModalType;
    parent?: HTMLElement;
    width?: number;
}

@Component({
    standalone: true,
    imports: [CommonModule, EscModalContentDirective, EscModalFooterDirective, IconComponent],
    templateUrl: './confirm-modal.component.html',
    styleUrl: './confirm-modal.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.is-initialized]': 'initialized',
    },
})
export class ConfirmModalComponent implements OnInit, AfterViewInit {
    protected initialized = false;
    public data: InputSignal<IConfirmModalData> = input({
        title: '',
        buttonText: '',
        type: 'info',
    } as IConfirmModalData);
    public icon: TIconName = 'warning';

    constructor(private _modalRef: EscModalRef<boolean>) {}

    public ngOnInit(): void {
        if (this.data().type === 'info') {
            this.icon = 'info';
        }
    }

    public ngAfterViewInit() {
        this.initialized = true;
        this._modalRef.updateSize(this.data().width || 360);
    }

    public close(b: boolean) {
        this._modalRef.close(b);
    }
}
