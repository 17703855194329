import { booleanAttribute, Component, contentChild, DestroyRef, inject, input, InputSignalWithTransform, signal } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OverlayContentDirective, OverlayPanelComponent } from '../../../layout/components/overlay-panel/overlay-panel.component';
import { ContextMenuComponent } from '../../../common/components/context-menu/context-menu.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IconComponent, TIconName } from '../../../common/components/icon/icon.component';

@Component({
    selector: 'esc-header-cell-action-menu',
    standalone: true,
    imports: [CommonModule, OverlayContentDirective, OverlayPanelComponent, IconComponent],
    templateUrl: './header-cell-action-menu.component.html',
    styleUrl: './header-cell-action-menu.component.scss',
})
export class HeaderCellActionMenuComponent {
    private _destroyRef = inject(DestroyRef);

    open = signal(false);
    menu = contentChild(ContextMenuComponent);

    icon = input<TIconName>('more_vert');
    public fill: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute });

    ngAfterContentInit() {
        this.menu()
            ?.closeRequest.pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.open.set(false);
            });
    }
}
