import { ControlValueAccessor, NgControl } from '@angular/forms';
import { computed, Directive, effect, ElementRef, inject, Signal } from '@angular/core';
import { FormActionsService } from '../services/form-actions.service';

let nextUniqueId = 0;

@Directive()
export abstract class EscFormFieldControl {
    private _id = 'form-field-input-' + nextUniqueId++;

    get id(): string {
        return this._id;
    }

    abstract ngControl: NgControl | null;
    abstract escDisabled: Signal<boolean>;

    public readonly formActions = inject(FormActionsService, { optional: true });

    showInvalidState = computed(() => {
        return this.formActions ? this.formActions.submitAttempted() : true;
    });
}

@Directive()
export abstract class EscFormFieldControlNativeInput extends EscFormFieldControl {
    abstract elementRef: ElementRef<HTMLInputElement> | null;
    abstract onContainerClick(event: MouseEvent): void;
}

@Directive()
export abstract class EscFormFieldControlWithValueAccessor<T> extends EscFormFieldControl implements ControlValueAccessor {
    abstract value: Signal<T | undefined>;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
    protected _onChange = (v: unknown) => {};
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
    protected _onTouched = () => {};

    abstract writeValue(obj: unknown): void;

    registerOnChange(fn: (v: unknown) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this._onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        // throw new Error('Method not implemented.');
    }

    public readonly ngControl = inject(NgControl, { optional: true, self: true });

    constructor() {
        super();

        if (this.ngControl != null) {
            this.ngControl.valueAccessor = this;
        }

        effect(() => {
            if (this.ngControl?.value !== this.value()) {
                this._onChange(this.value());
            }
        });
    }
}
