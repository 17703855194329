import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

export class EscLetContext<T = unknown> {
    $implicit: T = null!;
    escLet: T = null!;
}

@Directive({
    selector: '[escLet]',
    exportAs: 'escLet',
    standalone: true,
})
export class LetDirective<T> implements OnInit {
    private _context = new EscLetContext<T>();

    @Input()
    set escLet(value: T) {
        this._context.$implicit = this._context.escLet = value;
    }

    constructor(
        private _vcr: ViewContainerRef,
        private _templateRef: TemplateRef<EscLetContext>
    ) {}

    static ngTemplateContextGuard<T>(dir: LetDirective<T>, ctx: unknown): ctx is EscLetContext<T> {
        return true;
    }

    ngOnInit() {
        this._vcr.createEmbeddedView(this._templateRef, this._context);
    }
}
