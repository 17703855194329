import { Component, contentChild, DestroyRef, inject, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldTitleComponent } from '../form-field-title/form-field-title.component';
import { FormFieldDescriptionComponent } from '../form-field-description/form-field-description.component';
import { EscFormFieldControl } from '../../directives/form-field-control.directive';
import { TErrorMessage, TValidationMessages, ValidationMessagesService } from '../../services/validation-messages.service';
import { distinctUntilChanged, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbstractControl } from '@angular/forms';
import { ValidationTextComponent } from '../../../common/components/validation-text/validation-text.component';
import { TooltipDirective } from '../../../common/directives/tooltip.directive';
import { OwlDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormActionsService } from '../../services/form-actions.service';

@Component({
    selector: 'esc-form-field',
    templateUrl: './form-field.component.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule, FormFieldTitleComponent, FormFieldDescriptionComponent, ValidationTextComponent, TooltipDirective, OwlDateTimeModule],
    host: {
        '[class]': '"esc-form-field "',
        '[class.is-invalid]': 'controlProp("invalid")',
        '[class.is-touched]': 'controlProp("touched")',
        '[class.is-dirty]': 'controlProp("dirty")',
        '[class.is-disabled]': 'controlProp("disabled")',
    },
})
export class FormFieldComponent {
    formActions = inject(FormActionsService, { optional: true });

    @Input() public customMessages: Partial<TValidationMessages> = {};

    public _control = contentChild(EscFormFieldControl);

    public validationErrors$!: Observable<TErrorMessage[]>;

    constructor(
        private _validationMessages: ValidationMessagesService,
        private _destroyRef: DestroyRef
    ) {}

    public ngAfterContentInit(): void {
        this.validationErrors$ = this._control()?.ngControl?.valueChanges?.pipe(
            takeUntilDestroyed(this._destroyRef),
            distinctUntilChanged(),
            map(() => {
                return this._validationMessages.parseMessage(this._control()?.ngControl?.errors, this.customMessages);
            })
        ) as Observable<TErrorMessage[]>;
    }

    public controlProp(prop: keyof AbstractControl): boolean {
        const control = this._control()?.ngControl;
        return control?.control && control.control[prop];
    }
}
