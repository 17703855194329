import { ChangeDetectionStrategy, Component, computed, input, Input, ViewEncapsulation } from '@angular/core';

export type TProgressBarValue = {
    value: number;
    color: string;
};

@Component({
    selector: 'esc-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
    host: {
        role: 'progressbar',
        '[attr.aria-valuemin]': 'min()',
        '[attr.aria-valuemax]': 'max()',
        tabindex: '-1',
        '[attr.aria-valuenow]': 'value()',
        // '[attr.mode]': 'mode',
        '[class]': '"esc-progress-bar is-" + type',
    },
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
})
export class ProgressBarComponent {
    value = input(0);
    min = input(0);
    max = input(100);
    totalValue = computed(() => {});

    private _type!: 'default' | 'info' | 'warning' | 'success' | 'error';

    @Input()
    public get type(): 'default' | 'info' | 'warning' | 'success' | 'error' {
        if (this._type) {
            return this._type;
        }

        return this.value === this.max ? 'info' : 'default';
    }

    public set type(v: 'default' | 'info' | 'warning' | 'success' | 'error') {
        this._type = v;
    }

    public getPrimaryBarWidth(): string {
        const from = this.value() - this.min();
        const to = this.max() - this.min();

        return `${Math.floor(Math.min(Math.max(from / to, this.min()), this.max()) * 100)}%`;
    }
}

// TODO: animations
