import {
    booleanAttribute,
    Component,
    computed,
    contentChild,
    contentChildren,
    Directive,
    effect,
    ElementRef,
    inject,
    Input,
    input,
    InputSignal,
    InputSignalWithTransform,
    output,
    signal,
    Signal,
    TemplateRef,
    viewChild,
    ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { EscFormFieldControl, EscFormFieldControlWithValueAccessor } from '../../directives/form-field-control.directive';
import { ControlValueAccessor, FormControl, FormsModule } from '@angular/forms';
import { EscSelectionModel } from '../../../data-and-collections/classes/esc-selection-model';
import { OverlayContentDirective, OverlayPanelComponent } from '../../../layout/components/overlay-panel/overlay-panel.component';
import { OptionComponent } from './option/option.component';
import { IconComponent } from '../../../common/components/icon/icon.component';
import { ESC_OPTION_PARENT_COMPONENT, EscOptionParentComponent } from './option/option-parent';
import { FormFieldComponent } from '../form-field/form-field.component';
import { EscInputDirective } from '../../directives/input.directive';
import { DataSourceSearch } from '../../../data-and-collections/classes/data-source/data-source-search';
import { FormFieldInputComponent, SuffixDirective } from '../form-field-input/form-field-input.component';
import { DisabledStateDirective } from '../../directives/disabled-state.directive';

interface ISelectPrintValueContext<R> {
    $implicit: R[];
    item: R;
    items: R[];
}

@Directive({
    selector: '[escSelectPrintValue]',
    standalone: true,
})
export class SelectPrintValueDirective<T> {
    @Input() source!: T[];

    constructor(public template: TemplateRef<ISelectPrintValueContext<T>>) {}

    static ngTemplateContextGuard<T>(dir: SelectPrintValueDirective<T>, ctx: unknown): ctx is ISelectPrintValueContext<T> {
        return true;
    }
}

@Component({
    selector: 'esc-select',
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [
        CommonModule,
        OverlayContentDirective,
        OverlayPanelComponent,
        IconComponent,
        FormFieldComponent,
        FormsModule,
        SuffixDirective,
        OptionComponent,
        FormFieldInputComponent,
        EscInputDirective,
    ],
    templateUrl: './select.component.html',
    styleUrl: './select.component.scss',
    providers: [
        { provide: EscFormFieldControl, useExisting: SelectComponent },
        { provide: EscFormFieldControlWithValueAccessor, useExisting: SelectComponent },
        { provide: ESC_OPTION_PARENT_COMPONENT, useExisting: SelectComponent },
    ],
    host: {
        class: 'esc-select',
        '[class.is-selected]': 'selectionModel?.singleSelected()',
        '[attr.tabindex]': 'disabledInput() ? -1 : 0',
        '(click)': 'onContainerClick()',
        '(keydown)': 'onContainerKeyup($event)',
        '[class.is-disabled]': 'disabledInput()',
        '[attr.aria-disabled]': 'disabledInput()',
        '[attr.disabled]': 'null',
    },
    hostDirectives: [
        {
            directive: DisabledStateDirective,
            inputs: ['escDisabled'],
        },
    ],
})
export class SelectComponent<T extends R[] | R, R>
    extends EscFormFieldControlWithValueAccessor<T>
    implements EscOptionParentComponent<R>, ControlValueAccessor
{
    //
    public readonly _elementRef = inject(ElementRef, { optional: true, self: true });
    public readonly disabledState = inject(DisabledStateDirective, { optional: true, self: true });
    escDisabled = computed(() => {
        return this.disabledState?.escDisabled() || false;
    });

    value = computed(() => {
        return (this.multiple() ? this.selectionModel.selected() : this.selectionModel.singleSelected()) as T;
    });

    public printEmpty = input(false);
    public dynamicContentWidth = input(false);
    public multiple = input(false);
    public useSearch = input(true);
    public trackVariable: InputSignal<string> = input('id');
    public printValueKey: InputSignal<string> = input('');
    public disabledInput: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute, alias: 'disabled' });

    public options = contentChildren(OptionComponent);
    public printValueDirective = contentChild(SelectPrintValueDirective);
    public container = viewChild<ElementRef<HTMLElement>>('container');
    public overlayPanelComponent = viewChild(OverlayPanelComponent);
    public searchInput = viewChild<ElementRef<HTMLInputElement>>('searchInput');

    public get disabled() {
        return this.disabledInput();
    }

    // outputs
    valueChange = output<{ value: R[] | R; control: FormControl<T> }>();

    public printValue: Signal<string> = computed(() => {
        return this.selectionModel
            .selected()
            .map(s => (this.printValueKey() ? (s[this.printValueKey() as keyof R] as string) : s))
            .join(', ');
    });

    public containerWidth = computed(() => {
        const c = this.container();

        if (c) {
            return c.nativeElement?.offsetWidth;
        }

        return 0;
    });

    private _writeEffect = effect(() => {
        if (!this.selectionModel.compare(this.ngControl?.value, this.selectionModel.selected())) {
            this._onChange(this.multiple() ? this.selectionModel.selected() : this.selectionModel.singleSelected());
            this.valueChange.emit({
                value: this.multiple() ? this.selectionModel.selected() : this.selectionModel.singleSelected(),
                control: this.ngControl?.control as FormControl<T>,
            });
        }
    });

    protected searchValue = signal('');
    private _search = new DataSourceSearch<OptionComponent<R>>();

    protected filteredOptions = computed(() => {
        return this._search.search(this.options(), this.searchValue());
    });

    protected filteredOptionsValues = computed(() => {
        return (
            this.filteredOptions()
                ?.filter(o => o.initialized())
                .map(a => a.value()) || []
        );
    });

    public selectionModel: EscSelectionModel<R> = new EscSelectionModel<R>(this.trackVariable() as keyof R, this.multiple, this.filteredOptionsValues);

    constructor() {
        super();

        this._search.setCustomParseFunc(r => {
            return [r.renderValue];
        });
    }

    public ngOnInit(): void {
        this.selectionModel.setTrackByVariable(this.trackVariable() as keyof R);
    }

    public onContainerClick(): void {
        if (this.disabledInput()) {
            return;
        }

        const o = this.overlayPanelComponent();
        if (o) {
            o.open.set(true);
        }

        if (this.searchInput()) {
            this.searchValue.set('');
            setTimeout(() => this.searchInput()?.nativeElement.focus());
        } else {
            this.focus();
        }
        // this.selectInnerElementRef.nativeElement.focus();
    }

    public onContainerKeyup(event: KeyboardEvent): void {
        let prevent = true;
        const o = this.overlayPanelComponent();

        switch (event.key) {
            case 'Enter':
            case ' ':
            case 'Tab':
                if (o) {
                    if (o.open()) {
                        // this.focus();
                        prevent = false;
                    }

                    o.toggleDropdown();
                }
                break;
            case 'Escape':
                if (o) {
                    o.open.set(false);
                }
                break;
            case 'ArrowDown':
                if (o && !o.open()) {
                    o.toggleDropdown();
                }

                if (this.searchInput()) {
                    this.searchInput()?.nativeElement.focus();
                } else {
                    this._getNextOption()?.focus();
                }
                break;
            case 'ArrowUp':
                if (o && !o.open()) {
                    o.toggleDropdown();
                }
                this._getPreviousOption()?.focus();

                this.options()[this.options().length]?.elementRef.nativeElement.focus();
                break;
            default:
                prevent = false;
        }

        if (prevent) {
            event.preventDefault();
        }
    }

    public onOptionKeydown(event: KeyboardEvent, option: OptionComponent<R>): void {
        let prevent = true;
        const o = this.overlayPanelComponent();
        switch (event.key) {
            case 'Tab':
                this._elementRef?.nativeElement.focus();
                // if (o) {
                //     o.destroyDropdown();
                // }
                break;
            case 'Enter':
            case ' ':
                option.toggle();
                break;
            case 'Escape':
                if (o) {
                    o.open.set(false);
                }
                break;
            case 'ArrowDown':
                this._getNextOption(option)?.focus();
                break;
            case 'ArrowUp':
                this._getPreviousOption(option)?.focus();
                break;
            default:
                prevent = false;
        }

        if (prevent) {
            event.preventDefault();
        }
    }

    public onSearchKeyup(event: KeyboardEvent): void {
        const o = this.overlayPanelComponent();

        switch (event.key) {
            case 'Tab':
                if (o) {
                    o.toggleDropdown();
                    event.preventDefault();
                }
                break;
            case 'ArrowDown':
                setTimeout(() => {
                    this._getNextOption()?.focus();
                });

                event.preventDefault();
                break;
        }
    }

    public onOptionClick() {
        if (!this.multiple()) {
            this.overlayPanelComponent()?.open.set(false);
        }
    }

    public focus(): void {
        this._elementRef?.nativeElement.focus();
    }

    ///////////
    writeValue(value: T) {
        this.selectionModel.setSelection(value);

        setTimeout(() => {
            this.valueChange.emit({ value, control: this.ngControl?.control as FormControl<T> });
        });
    }

    ///
    private _getNextOption(option?: OptionComponent<R>): OptionComponent<R> | undefined {
        const options = this.filteredOptions();

        if (options && options.length) {
            return option ? options[options.indexOf(option) + 1] || options[0] : options[0];
        }

        return undefined;
    }

    private _getPreviousOption(option?: OptionComponent<R>): OptionComponent<R> | undefined {
        const options = this.filteredOptions();

        if (options && options.length) {
            return option ? options[options.indexOf(option) - 1] || options[0] : options[0];
        }

        return undefined;
    }
}
