import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Timer } from '../../classes/timer';

export interface IToastDTO {
    type: 'warning' | 'error' | 'info' | 'success';
    text: string;
    subtext?: string;
    timeout?: number;
}

export interface IToast extends IToastDTO {
    id: number;
    timer: Timer;
}

let _uniqueId = 0;

@Injectable({
    providedIn: 'root',
})
export class EscToastsStateService {
    private _toasts: WritableSignal<IToast[]> = signal([]);

    //
    public get toasts(): Signal<IToast[]> {
        return this._toasts.asReadonly();
    }

    public add(data: IToastDTO): void {
        const id = _uniqueId++;
        const toast = {
            ...data,
            id,
            timer: new Timer(() => {
                this.remove(id);
            }, data.timeout || 5000),
        };
        const toasts = [...this.toasts()]
            .reverse()
            .filter((_, index) => index <= 1)
            .reverse();

        toasts.push(toast);

        this._toasts.update(() => toasts);
    }

    public remove(toastId: number) {
        this._toasts.update(ts => ts.filter(t => t.id !== toastId));
    }
}
