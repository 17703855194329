import { Injectable } from '@angular/core';
import { EscModalService } from '../services/modal.service';
import { ConfirmModalComponent, IConfirmModalData } from './confirm-modal.component';
import { Overlay, PositionStrategy } from '@angular/cdk/overlay';

@Injectable({ providedIn: 'root' })
export class EscConfirmModalService {
    constructor(
        private _modal: EscModalService,
        private _overlay: Overlay
    ) {}

    public async result(data: IConfirmModalData): Promise<boolean> {
        let positionStrategy: PositionStrategy = this._overlay.position().global().centerHorizontally().centerVertically();

        if (data.parent) {
            positionStrategy = this._overlay
                .position()
                .flexibleConnectedTo(data.parent as HTMLElement)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                        offsetY: -4,
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                        offsetY: -4,
                    },
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                        offsetY: 4,
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                        offsetY: 4,
                    },
                ]);
        }

        return this._modal
            .open<ConfirmModalComponent, boolean>(
                ConfirmModalComponent,
                { data },
                {
                    closeOnEscapeKeydown: true,
                    closeOnBackdropClick: true,
                    enableCloseButton: false,
                    hasBackdrop: true,
                    positionStrategy,
                    backdropClass: data.parent ? ['overlay-backdrop'] : ['overlay-backdrop', 'cdk-overlay-dark-backdrop'],
                }
            )
            .result();
    }
}
