import { ChangeDetectorRef, Directive, EmbeddedViewRef, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[escRecreateViewKey]',
    exportAs: 'escRecreateViewKey',
    standalone: true,
})
export class RecreateViewDirective<T> implements OnChanges {
    @Input('escRecreateViewKey') key!: T;

    protected viewRef?: EmbeddedViewRef<unknown>;

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private _cd: ChangeDetectorRef
    ) {}

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['key']) {
            setTimeout(() => {
                if (this.viewRef) {
                    this.destroyView();
                }

                this.createView();
                this._cd.detectChanges();
            });
        }
    }

    private createView() {
        this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
    }

    private destroyView() {
        if (this.viewRef) {
            this.viewRef.destroy();
            this.viewRef = undefined;
        }
    }
}
