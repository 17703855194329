<esc-modal-header>
    <h2 class="text-heading-s">Preferencje plików cookies</h2>
</esc-modal-header>
<esc-modal-content>
    <div class="cookies-edit__items" [formGroup]="form">
        <div class="flex-col is-gap-default">
            <div class="cookies-edit__item cookie-item">
                <div class="cookie-item__title">
                    <p>Niezbędne pliki cookies</p>
                    <p class="cookie-item__required">Zawsze aktywne</p>
                </div>
                <div class="cookie-item__text">
                    Umożliwiające korzystanie z usług dostępnych w ramach Strony, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających
                    uwierzytelniania w ramach strony
                </div>
            </div>

            @for (consent of consents; track consent.consentType) {
                <esc-divider />

                <div class="cookies-edit__item cookie-item">
                    <div class="cookie-item__input">
                        <esc-switch class="consent-switch" [formControlName]="consent.consentType">
                            <p class="cookie-item__title">{{ consent.title }}</p>
                        </esc-switch>
                        <!--                    <es-checkbox-switch [inline]="true" [hideValue]="true" [fullWidth]="true" [size]="'small'" [formControlName]="item.consentType" [label]="item.title"></es-checkbox-switch>-->
                    </div>
                    <div class="cookie-item__text">{{ consent.text }}</div>
                </div>
            }
        </div>
    </div>
</esc-modal-content>
<esc-modal-footer>
    <div class="flex is-flex-space-between">
        <div>
            <button type="button" class="link is-underline" (click)="modalRef.close()">Anuluj</button>
        </div>
        <div class="cookies-buttons">
            <button class="esc-button is-secondary is-small" type="button" (click)="decline()">Potwierdź niezbędne</button>
            <button class="esc-button is-small" type="button" (click)="submit()">Potwierdź ustawienia</button>
        </div>
    </div>
</esc-modal-footer>
