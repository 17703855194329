import { Injectable, ComponentFactoryResolver, ComponentRef, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { NotificationContainerComponent } from '../components/notification-container/notification-container.component';
import { NotificationConfig } from './notification-config';
import { NotificationRef } from './notification-ref';
import { NotificationInjector } from './notification-injector';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    private notificationComponentRef: ComponentRef<NotificationContainerComponent>;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {}

    public add(config: NotificationConfig): NotificationRef {
        if (this.notificationComponentRef) {
            this.removeNotificationComponentFromBody(this.notificationComponentRef);
            this.notificationComponentRef = undefined;
        }
        const ref = this.appendNotificationComponentToBody(config);

        return ref;
    }

    private appendNotificationComponentToBody(config: NotificationConfig): NotificationRef {
        const notificationRef = new NotificationRef();
        const injector = this.getInjector(config, notificationRef);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NotificationContainerComponent);
        const componentRef = componentFactory.create(injector);

        this.appRef.attachView(componentRef.hostView);
        this.handleNotificationRefEvents(notificationRef);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);

        this.notificationComponentRef = componentRef;

        return notificationRef;
    }

    public removeNotificationComponentFromBody(notificationComponentRef: ComponentRef<NotificationContainerComponent>): void {
        this.appRef.detachView(notificationComponentRef.hostView);
        notificationComponentRef.destroy();
        notificationComponentRef = null;
    }

    private getInjector(config: NotificationConfig, notificationRef: NotificationRef): NotificationInjector {
        const map = new WeakMap();
        map.set(NotificationConfig, new NotificationConfig(config));
        map.set(NotificationRef, notificationRef);

        return new NotificationInjector(this.injector, map);
    }

    private handleNotificationRefEvents(notificationRef: NotificationRef): void {
        notificationRef.afterClosing.pipe(take(1)).subscribe(() => {
            this.removeNotificationComponentFromBody(this.notificationComponentRef);
        });
    }
}
