import {
    ANIMATION_MODULE_TYPE,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    Optional,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'esc-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        // Forces the element to have a layout in IE and Edge. This fixes issues where the element
        // won't be rendered if the animations are disabled or there is no web animations polyfill.
        '[style.zoom]': 'isVisible() ? 1 : null',
        // '(mouseleave)': 'handleMouseLeave($event)',
        'aria-hidden': 'true',
    },
    standalone: true,
    imports: [CommonModule],
})
export class TooltipComponent implements OnDestroy {
    public message!: SafeHtml;
    public tooltipClass: string | string[] | Set<string> | { [key: string]: unknown } | undefined;
    public width: number | null = null;
    public _mouseLeaveHideDelay = 0;
    public _triggerElement!: HTMLElement;

    private _animationsDisabled: boolean;
    private _showTimeoutId: ReturnType<typeof setTimeout> | undefined;
    private _hideTimeoutId: ReturnType<typeof setTimeout> | undefined;
    private _closeOnInteraction = false;
    private _isVisible = false;
    private readonly _onHide: Subject<void> = new Subject();

    @ViewChild('tooltip', { static: true }) _tooltip!: ElementRef<HTMLElement>;
    _showAnimation = 'esc-tooltip-show';
    _hideAnimation = 'esc-tooltip-hide';

    public afterHidden(): Observable<void> {
        return this._onHide;
    }

    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        @Optional() @Inject(ANIMATION_MODULE_TYPE) animationMode?: string
    ) {
        this._animationsDisabled = animationMode === 'NoopAnimations';
    }

    public ngOnDestroy() {
        this.cancelPendingAnimations();
        this._onHide.complete();
        this._triggerElement = null!;
    }

    public isVisible(): boolean {
        return this._isVisible;
    }

    public markForCheck(): void {
        this._changeDetectorRef.detectChanges();
    }

    public cancelPendingAnimations() {
        if (this._showTimeoutId != null) {
            clearTimeout(this._showTimeoutId);
        }

        if (this._hideTimeoutId != null) {
            clearTimeout(this._hideTimeoutId);
        }

        this._showTimeoutId = this._hideTimeoutId = undefined;
    }

    public _handleAnimationEnd({ animationName }: AnimationEvent) {
        if (animationName === this._showAnimation || animationName === this._hideAnimation) {
            this._finalizeAnimation(animationName === this._showAnimation);
        }
    }

    public show(delay: number): void {
        // Cancel the delayed hide if it is scheduled
        if (this._hideTimeoutId != null) {
            clearTimeout(this._hideTimeoutId);
        }

        this._showTimeoutId = setTimeout(() => {
            this._toggleVisibility(true);
            this._showTimeoutId = undefined;
        }, delay);
    }

    public hide(delay: number): void {
        // Cancel the delayed show if it is scheduled
        if (this._showTimeoutId != null) {
            clearTimeout(this._showTimeoutId);
        }

        this._hideTimeoutId = setTimeout(() => {
            this._toggleVisibility(false);
            this._hideTimeoutId = undefined;
        }, delay);
    }

    // public handleMouseLeave({relatedTarget}: MouseEvent) {
    //     if (!relatedTarget || !this._triggerElement.contains(relatedTarget as Node)) {
    //         if (this.isVisible()) {
    //             this.hide(this._mouseLeaveHideDelay);
    //         } else {
    //             this._finalizeAnimation(false);
    //         }
    //     }
    // }

    public handleBodyInteraction(delay: number): void {
        if (this._closeOnInteraction) {
            this.hide(delay);
        }
    }

    private _toggleVisibility(isVisible: boolean) {
        const tooltip = this._tooltip.nativeElement;
        const showClass = this._showAnimation;
        const hideClass = this._hideAnimation;
        tooltip.classList.remove(isVisible ? hideClass : showClass);
        tooltip.classList.add(isVisible ? showClass : hideClass);
        this._isVisible = isVisible;

        if (isVisible && !this._animationsDisabled && typeof getComputedStyle === 'function') {
            const styles = getComputedStyle(tooltip);
            if (styles.getPropertyValue('animation-duration') === '0s' || styles.getPropertyValue('animation-name') === 'none') {
                this._animationsDisabled = true;
            }
        }

        if (isVisible) {
            // this._onShow();
        }

        if (this._animationsDisabled) {
            tooltip.classList.add('_esc-animation-noopable');
            this._finalizeAnimation(isVisible);
        }
    }

    private _finalizeAnimation(toVisible: boolean) {
        if (toVisible) {
            this._closeOnInteraction = true;
        } else if (!this._isVisible) {
            this._onHide.next();
        }
    }
}
