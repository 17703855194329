import { Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from '../../../common/directives/tooltip.directive';
import { CopyToClipboardDirective } from '../../../common/directives/copy-to-clipboard.directive';

@Component({
    selector: 'esc-header-cell-action-copy',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './header-cell-action-copy.component.html',
    styleUrl: './header-cell-action-copy.component.scss',
    hostDirectives: [TooltipDirective, CopyToClipboardDirective],
})
export class HeaderCellActionCopyComponent {
    copyValue = input.required<string>();
    tooltipText = input('Skopiuj wszystkie');

    constructor(
        private _tooltip: TooltipDirective,
        private _copyDirective: CopyToClipboardDirective
    ) {}

    public ngOnInit(): void {
        this._tooltip.message = this.tooltipText();
        this._copyDirective.escCopyToClipboard = this.copyValue;
    }

    public onCopyClick(): void {}
}
