import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from '../../directives/tooltip.directive';

@Component({
    selector: 'esc-truncate',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './truncate.component.html',
    styleUrl: './truncate.component.scss',
    host: {
        '[class]': 'enableTruncate ? "truncate is-multiline" : ""',
        '[class.is-multiline]': 'lines > 1',
        '[style.-webkit-line-clamp]': 'lines > 1 ? lines : undefined',
    },
    hostDirectives: [TooltipDirective],
})
export class TruncateComponent implements AfterViewInit, AfterViewChecked {
    @Input() public lines = 1;
    @Input() public tooltip?: string;
    @Input() public withTooltip = true;
    @Input() public enableTruncate = true;

    @ViewChild('content') public content!: ElementRef<HTMLDivElement>;

    constructor(
        private _tooltip: TooltipDirective,
        private elt: ElementRef<HTMLElement>
    ) {
        _tooltip.tooltipClass = 'is-multiline';
    }

    public ngAfterViewInit(): void {
        this._tooltip.disabledFunc = () => {
            if (!this.withTooltip) {
                return true;
            }

            return this._tooltipDisabled();
        };
    }

    public ngAfterViewChecked(): void {
        this._tooltip.message = this.tooltip || this.content.nativeElement.innerText;
    }

    private _tooltipDisabled(): boolean {
        const parentRect = this.elt.nativeElement.getBoundingClientRect();
        const contentRect = this.content.nativeElement.getBoundingClientRect();

        return parentRect.height >= contentRect.height && parentRect.width >= contentRect.width;
    }
}
