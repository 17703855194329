export class DataSourceSorter<T> {
    private _currentSortName?: string;
    private _currentSortDirection?: 'ASC' | 'DESC';
    private _sorters: Map<string, (a: T, b: T) => number> = new Map();

    public get currentSort(): string | undefined {
        return this._currentSortName;
    }

    constructor() {}

    public addSortType(name: string, fn: (a: T, b: T) => number) {
        this._sorters.set(name, fn);
    }

    public sortBy(data: T[] | null, name: string, direction: 'ASC' | 'DESC' = 'ASC'): T[] {
        this._currentSortName = name;
        this._currentSortDirection = direction;

        return this.sort(data as T[]);
    }

    private sort(data: T[]): T[] {
        if (!data || !Array.isArray(data)) {
            return data;
        }

        const sortType = this._currentSortName || '';
        const sorter = this._sorters.get(sortType);
        let sortedData = [...data].sort(sorter);

        if (this._currentSortDirection === 'DESC') {
            sortedData = sortedData.reverse();
        }

        return sortedData;
    }
}
