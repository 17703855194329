import { Directive } from '@angular/core';
import { CdkHeaderRowDef } from '@angular/cdk/table';

@Directive({
    selector: '[escHeaderRowDef]',
    providers: [{ provide: CdkHeaderRowDef, useExisting: HeaderRowDefDirective }],
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['columns: escHeaderRowDef', 'sticky: escHeaderRowDefSticky'],
    standalone: true,
})
export class HeaderRowDefDirective extends CdkHeaderRowDef {}
