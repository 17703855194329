import { Directive, Inject, Input, IterableDiffers, Optional, TemplateRef } from '@angular/core';
import { CDK_TABLE, CdkRowDef } from '@angular/cdk/table';
import { EscDataSource } from '../../data-and-collections/classes/data-source/data-source';
import { IRowContext } from '../components/table/table.component';

@Directive({
    selector: '[escRowDef]',
    providers: [{ provide: CdkRowDef, useExisting: RowDefDirective }],
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['columns: escRowDefColumns', 'when: escRowDefWhen'],
    standalone: true,
})
export class RowDefDirective<T> extends CdkRowDef<T> {
    @Input() escRowDefDataSource!: EscDataSource<T>;

    constructor(
        template: TemplateRef<IRowContext<T>>,
        _differs: IterableDiffers,
        @Inject(CDK_TABLE) @Optional() public override _table?: any
    ) {
        super(template, _differs, _table);
    }

    static ngTemplateContextGuard<T>(dir: RowDefDirective<T>, ctx: unknown): ctx is { $implicit: T; index: number } {
        return true;
    }
}
