import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

import { OverlayPanelTriggerForDirective } from '../../../layout/directives/overlay-panel-trigger-for.directive';
import { IFilterKey, IFilterKeyItem } from '../../../data-and-collections/classes/data-source/data-source-keys-filter';
import { OverlayContentDirective, OverlayPanelComponent } from '../../../layout/components/overlay-panel/overlay-panel.component';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from '../../../forms/components/dropdown/dropdown.component';
import { DataSourceSearchInputComponent } from '../../../data-and-collections/components/data-source-search-input/data-source-search-input.component';
import { DropdownOptionComponent } from '../../../forms/components/dropdown-option/dropdown-option.component';
import { EscDataSource } from '../../../data-and-collections/classes/data-source/data-source';
import { EscSelectionModelDeprecated } from '../../../data-and-collections/classes/esc-selection-model';
import { DropdownSelectAllComponent } from '../../../forms/components/dropdown-select-all/dropdown-select-all.component';
import { HeaderCellActionDirective } from '../../directives/header-cell-action.directive';

/**
 * Component for displaying a header cell action keys filter.
 *
 * @remarks
 * This component is responsible for filtering data based on selected keys.
 *
 * @example
 * ```html
 * <esc-header-cell-action-keys-filter [dataSource]="dataSource" [key]="key" [enableSearch]="true"></esc-header-cell-action-keys-filter>
 * ```
 */
@Component({
    selector: 'esc-header-cell-action-keys-filter',
    templateUrl: './header-cell-action-keys-filter.component.html',
    styleUrls: ['./header-cell-action-keys-filter.component.scss'],
    host: {},
    standalone: true,
    hostDirectives: [HeaderCellActionDirective],
    imports: [
        CommonModule,
        OverlayPanelTriggerForDirective,
        OverlayPanelComponent,
        DropdownComponent,
        DataSourceSearchInputComponent,
        DropdownOptionComponent,
        DropdownSelectAllComponent,
        OverlayContentDirective,
    ],
})
export class HeaderCellActionKeysFilterComponent<T> implements OnInit, OnDestroy {
    @ViewChild(OverlayPanelTriggerForDirective) panelTrigger!: OverlayPanelTriggerForDirective;
    protected destroy$: Subject<boolean> = new Subject<boolean>();

    @Input({ required: true }) public dataSource!: EscDataSource<T>;
    @Input({ required: true }) public key!: string;
    @Input() public enableSearch: boolean = true;

    private filterKey?: IFilterKey<T>;
    public filterLabel?: string;

    public selectDataSource: EscDataSource<IFilterKeyItem> = new EscDataSource();
    public selectionModel!: EscSelectionModelDeprecated<IFilterKeyItem>;

    private keys: IFilterKeyItem[] = [];

    public ngOnInit(): void {
        this.selectionModel = new EscSelectionModelDeprecated<IFilterKeyItem>(this.selectDataSource, 'id');

        this.dataSource
            .connect()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                if (!this.key) {
                    return;
                }

                this.filterKey = this.dataSource.getFilterKey(this.key);

                if (this.filterKey) {
                    const newKeys = this.filterKey.keys;
                    this.filterLabel = this.filterKey.title;
                    if (newKeys.length !== this.keys.length) {
                        this.keys = newKeys;
                        this.selectDataSource.next(this.keys);
                    }
                }
            });

        this.selectionModel.changed.pipe(takeUntil(this.destroy$)).subscribe(v => {
            this.dataSource.defineFilterKeyValues(this.key, v.allCollection);
            this.dataSource.keysFilter();
        });

        this.selectDataSource.defineSearchParseFunc(s => {
            return [s.id];
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public clear() {
        this.selectDataSource.search('');

        this.selectionModel.clear();
    }

    public selectAll() {
        this.selectionModel.toggleAll(true);
    }

    public reverse() {
        this.selectionModel.reverse();
    }
}
