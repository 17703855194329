import { ISchoolYearAddDTO } from '../../models/school-year.interface';

export class LoadSchoolYears {
    public static readonly type = '[Dictionary] LoadSchoolYears';
    constructor() {}
}

export class EditSchoolYear {
    public static readonly type = '[Dictionary] EditSchoolYear';
    constructor(public payload: { data: Partial<ISchoolYearAddDTO> }) {}
}

export class LoadProvincesIfEmpty {
    public static readonly type = '[Dictionary] LoadProvincesIfEmpty';
    constructor() {}
}

export class LoadGroupColors {
    public static readonly type = '[Dictionary] LoadGroupColors';
    constructor() {}
}

export class LoadProvinces {
    public static readonly type = '[Dictionary] LoadProvinces';
    constructor() {}
}

export class LoadSchoolSettingsIfEmpty {
    public static readonly type = '[Dictionary] LoadSchoolSettingsIfEmpty';
    constructor() {}
}

export class LoadSchoolSettings {
    public static readonly type = '[Dictionary] LoadSchoolSettings';
    constructor() {}
}

export class LoadRolesIfEmpty {
    public static readonly type = '[Dictionary] LoadRolesIfEmpty';
    constructor() {}
}

export class LoadRelations {
    public static readonly type = '[Dictionary] LoadRelations';
    constructor() {}
}

export class LoadRelationsIfEmpty {
    public static readonly type = '[Dictionary] LoadRelationsIfEmpty';
    constructor() {}
}

export class LoadSchoolTypes {
    public static readonly type = '[Dictionary] LoadSchoolTypes';
    constructor() {}
}

export class LoadSchoolTypesIfEmpty {
    public static readonly type = '[Dictionary] LoadSchoolTypesIfEmpty';
    constructor() {}
}

export class LoadGradeCategories {
    public static readonly type = '[Dictionary] LoadGradeCategories';
    constructor() {}
}

export class LoadLevels {
    public static readonly type = '[Dictionary] LoadLevels';
    constructor() {}
}

export class LoadSelectedYearLevels {
    public static readonly type = '[Dictionary] LoadSelectedYearLevels';
    constructor(public payload: { schoolYearId: number }) {}
}

export class ResetSelectedYearLevels {
    public static readonly type = '[Dictionary] ResetSelectedYearLevels';
    constructor() {}
}

export class LoadPolls {
    public static readonly type = '[Dictionary] LoadPolls';
    constructor() {}
}

export class LoadAdminFranchises {
    public static readonly type = '[Dictionary] LoadAdminFranchises';
    constructor() {}
}

export class LoadStreetTypes {
    public static readonly type = '[Dictionary] LoadStreetTypes';
    constructor() {}
}

export class LoadApiVersion {
    public static readonly type = '[Dictionary] LoadApiVersion';
    constructor() {}
}

export class LoadAppVersion {
    public static readonly type = '[Dictionary] LoadAppVersion';
    constructor() {}
}
