import { Component, DestroyRef, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { EscDataSource } from '../../classes/data-source/data-source';
import { FormFieldInputComponent } from '../../../forms/components/form-field-input/form-field-input.component';
import { IconComponent } from '../../../common/components/icon/icon.component';
import { EscInputDirective } from '../../../forms/directives/input.directive';

@Component({
    selector: 'esc-data-source-search-input',
    templateUrl: './data-source-search-input.component.html',
    standalone: true,
    imports: [FormsModule, FormFieldInputComponent, IconComponent, EscInputDirective],
})
export class DataSourceSearchInputComponent<T> {
    @Input({ required: true }) public dataSource!: EscDataSource<T>;
    public searchQuery = '';

    constructor(private _destroyRef: DestroyRef) {}

    public ngOnInit() {
        this.dataSource?.search$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(s => {
            this.searchQuery = s;
        });
    }

    public onSearchQueryChange() {
        this.dataSource?.search(this.searchQuery);
    }
}
