import { Component, computed, contentChild, contentChildren, Directive, effect, Inject, inject, input, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EscFormFieldControl, EscFormFieldControlNativeInput } from '../../directives/form-field-control.directive';
import { AbstractControl } from '@angular/forms';
import { TooltipDirective } from '../../../common/directives/tooltip.directive';
import { FormActionsService } from '../../services/form-actions.service';

@Directive({
    standalone: true,
    selector: '[escPrefix], [escInputPrefix]',
})
export class PrefixDirective {
    @Input('escInputPrefix')
    set _isInner(value: '') {
        this.isInner = true;
    }

    public isInner = false;
}

@Directive({
    standalone: true,
    selector: '[escSuffix], [escInputSuffix]',
})
export class SuffixDirective {
    @Input('escInputSuffix')
    set _isInner(value: '') {
        this.isInner = true;
    }

    public isInner = false;
}

// TODO: tooltip, escDisabled, maxWidth
@Component({
    selector: 'esc-form-field-input',
    standalone: true,
    imports: [CommonModule, TooltipDirective],
    templateUrl: './form-field-input.component.html',
    styleUrl: './form-field-input.component.scss',
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'esc-form-field-input',
        '[class.is-invalid]': 'controlProp("invalid")',
        '[class.is-touched]': 'controlProp("touched")',
        '[class.is-dirty]': 'controlProp("dirty")',
        '[class.is-disabled]': 'controlProp("disabled")',
        '[class.is-readonly]': 'formFieldControl()?.elementRef?.nativeElement.readOnly',
        '[class.is-submit-attempted]': 'formActions ? formActions.submitAttempted() : true',
        '[style.maxWidth.px]': 'width()',

        // '[attr.readonly]': '_formFieldControl()?.',
    },

    hostDirectives: [TooltipDirective],
})
export class FormFieldInputComponent {
    private _tooltipDirective = inject(TooltipDirective, { self: true });
    formActions = inject(FormActionsService, { optional: true });

    escDisabledTooltip = input('');
    width = input<number>();

    formFieldControl = contentChild(EscFormFieldControlNativeInput);
    private _prefixChildren = contentChildren(PrefixDirective);
    private _suffixChildren = contentChildren(SuffixDirective);

    hasPrefix = computed(() => {
        return this._prefixChildren()?.some(p => !p.isInner);
    });
    hasSuffix = computed(() => {
        return this._suffixChildren()?.some(p => !p.isInner);
    });

    constructor() {
        effect(() => {
            if (this.formFieldControl()?.escDisabled()) {
                this._tooltipDirective.message = this.escDisabledTooltip();
            } else {
                this._tooltipDirective.message = '';
            }
        });
    }

    public controlProp(prop: keyof AbstractControl): boolean {
        const f = this.formFieldControl();

        if (f) {
            return f.ngControl?.control && f.ngControl?.control[prop];
        }

        return false;
    }

    onInputClick(e: MouseEvent) {
        this.formFieldControl()?.onContainerClick(e);
    }
}
