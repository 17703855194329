import { Injectable } from '@angular/core';
import { EscToastsStateService } from './toasts-state.service';

export interface IToastDTO {
    type: 'warning' | 'error' | 'info' | 'success';
    text: string;
    subtext?: string;
    timeout?: number;
}

export interface IToast extends IToastDTO {
    id: number;
}

@Injectable({
    providedIn: 'root',
})
export class EscToastsService {
    constructor(private _toastsState: EscToastsStateService) {}

    public add(data: IToastDTO): void {
        this._toastsState.add(data);
    }
}
