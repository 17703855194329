import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    ContentChildren,
    Directive,
    effect,
    ElementRef,
    EventEmitter,
    forwardRef,
    inject,
    input,
    Input,
    model,
    output,
    Output,
    QueryList,
    signal,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { ControlValueAccessor } from '@angular/forms';
import { EscFormFieldControl, EscFormFieldControlWithValueAccessor } from '../../directives/form-field-control.directive';
import { DisabledStateDirective } from '../../directives/disabled-state.directive';

export class EscRadioChange<T> {
    constructor(
        public source: RadioComponent<T>,
        public value: T
    ) {}
}

// TODO: Aria, indeterminate, unique ID, validation
let nextUniqueId = 0;

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'esc-radio-group',
    standalone: true,
    providers: [
        { provide: EscFormFieldControl, useExisting: RadioGroupDirective },
        { provide: EscFormFieldControlWithValueAccessor, useExisting: RadioGroupDirective },
    ],
    host: {
        class: 'esc-radio-group',
        '[class.is-invalid]': 'ngControl?.invalid && showInvalidState()',
        '[attr.tabindex]': '-1',
    },
    hostDirectives: [
        {
            directive: DisabledStateDirective,
            inputs: ['escDisabled'],
        },
    ],
})
export class RadioGroupDirective<T> extends EscFormFieldControlWithValueAccessor<T> implements ControlValueAccessor {
    public readonly disabledState = inject(DisabledStateDirective, { optional: true, self: true });
    escDisabled = computed(() => {
        return this.disabledState?.escDisabled() || false;
    });

    value = model<T>();
    valueChange = output<T | undefined>();

    //
    private _name: string = this.id;
    private _selected: RadioComponent<T> | null = null;
    private _isInitialized = false;

    @Output() readonly escChange: EventEmitter<EscRadioChange<T>> = new EventEmitter<EscRadioChange<T>>();
    @ContentChildren(forwardRef(() => RadioComponent<T>), { descendants: true })
    _radios!: QueryList<RadioComponent<T>>;

    @Input({ required: true })
    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
        this._updateRadioButtonNames();
    }

    public get selected(): RadioComponent<T> | null {
        return this._selected;
    }

    set selected(selected: RadioComponent<T> | null) {
        this._selected = selected;
        this.value.set((selected ? selected.value : null) as T);
        if (this._selected && !this._selected.checked) {
            this._selected.checked = true;
        }
    }

    constructor() {
        super();

        effect(() => {
            if (this.ngControl?.value !== this.value()) {
                this._updateSelectedRadioFromValue();
                if (this._selected && !this._selected.checked) {
                    this._selected.checked = true;
                }
            }

            this.valueChange.emit(this.value());
        });

        effect(() => {
            if (this._radios) {
                this._radios.forEach(radio => {
                    radio.escDisabled = this.escDisabled();
                    radio.markForCheck();
                });
            }
        });
    }

    public ngAfterContentInit(): void {
        this._isInitialized = true;

        this._updateSelectedRadioFromValue();
        if (this._selected && !this._selected.checked) {
            this._selected.checked = true;
        }

        this._updateRadioButtonNames();
        this._updateRadioButtonDisableState();
    }

    private _updateRadioButtonNames(): void {
        if (this._radios) {
            this._radios.forEach(radio => {
                radio.name = this.name;
                radio.markForCheck();
            });
        }
    }

    private _updateRadioButtonDisableState(): void {
        if (this._radios) {
            this._radios.forEach(radio => {
                radio.escDisabled = this.escDisabled();
                radio.markForCheck();
            });
        }
    }

    private _updateRadioValidState(): void {
        if (this._radios) {
            this._radios.forEach(radio => {
                radio.escDisabled = this.escDisabled();
                radio.markForCheck();
            });
        }
    }

    private _updateSelectedRadioFromValue(): void {
        // If the value already matches the selected radio, do nothing.
        const isAlreadySelected = this._selected !== null && this._selected.value === this.value();
        if (this._radios && !isAlreadySelected) {
            this._selected = null;
            this._radios.forEach(radio => {
                radio.checked = this.value() === radio.value;
                if (radio.checked) {
                    this._selected = radio;
                }
            });
        }
    }

    emitChangeEvent(): void {
        if (this._isInitialized) {
            this.escChange.emit(new EscRadioChange(this._selected!, this.value() as T));
        }
    }

    markRadiosForCheck() {
        if (this._radios) {
            this._radios.forEach(radio => radio.markForCheck());
        }
    }

    writeValue(value: T) {
        this.value.set(value);
        this._updateSelectedRadioFromValue();
    }
}

@Component({
    selector: 'esc-radio',
    templateUrl: './radio.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    // providers: [RADIO_CONTROL_VALUE_ACCESSOR],
    standalone: true,
    imports: [CommonModule],
    host: {
        '[class.is-checked]': 'checked',
        '[class.is-active]': 'checked',
        '[style.alignSelf]': 'isBorderType() ? "stretch" : ""',
    },
})
export class RadioComponent<T> {
    private _controlValueAccessorChangeFn: (value: unknown) => void = () => {};
    private _uniqueId = `esc-radio-${++nextUniqueId}`;
    private _removeUniqueSelectionListener: () => void = () => {};

    @Input() id: string = this._uniqueId;
    @Input() name!: string;
    @Input() radioHidden = false;

    private _value: T | null = null;

    @Input()
    get value(): T | null {
        return this._value;
    }

    set value(value: T) {
        if (this._value !== value) {
            this._value = value;
            if (this.radioGroup !== null) {
                if (!this.checked) {
                    // Update checked when the value changed to match the radio group's value
                    this.checked = this.radioGroup.value === value;
                }
                if (this.checked) {
                    this.radioGroup.selected = this;
                }
            }
        }
    }

    //
    @ViewChild('input') private _inputElement!: ElementRef<HTMLInputElement>;
    @ViewChild('label') private _labelElement!: ElementRef<HTMLInputElement>;
    //

    @Output() readonly escChange: EventEmitter<EscRadioChange<T>> = new EventEmitter<EscRadioChange<T>>();

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    get radioClass(): string | string[] | Set<string> | { [key: string]: any } {
        return this._radioClass;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set radioClass(value: string | string[] | Set<string> | { [key: string]: any }) {
        this._radioClass = value;
        this._changeDetectorRef.markForCheck();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private _radioClass: string | string[] | Set<string> | { [key: string]: any } = '';

    @Input()
    get checked(): boolean {
        return this._checked;
    }

    set checked(value: BooleanInput) {
        const newCheckedState = coerceBooleanProperty(value);
        if (this._checked !== newCheckedState) {
            this._checked = newCheckedState;
            if (this._inputElement) {
                this._inputElement.nativeElement.checked = this.checked;
            }

            if (newCheckedState && this.radioGroup && this.radioGroup.value !== this.value) {
                this.radioGroup.selected = this;
            } else if (!newCheckedState && this.radioGroup && this.radioGroup.value === this.value) {
                // When unchecking the selected radio button, update the selected radio
                // property on the group.
                this.radioGroup.selected = null;
            }

            if (newCheckedState) {
                // Notify all radio buttons with the same name to un-check.
                this._radioDispatcher.notify(this.id, this.name);
            }
            this._changeDetectorRef.markForCheck();
        }
    }

    private _checked = false;

    @Input()
    get escDisabled(): boolean {
        return this._escDisabled;
    }

    set escDisabled(value: BooleanInput) {
        const newValue = coerceBooleanProperty(value);

        if (newValue !== this.escDisabled) {
            this._escDisabled = newValue;
            this._changeDetectorRef.markForCheck();
        }
    }

    private _escDisabled = false;

    @Input()
    get required(): boolean {
        return this._required;
    }

    set required(value: BooleanInput) {
        this._required = coerceBooleanProperty(value);
    }

    private _required = false;
    isBorderType = input(false);

    constructor(
        public radioGroup: RadioGroupDirective<T>,
        private _changeDetectorRef: ChangeDetectorRef,
        private _radioDispatcher: UniqueSelectionDispatcher
    ) {}

    public ngOnInit() {
        if (this.radioGroup) {
            // If the radio is inside a radio group, determine if it should be checked
            this.checked = this.radioGroup.value === this._value;

            if (this.checked) {
                this.radioGroup.selected = this;
            }

            // Copy name from parent radio group
            this.name = this.radioGroup.name;
        }

        this._removeUniqueSelectionListener = this._radioDispatcher.listen((id, name) => {
            if (id !== this.id && name === this.name) {
                this.checked = false;
            }
        });
    }

    public ngOnDestroy() {
        this._removeUniqueSelectionListener();
    }

    public markForCheck() {
        this._changeDetectorRef.markForCheck();
    }

    protected _onInteractionEvent(event: Event) {
        event.stopPropagation();

        if (!this.checked && !this.escDisabled) {
            const groupValueChanged = this.radioGroup && this.value !== this.radioGroup.value;
            this.checked = true;
            this._emitChangeEvent();

            if (this.radioGroup) {
                this.radioGroup.value.set(this.value as T);
                if (groupValueChanged) {
                    this.radioGroup.emitChangeEvent();
                }
            }
        }
    }

    private _emitChangeEvent() {
        this._controlValueAccessorChangeFn(this.checked);
        this.escChange.emit(new EscRadioChange(this, this._value!));

        if (this._inputElement) {
            this._inputElement.nativeElement.checked = this.checked;
        }
    }
}
