import {
    AfterContentInit,
    Component,
    ContentChild,
    ContentChildren,
    ElementRef,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewEncapsulation,
} from '@angular/core';
import { DropdownOptionComponent } from '../dropdown-option/dropdown-option.component';
import { defer, filter, merge, Observable, startWith, Subject, switchMap, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropdownSelectAllComponent } from '../dropdown-select-all/dropdown-select-all.component';
import { CommonModule } from '@angular/common';
import { EscSelectionModelDeprecated } from '../../../data-and-collections/classes/esc-selection-model';

let nextId = 0;

@Component({
    selector: 'esc-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [CommonModule],
    host: {
        class: 'esc-dropdown',
        role: 'listbox',
        '[id]': 'id',
        '[attr.tabindex]': '-1',
        '[attr.aria-multiselectable]': 'isMultiple',
        //       '[attr.aria-activedescendant]': '_getAriaActiveDescendant()',
        '(focus)': '_handleFocus()',
        //       '(focusout)': '_handleFocusOut($event)',
        //       '(focusin)': '_handleFocusIn()',
    },
})
export class DropdownComponent<T> implements OnInit, AfterContentInit, OnDestroy {
    @Input({ required: true }) public selectionModel!: EscSelectionModelDeprecated<T>;

    @Output() readonly escValueChange = new Subject<DropboxValueChangeEvent<T>>();

    @ContentChild(DropdownSelectAllComponent, { descendants: true }) protected selectAllOption!: DropdownSelectAllComponent<T>;
    @ContentChildren(DropdownOptionComponent, { descendants: true }) protected options?: QueryList<DropdownOptionComponent<T>>;
    protected destroyed = new Subject<void>();

    protected readonly element: HTMLElement = inject(ElementRef).nativeElement;

    private _generatedId = `esc-dropdown-${nextId++}`;
    private _compareVariable: string = 'id';

    get id() {
        return this._generatedId;
    }

    @Input('value')
    get value(): readonly T[] {
        return this.selectionModel.selection;
    }

    set value(value: readonly T[]) {
        this._setSelection(value);
    }

    @Input()
    get compareVariable(): string {
        return this.selectionModel?.uniqueVariable as string;
    }

    set compareVariable(value: string) {
        this._compareVariable = value;
    }

    get isMultiple(): boolean {
        return this.selectionModel.multiple;
    }

    private _optionClicked = defer(() => {
        if (this.options) {
            return (this.options.changes as Observable<DropdownOptionComponent<T>[]>).pipe(
                startWith(this.options),
                switchMap(options => merge(...options.map(option => option._clicked.pipe(map(event => ({ option, event }))))))
            );
        }

        return [];
    });

    private _optionFocused = defer(() => {
        if (this.options) {
            return (this.options.changes as Observable<DropdownOptionComponent<T>[]>).pipe(
                startWith(this.options),
                switchMap(options => merge(...options.map(option => option._focused.pipe(map(event => ({ option, event }))))))
            );
        }

        return [];
    });

    constructor() {}

    public ngOnInit(): void {
        if (!this.selectionModel) {
            this.selectionModel = new EscSelectionModelDeprecated<T>(undefined, this._compareVariable as unknown as keyof T);
        }
    }

    public ngAfterContentInit(): void {
        this.selectAllOption?._clicked.pipe(takeUntil(this.destroyed)).subscribe(() => {
            this._handleSelectAllClicked();
        });

        this.selectAllOption?._focused.pipe(takeUntil(this.destroyed)).subscribe(event => {
            this._handleSelectAllOptionFocused(event);
        });

        this._optionClicked
            .pipe(
                filter(({ option }) => !option.disabled),
                takeUntil(this.destroyed)
            )
            .subscribe(({ option, event }) => this._handleOptionClicked(option, event));

        this._optionFocused
            .pipe(
                filter(({ option }) => !option.disabled),
                takeUntil(this.destroyed)
            )
            .subscribe(({ option, event }) => this._handleOptionFocused(option, event));

        this.focus();
    }

    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }

    private _handleOptionClicked(option: DropdownOptionComponent<T>, event: Event) {
        event.preventDefault();

        this.triggerOption(option);
    }

    protected triggerOption(option: DropdownOptionComponent<T>) {
        if (!option.disabled) {
            this.selectionModel.toggle(option.value);

            // this._onChange(this.value);
            this.escValueChange.next({
                value: this.value,
                dropdown: this,
                option: option,
            });
        }
    }

    private _setSelection(value: readonly T[]) {
        this.selectionModel.setSelection(...value);
    }

    public isSelected(dropdownOptionComponent: DropdownOptionComponent<T>) {
        if (dropdownOptionComponent.value) {
            return this.selectionModel.isSelected(dropdownOptionComponent.value);
        } else {
            return this.selectionModel.isSelectedAll;
        }
    }

    public isSelectedAll() {
        return this.selectionModel.isSelectedAll;
    }

    public focus() {
        setTimeout(() => {
            this.element.focus();
        });
    }

    private _handleSelectAllClicked(): void {
        this.selectionModel.toggleAll();

        this.escValueChange.next({
            value: this.value,
            dropdown: this,
            option: null,
        });
    }

    private _handleOptionFocused(option: DropdownOptionComponent<T>, event: KeyboardEvent) {
        switch (event.key) {
            case 'Enter':
            case 'Space':
                this.triggerOption(option);
                break;
            case 'ArrowDown':
                (this._getNextOption(option) || this.selectAllOption || this.options?.first)?.element.focus();
                break;
            case 'ArrowUp':
                (this._getPreviousOption(option) || this.selectAllOption || this.options?.last)?.element.focus();
                break;
        }

        event.stopPropagation();
        event.preventDefault();
    }

    private _handleSelectAllOptionFocused(event: KeyboardEvent) {
        switch (event.key) {
            case 'Enter':
            case 'Space':
                this._handleSelectAllClicked();
                break;
            case 'ArrowDown':
                this.options?.first?.element.focus();
                break;
            case 'ArrowUp':
                this.options?.last?.element.focus();
                break;
        }

        event.stopPropagation();
        event.preventDefault();
    }

    private _getNextOption(option: DropdownOptionComponent<T>): DropdownOptionComponent<T> | undefined {
        return this.options?.get(this.options?.toArray().indexOf(option) + 1);
    }

    private _getPreviousOption(option: DropdownOptionComponent<T>): DropdownOptionComponent<T> | undefined {
        return this.options?.get(this.options?.toArray().indexOf(option) - 1);
    }

    _handleFocus() {}

    _handleKeydown(event: KeyboardEvent) {}
}

export interface DropboxValueChangeEvent<T> {
    readonly value: readonly T[];
    readonly dropdown: DropdownComponent<T>;
    readonly option: DropdownOptionComponent<T> | null;
}
