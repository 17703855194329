import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IEnvironment } from 'apps/early-stage-office/src/environments/environment.interface';

type THTTPOptions = {
    headers?:
        | HttpHeaders
        | {
              [header: string]: string | string[];
          };
    observe?: 'body';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    params?: any;
    reportProgress?: boolean;
    responseType?: any; // 'blob' | 'json'
    withCredentials?: boolean;
};

@Injectable({
    providedIn: 'root',
})
export class ApiClientService {
    constructor(
        @Inject('ENV') private env: IEnvironment,
        public httpClient: HttpClient
    ) {}

    public get<T>(endPoint: string, options?: THTTPOptions): Observable<T> {
        return this.httpClient.get<T>(this.env.apiUrl + endPoint, options);
    }

    public delete<T>(endPoint: string, options?: THTTPOptions): Observable<T> {
        return this.httpClient.delete<T>(this.env.apiUrl + endPoint, options);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public post<T>(endPoint: string, body?: any, options?: THTTPOptions): Observable<T> {
        if (typeof body !== 'string') {
            body = JSON.stringify(body);
        }

        return this.httpClient.post<T>(this.env.apiUrl + endPoint, body, options);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public patch<T>(endPoint: string, body?: any, options?: THTTPOptions): Observable<T> {
        if (typeof body !== 'string') {
            body = JSON.stringify(body);
        }

        return this.httpClient.patch<T>(this.env.apiUrl + endPoint, body, options);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public put<T>(endPoint: string, body?: any, options?: THTTPOptions): Observable<T> {
        return this.httpClient.put<T>(this.env.apiUrl + endPoint, body, options);
    }
}
