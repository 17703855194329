import { Component } from '@angular/core';
import { PrivacyConsentsService } from '../../services/privacy-consents.service';
import { PrivacyConsentsModalsService } from '../../services/privacy-consents-modals.service';
import { EscModalRef } from '../../../modal/classes/modal-ref';
import { IconComponent } from '../../../common/components/icon/icon.component';

@Component({
    templateUrl: './privacy-consents-info-modal.component.html',
    styleUrls: ['./privacy-consents-info-modal.component.scss'],
    standalone: true,
    imports: [IconComponent],
})
export class PrivacyConsentsInfoModalComponent {
    constructor(
        private cookiesPolicy: PrivacyConsentsService,
        private cookiesPolicyModals: PrivacyConsentsModalsService,
        protected modalRef: EscModalRef
    ) {}

    public clickAcceptAll(): void {
        this.cookiesPolicy.enableAllPrivacyConsents(true);

        this.modalRef.close();
    }

    public clickRejectAll(): void {
        this.cookiesPolicy.enableAllPrivacyConsents(false);

        this.modalRef.close();
    }

    public clickOpenCookiesSettingsModal(): void {
        this.cookiesPolicyModals.openConsentsSettingsModal();
        this.modalRef.close();
    }
}
