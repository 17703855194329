import { Injectable, InjectionToken, Injector, StaticProvider, TemplateRef, Type } from '@angular/core';
import { ComponentType, Overlay, OverlayConfig, OverlayRef, PositionStrategy, ScrollStrategy } from '@angular/cdk/overlay';
import { EscModalRef } from '../classes/modal-ref';
import { BasePortalOutlet, ComponentPortal, TemplatePortal } from '@angular/cdk/portal';
import { ModalContainerComponent } from '../components/modal-container/modal-container.component';
import { IModalConfig } from '../classes/modal-config';
import { Router } from '@angular/router';

export const MODAL_CONFIG = new InjectionToken<IModalConfig>('MODAL_CONFIG');

export type TModalData<T> = {
    [key in keyof Partial<T>]?: any;
};

@Injectable({ providedIn: 'root' })
export class EscModalService {
    private readonly _scrollStrategy: ScrollStrategy;
    private readonly _positionStrategy: PositionStrategy;
    private readonly _defaultModalConfig: IModalConfig;

    constructor(
        private _overlay: Overlay,
        private _injector: Injector,
        private _router: Router
    ) {
        this._scrollStrategy = this._overlay.scrollStrategies.block();
        this._positionStrategy = this._overlay.position().global().centerHorizontally().centerVertically();

        this._defaultModalConfig = {
            closeOnNavigation: true,
            hasBackdrop: true,
            backdropClass: ['overlay-backdrop', 'cdk-overlay-dark-backdrop'],
            scrollStrategy: this._scrollStrategy,
            positionStrategy: this._positionStrategy,
            closeOnBackdropClick: false,
            closeOnDispose: true,
            enableCloseButton: true,
            closeOnEscapeKeydown: false,
            overlayClass: ['overlay-panel'],
        };
    }

    public open<T, R = boolean>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        data: TModalData<T> = {},
        partialModalConfig: Partial<IModalConfig> = {},
        additionalProviders: StaticProvider[] = []
    ): EscModalRef<T, R> {
        const combinedModalConfig = { ...this._defaultModalConfig, ...partialModalConfig } as IModalConfig;

        const overlayConfig = this._getOverlayConfig(combinedModalConfig);
        const overlayRef = this._overlay.create(overlayConfig);
        const modalRef = new EscModalRef<T>(overlayRef, combinedModalConfig, this._router, this._overlay);

        const containerComponent = this._attachAndGetContainer(overlayRef, modalRef, combinedModalConfig, additionalProviders);
        this._attachModalContent(componentOrTemplateRef, modalRef, containerComponent, data, combinedModalConfig, additionalProviders);

        return modalRef as EscModalRef<T, R>;
    }

    private _getOverlayConfig(modalConfig: IModalConfig): OverlayConfig {
        return {
            positionStrategy: modalConfig.positionStrategy,
            scrollStrategy: modalConfig.scrollStrategy,
            hasBackdrop: modalConfig.hasBackdrop,
            backdropClass: modalConfig.backdropClass,
            panelClass: modalConfig.overlayClass,
            disposeOnNavigation: modalConfig.closeOnNavigation,
        };
    }

    private _attachAndGetContainer(
        overlay: OverlayRef,
        modalRef: EscModalRef,
        modalConfig: IModalConfig,
        additionalProviders: StaticProvider[] = []
    ): BasePortalOutlet {
        const containerType: Type<BasePortalOutlet> = ModalContainerComponent;

        const containerPortal = new ComponentPortal(containerType, null, this._createInjector(modalRef, modalConfig, additionalProviders));
        const containerRef = overlay.attach(containerPortal);

        return containerRef.instance;
    }

    private _attachModalContent<T>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        dialogRef: EscModalRef<T>,
        dialogContainer: BasePortalOutlet,
        data: TModalData<T>,
        modalConfig: IModalConfig,
        additionalProviders: StaticProvider[]
    ): void {
        if (componentOrTemplateRef instanceof TemplateRef) {
            const injector = this._createInjector(dialogRef, modalConfig, additionalProviders);
            let context: object = { dialogRef };

            if (data) {
                context = {
                    ...context,
                    ...data,
                };
            }

            dialogContainer.attachTemplatePortal(new TemplatePortal<unknown>(componentOrTemplateRef, null!, context, injector));
        } else {
            const injector = this._createInjector(dialogRef, modalConfig, additionalProviders);
            const contentRef = dialogContainer.attachComponentPortal<T>(new ComponentPortal(componentOrTemplateRef, null, injector));
            dialogRef.componentRef = contentRef;
            dialogRef.componentInstance = contentRef.instance;
            contentRef.location.nativeElement.classList.add('esc-modal__host');

            for (const dataKey in data) {
                contentRef.setInput(dataKey, data[dataKey]);
            }
        }
    }

    private _createInjector(modalRef: EscModalRef<unknown>, modalConfig: IModalConfig, additionalProviders: StaticProvider[]): Injector {
        const providers: StaticProvider[] = [
            ...additionalProviders,
            { provide: EscModalRef, useValue: modalRef },
            { provide: MODAL_CONFIG, useValue: modalConfig },
        ];

        return Injector.create({ parent: this._injector, providers });
    }
}
