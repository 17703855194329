import { Directive } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'esc-header-cell-action, [escHeaderCellAction]',
    host: {
        class: 'esc-header-cell-action',
    },
    standalone: true,
})
export class HeaderCellActionDirective {
    constructor() {}
}
