import { isString } from 'lodash';

export interface IFilterKeyItem {
    id: string;
    sortHierarchy?: number;
    icon?: {
        name: string;
        width?: number;
        height?: number;
        color?: string;
    };
}

export interface IFilterKeyDTO<T> {
    id: string;
    title: string;
    fun: (item: T) => IFilterKeyItem | string;
    sortFun?: (a: T, b: T) => number;
    additionalKeys?: IFilterKeyItem[];
}

export interface IFilterKey<T> {
    id: string;
    title: string;
    fun: (item: T) => IFilterKeyItem | string;
    sortFun?: (a: T, b: T) => number;
    enabled?: boolean;
    keys: IFilterKeyItem[];
    selectedKeys: IFilterKeyItem[];
    additionalKeys?: IFilterKeyItem[];
}

export class DataSourceKeysFilter<T> {
    public _filterKeys: { [key: string]: IFilterKey<T> } = {};

    constructor() {}

    public getFilterKey(name: string): IFilterKey<T> {
        return this._filterKeys[name];
    }

    public getActiveFilters(): IFilterKey<T>[] {
        const filters = [];
        const groups = this.getFilterKeysArray();

        for (const item of groups) {
            if (item.selectedKeys?.length > 0) {
                filters.push(item);
            }
        }

        return filters;
    }

    public defineFilterKey(key: IFilterKeyDTO<T>): void {
        this._filterKeys[key.id] = {
            ...key,
            keys: [],
            selectedKeys: [],
        };
    }

    public defineFilterKeyValues(id: string, values: IFilterKeyItem[]): void {
        if (this._filterKeys[id]) {
            this._filterKeys[id].selectedKeys = values;
        }
    }

    public clearFilterKeys(id: string): void {
        if (!this._filterKeys[id]) {
            return;
        }
        this._filterKeys[id].selectedKeys = [];
    }
    public clearAllFilterKeys(): void {
        const groups = this.getFilterKeysArray();

        for (const item of groups) {
            item.selectedKeys = [];
        }
    }

    public isFiltering(): boolean {
        const groups = this.getFilterKeysArray();

        for (const item of groups) {
            if (item.selectedKeys?.length > 0) {
                return true;
            }
        }

        return false;
    }

    private getFilterKeysArray(): IFilterKey<T>[] {
        const groups: IFilterKey<T>[] = [];
        for (const key in this._filterKeys) {
            const group = this._filterKeys[key];
            groups.push(group);
        }

        return groups;
    }

    public resolveKeys(data: T[]): void {
        const groups = this.getFilterKeysArray();

        for (const item of groups) {
            const sortedData = item.sortFun ? data.sort(item.sortFun) : data;

            const items: { [key: string]: IFilterKeyItem } = {};

            item.keys = [];

            if (item.additionalKeys) {
                for (const key of item.additionalKeys) {
                    items[key.id] = key;
                }
            }

            for (const d of sortedData) {
                const value = item.fun(d) || '';

                if (isString(value)) {
                    items[value] = {
                        id: value,
                    };
                } else {
                    items[value.id] = value;
                }
            }

            let useSorting = false;
            // tslint:disable-next-line: forin
            for (const key in items) {
                item.keys.push(items[key]);

                if (items[key].sortHierarchy) {
                    useSorting = true;
                }
            }

            if (useSorting) {
                item.keys = item.keys.sort((a, b) => {
                    return (a.sortHierarchy || 0) - (b.sortHierarchy || 0);
                });
            }
        }
    }

    public filter(data: T[] | null): T[] | null {
        if (!data) {
            return data;
        }

        this.resolveKeys(data);

        let filteredData = data;

        // tslint:disable-next-line: forin
        for (const key in this._filterKeys) {
            const group = this._filterKeys[key];
            if (!group.selectedKeys || group.selectedKeys.length === 0) {
                continue;
            }

            filteredData = filteredData.filter(d => {
                const result = group.fun(d) || '';
                const v: string = isString(result) ? result : result.id;

                return group.selectedKeys.some(k => k.id === v);
            });
        }

        return filteredData;
    }
}
