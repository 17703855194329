import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { EscDataSource } from '../../classes/data-source/data-source';
import { IFilterGroup, IFilterGroupItem } from '../../classes/data-source/data-source-filter';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'esc-data-source-filter-input',
    templateUrl: './data-source-filter-input.component.html',
    styleUrls: ['./data-source-filter-input.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule],
})
export class DataSourceFilterInputComponent<T> implements OnInit, OnDestroy {
    @Input({ required: true }) public dataSource!: EscDataSource<T>;
    @Input({ required: true }) public filterGroupId!: string;
    @Input() public type: 'quick-filter' | 'button' = 'quick-filter';

    protected filterItems!: IFilterGroupItem<T>[];
    protected filterGroup!: IFilterGroup<T>;

    private destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(private _cd: ChangeDetectorRef) {}

    public ngOnInit(): void {
        this.filterGroup = this.dataSource.getFilterGroup(this.filterGroupId);
        this.filterItems = this.dataSource.getFilterGroupItems(this.filterGroupId);

        this.dataSource
            .connect()
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.filterGroup = this.dataSource.getFilterGroup(this.filterGroupId);
                this.filterItems = this.dataSource.getFilterGroupItems(this.filterGroupId);

                this._cd.detectChanges();
            });
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public onButtonClick(item: IFilterGroupItem<T>) {
        this.dataSource.toggleFilter(this.filterGroupId, item.id);
    }
}
