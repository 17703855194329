<ng-container *ngIf="!isReadonly">
    <button
        *ngIf="!escRouterLink"
        [escTooltip]="isDisabled ? escCellDisabledTooltip : escCellTooltip"
        [escTooltipShowDelay]="isDisabled ? 100 : 1000"
        class="esc-button esc-cell-button__button is-shadowed is-small"
        [ngClass]="{ 'is-disabled': isDisabled, 'is-icon-only': iconOnly }"
        (click)="onButtonClick()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </button>
    <a
        *ngIf="escRouterLink"
        [routerLink]="escRouterLink"
        [escTooltip]="isDisabled ? escCellDisabledTooltip : escCellTooltip"
        [escTooltipShowDelay]="isDisabled ? 100 : 1000"
        class="esc-button esc-cell-button__button is-shadowed is-small"
        [ngClass]="{ 'is-disabled': isDisabled, 'is-icon-only': iconOnly }"
        (click)="onButtonClick()">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-container>
<ng-container *ngIf="isReadonly">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</ng-container>
<ng-template #content>
    <ng-content></ng-content>
</ng-template>
