import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { EscConfirmModalService } from '../../modal/modals/confirm-modal.service';

export function unsavedFormGuard(form: FormGroup, confirmService: EscConfirmModalService): CanDeactivateFn<unknown> {
    return (component: unknown, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {
        return unsavedFormCheck(form, confirmService);
    };
}

export async function unsavedFormCheck(form: FormGroup, confirmService: EscConfirmModalService): Promise<boolean> {
    if (form.pristine) {
        return true;
    }

    return await confirmService.result({
        type: 'warning',
        text: 'Czy na pewno chcesz opuścić stronę?',
        buttonText: 'Opuść',
        title: 'Zmiany nie zostały zapisane',
    });
}
