import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgFor } from '@angular/common';

import { TPrivacyConsents, TPrivacyConsentType } from '../../interfaces/privacy-consents.interface';
import { SwitchComponent } from '../../../forms/components/switch/switch.component';
import { EscModalContentDirective, EscModalFooterDirective, EscModalHeaderDirective } from '../../../modal/directives/modal-directives';
import { DividerComponent } from '../../../layout/components/divider/divider.component';
import { PrivacyConsentsService } from '../../services/privacy-consents.service';
import { EscModalRef } from '../../../modal/classes/modal-ref';

@Component({
    selector: 'esc-privacy-consents-edit-modal',
    templateUrl: './privacy-consents-edit-modal.component.html',
    styleUrls: ['./privacy-consents-edit-modal.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        SwitchComponent,
        EscModalHeaderDirective,
        EscModalContentDirective,
        EscModalFooterDirective,
        DividerComponent,
    ],
})
export class PrivacyConsentsEditModalComponent implements OnInit {
    public consents: {
        title: string;
        consentType: TPrivacyConsentType;
        text: string;
    }[] = [
        {
            title: 'Personalizacyjne pliki cookies',
            text: 'Umożliwia przechowywanie związane z personalizacją, np. rekomendacje wideo',
            consentType: 'personalization',
        },
        {
            title: 'Pliki cookies zabezpieczeń',
            text: 'Umożliwia przechowywanie związane z bezpieczeństwem, takie jak funkcja uwierzytelniania, zapobieganie oszustwom i inna ochrona użytkownika',
            consentType: 'security',
        },
        {
            title: 'Funkcjonalne pliki cookies',
            text: 'Umożliwiające ‘zapamiętanie’ wybranych przez Użytkownika ustawień',
            consentType: 'functionality',
        },
        {
            title: 'Analityczne pliki cookies',
            text: 'Służące do analizy i polepszania funkcjonalności strony',
            consentType: 'analytics',
        },
        {
            title: 'Reklamowe pliki cookies',
            text: 'Umożliwiające zapamiętanie zachowania Użytkownika na stronie i kierowania odpowiednich reklam',
            consentType: 'ad',
        },
    ];

    public form!: UntypedFormGroup;

    constructor(
        private privacyConsents: PrivacyConsentsService,
        protected modalRef: EscModalRef
    ) {}

    public ngOnInit(): void {
        this.form = new UntypedFormGroup({});

        for (const consentsKey in this.privacyConsents.getConsents()) {
            const consent = this.privacyConsents.getConsents()[consentsKey as TPrivacyConsentType];

            this.form.addControl(consentsKey, new UntypedFormControl(consent === 'granted'));
        }
    }

    public submit(): void {
        const formValue = this.form.value;
        const consents: TPrivacyConsents = {
            personalization: formValue.personalization === true ? 'granted' : 'denied',
            security: formValue.security === true ? 'granted' : 'denied',
            functionality: formValue.functionality === true ? 'granted' : 'denied',
            analytics: formValue.analytics === true ? 'granted' : 'denied',
            ad: formValue.ad === true ? 'granted' : 'denied',
        };

        this.privacyConsents.setConsents(consents);
        this.modalRef.close();
    }

    public decline(): void {
        this.privacyConsents.enableAllPrivacyConsents(false);
        this.modalRef.close();
    }
}
