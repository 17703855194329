<div class="esc-checkbox">
    <input
        #checkbox
        class="checkbox"
        type="checkbox"
        [name]="''"
        [disabled]="disabled$ | async"
        [escTooltip]="disabledTooltip$ | async"
        (change)="onCheckboxChange($event)" />
</div>
