import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { EscSelectionModelDeprecated } from '../../classes/esc-selection-model';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from '../../../common/directives/tooltip.directive';

@Component({
    selector: 'esc-collection-capsule-button',
    templateUrl: './collection-capsule-button.component.html',
    styleUrls: ['./collection-capsule-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, TooltipDirective],
})
export class CollectionCapsuleButtonComponent<T> implements OnDestroy {
    protected destroy$: Subject<boolean> = new Subject<boolean>();
    private _selectionModel!: EscSelectionModelDeprecated<T>;

    @Input() public disabled = false;
    @Input() public disabledTooltip?: string;
    @Input() public disabledFunc?: (items: unknown[]) => boolean;

    public tooltip?: string;

    @Output() public escClick: EventEmitter<T[]> = new EventEmitter<T[]>();

    constructor(private _cd: ChangeDetectorRef) {}

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }

    public initCollection(collectionModel: EscSelectionModelDeprecated<T>): void {
        this._selectionModel = collectionModel;

        if (this._selectionModel) {
            this._selectionModel.changed.pipe(takeUntil(this.destroy$)).subscribe(v => {
                if (this.disabledFunc) {
                    this.disabled = this.disabledFunc(v.collection);
                }
                this.tooltip = this.disabled && this.disabledTooltip ? this.disabledTooltip : undefined;
                this._cd.detectChanges();
            });
        }
    }

    public onButtonClick(): void {
        if (this.disabled) {
            return;
        }

        this.escClick.emit(this._selectionModel?.selection);
        this._selectionModel.clear();
    }
}
