import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'esc-divider',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './divider.component.html',
    styleUrl: './divider.component.scss',
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'esc-divider',
    },
})
export class DividerComponent {}
