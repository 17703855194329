import { booleanAttribute, Component, Input, signal, ViewEncapsulation, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, AnimationTriggerMetadata, state, style, transition, trigger } from '@angular/animations';

// TODO: Lazy loaded content

let uniqueId = 0;

const bodyExpansion: AnimationTriggerMetadata = trigger('bodyExpansion', [
    state('collapsed, void', style({ height: '0px', visibility: 'hidden', display: 'none' })),
    state('expanded', style({ height: '*', visibility: '' })),
    transition('expanded <=> collapsed, void => collapsed', animate(200)),
]);

@Component({
    selector: 'esc-accordion',
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule],
    templateUrl: './accordion.component.html',
    styleUrl: './accordion.component.scss',
    host: {
        class: 'esc-accordion',
        '[class.is-expanded]': 'expanded()',
    },
    animations: [bodyExpansion],
})
export class AccordionComponent {
    @Input({ transform: booleanAttribute }) disabled: boolean = false;
    @Input('expanded') public initialExpanded: boolean = false;
    public readonly expanded: WritableSignal<boolean> = signal(true);
    public readonly headerId = `esc-accordion-header-${uniqueId++}`;
    public readonly id = `esc-accordion-${uniqueId++}`;

    public ngOnInit(): void {
        this.expanded.set(this.initialExpanded);
    }

    public toggle(): void {
        this.expanded.set(!this.expanded());
    }

    getExpandedState(): 'expanded' | 'collapsed' {
        return this.expanded() ? 'expanded' : 'collapsed';
    }
}
