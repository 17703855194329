<div class="esc-form-field-header flex-col is-gap-tiny">
    <ng-content select="esc-form-field-title"></ng-content>
    <ng-content select="esc-form-field-sub-title"></ng-content>
</div>

<div class="esc-form-field-content">
    <ng-content></ng-content>
</div>

<ng-content select="esc-form-field-description"></ng-content>
<ng-content select="esc-form-field-info"></ng-content>

<ng-content select="owl-date-time"></ng-content>

@for (error of validationErrors$ | async; track error.key) {
    @if (_control()?.showInvalidState()) {
        <esc-validation-text [type]="'error'">
            {{ error.text }}
        </esc-validation-text>
    }
}
