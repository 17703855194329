import { Component, contentChild, DestroyRef, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayContentDirective, OverlayPanelComponent } from '../../../../layout/components/overlay-panel/overlay-panel.component';
import { IconComponent } from '../../icon/icon.component';
import { ContextMenuComponent } from '../context-menu.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'esc-context-menu-dots',
    standalone: true,
    imports: [CommonModule, OverlayContentDirective, OverlayPanelComponent, IconComponent],
    templateUrl: './context-menu-dots.component.html',
    styleUrl: './context-menu-dots.component.scss',
})
export class ContextMenuDotsComponent {
    // Inputs
    private _destroyRef = inject(DestroyRef);

    // Signals
    open = signal(false);

    menu = contentChild(ContextMenuComponent);

    ngAfterContentInit() {
        this.menu()
            ?.closeRequest.pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.open.set(false);
            });
    }

    public openPanel(): void {
        this.open.set(true);
    }
}
