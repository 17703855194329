import { Host, Injectable, Optional, signal, Signal, ViewContainerRef } from '@angular/core';
import { firstValueFrom, fromEvent, Observable, Subject, throwError } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, UntypedFormGroup } from '@angular/forms';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { TErrorMessage, ValidationMessagesService } from './validation-messages.service';
import { ActivatedRoute } from '@angular/router';
import { unsavedFormCheck, unsavedFormGuard } from '../guards/unsaved-form.guard';
import { TabGroupComponent } from '../../layout/components/tab-group/tab-group.component';
import { EscConfirmModalService } from '../../modal/modals/confirm-modal.service';
import { toObservable } from '@angular/core/rxjs-interop';

export enum EFormState {
    StateInitial = 'initial',
    StateBeforeLoading = 'before',
    StateLoading = 'loading',
    StateSuccess = 'success',
    StateError = 'error',
}

export enum EFormActionsEvent {
    BeforeValidate = 'beforeValidate',
    AfterValidate = 'afterValidate',
    SubmitError = 'submitError',
    SubmitSuccess = 'submitSuccess',
}

@Injectable()
export class FormActionsService {
    //
    private _initialized = false;
    private _useUnsavedGuard = false;
    private _formGroup!: FormGroup;
    private _formState = signal(EFormState.StateInitial);
    private _submitAttempted = signal(false);

    private _destroy$: Subject<boolean> = new Subject<boolean>();
    private _events: Subject<{ type: EFormActionsEvent; data: unknown }> = new Subject<{
        type: EFormActionsEvent;
        data: unknown;
    }>();

    //
    public errorMessage$!: Observable<TErrorMessage[]>;

    // getters
    public get formGroup(): FormGroup {
        return this._formGroup;
    }

    public get formState(): Signal<EFormState> {
        return this._formState;
    }

    public get submitAttempted(): Signal<boolean> {
        return this._submitAttempted.asReadonly();
    }

    public formState$ = toObservable(this.formState);

    public get events(): Observable<{
        type: EFormActionsEvent;
        data: unknown;
    }> {
        return this._events.asObservable();
    }

    public get beforeValidate$(): Observable<FormGroup> {
        return this._events.asObservable().pipe(
            filter(a => a.type === EFormActionsEvent.BeforeValidate),
            map(a => a.data as FormGroup)
        );
    }

    public get submitError$(): Observable<Error> {
        return this._events.asObservable().pipe(
            filter(a => a.type === EFormActionsEvent.SubmitError),
            map(a => a.data as Error)
        );
    }

    public get submitSuccess$(): Observable<unknown> {
        return this._events.asObservable().pipe(
            filter(a => a.type === EFormActionsEvent.SubmitSuccess),
            map(a => a.data)
        );
    }

    constructor(
        public validationMessages: ValidationMessagesService,
        @Optional() private _viewContainerRef: ViewContainerRef,
        private _activatedRoute: ActivatedRoute,
        @Host() @Optional() private _tabs: TabGroupComponent,
        private _confirmService: EscConfirmModalService
    ) {}

    public connect(formGroup: FormGroup, useUnsavedGuard = false): void {
        if (this._initialized) {
            throw new Error('Form group already connected');
        }

        this._initialized = true;

        this._formGroup = formGroup;
        this._useUnsavedGuard = useUnsavedGuard;
        this._formState.set(EFormState.StateInitial);

        if (this._useUnsavedGuard) {
            if (this._tabs) {
                this._tabs.canDeactivate = () => unsavedFormCheck(formGroup, this._confirmService);
            }

            if (this._activatedRoute.routeConfig) {
                this._activatedRoute.routeConfig.canDeactivate = [unsavedFormGuard(formGroup, this._confirmService)];
            }

            fromEvent(window, 'beforeunload')
                .pipe(takeUntil(this._destroy$))
                .subscribe(e => {
                    if (!this._formGroup.pristine) {
                        e.preventDefault();
                    }
                });
        }

        this.errorMessage$ = this._formGroup.statusChanges.pipe(
            map(() => {
                return this.validationMessages.parseMessage(this._formGroup.errors);
            })
        );
    }

    public async trySubmit(submit: () => Observable<unknown>, beforeSumit?: () => Promise<boolean>): Promise<boolean> {
        if (!this._initialized) {
            throw new Error('Form group is not connected');
        }
        const formState = await firstValueFrom(this.formState$);

        if (formState === EFormState.StateLoading || formState === EFormState.StateBeforeLoading) {
            return false;
        }

        this._submitAttempted.set(true);

        this._formState.set(EFormState.StateBeforeLoading);

        this._events.next({
            type: EFormActionsEvent.BeforeValidate,
            data: this._formGroup,
        });
        this._validateFields(this._formGroup);
        this._events.next({
            type: EFormActionsEvent.AfterValidate,
            data: this._formGroup,
        });

        if (!this._formGroup.valid) {
            this._formState.set(EFormState.StateError);

            return false;
        }

        if (beforeSumit) {
            const result = await beforeSumit();

            if (!result) {
                this._formState.set(EFormState.StateInitial);
                return false;
            }
        }

        this._formState.set(EFormState.StateLoading);

        const s = submit().pipe(take(1), takeUntil(this._destroy$));

        s.subscribe({
            next: a => {
                this._formState.set(EFormState.StateSuccess);
                this._formGroup.markAsPristine();

                this._events.next({
                    type: EFormActionsEvent.SubmitSuccess,
                    data: a,
                });
            },
            error: e => {
                this._formState.set(EFormState.StateError);
                this._events.next({
                    type: EFormActionsEvent.SubmitError,
                    data: e,
                });

                if (e.error && e.error.errors) {
                    this._parseServerSideErrors(e.error.errors);

                    this._events.next({
                        type: EFormActionsEvent.AfterValidate,
                        data: this._formGroup,
                    });
                } else {
                    if (navigator.onLine) {
                        this._formGroup.setErrors({
                            ssr: 'Spróbuj ponownie później lub/i skontaktuj się z administratorem',
                        });
                    } else {
                        this._formGroup.setErrors({
                            ssr: 'Wystąpił problem z połączeniem internetowym',
                        });
                    }
                }

                return throwError(e);
            },
        });

        // return await firstValueFrom(s as Observable<boolean>);
        return true;
    }

    /**
     * @deprecated empty method, can be removed
     */
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public disconnect(): void {}

    public ngOnDestroy(): void {
        if (this._useUnsavedGuard) {
            if (this._tabs) {
                this._tabs.canDeactivate = async () => true;
            }

            if (this._activatedRoute.routeConfig) {
                this._activatedRoute.routeConfig.canDeactivate = [];
            }
        }
        this._destroy$.next(true);
        this._destroy$.unsubscribe();
    }

    private _validateFields(group: FormGroup): void {
        Object.keys(group.controls).forEach(field => {
            const control = group.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
                control.markAsDirty({ onlySelf: true });

                control.updateValueAndValidity();
            } else if (control instanceof FormGroup) {
                this._validateFields(control);
            }

            if ((control as FormGroup).controls) {
                this._validateFields(control as FormGroup);
            }
        });

        this._scrollToFirstInvalidInput();
    }

    private _parseServerSideErrors(errors: { general: string; fields: { [key: string]: string } }) {
        if (!errors) {
            return;
        }

        if (errors.general) {
            this._formGroup.setErrors({ ssr: errors.general });
        }

        for (const key in errors.fields) {
            const error = errors.fields[key];
            this._parseServerSideErrorsChildren(this._formGroup, key, error);
        }

        this._scrollToFirstInvalidInput();
    }

    private _parseServerSideErrorsChildren(parent: AbstractControl, key: string, error: string | Array<string>): boolean {
        const field = parent.get(key);

        if (!field && (parent as UntypedFormGroup).controls) {
            Object.keys((parent as UntypedFormGroup).controls).forEach(child => {
                const control = parent.get(child);
                if (control) {
                    this._parseServerSideErrorsChildren(control, key, error);

                    // TODO:
                    /*if (control.ssrControlName === key) {
                        field = control;
                    }*/
                }
            });
        }

        if (!field) {
            return false;
        }

        if (typeof error === 'string' || typeof error === 'number') {
            field.markAsDirty();
            field.markAsTouched();
            field.setErrors({ ssr: error });
            return true;
        } else {
            try {
                for (const keyChild in error) {
                    const errorChild = error[keyChild];
                    if (this._parseServerSideErrorsChildren(field, keyChild, errorChild)) {
                        return true;
                    }
                }
            } catch (error) {
                //
            }
        }

        return false;
    }

    private _scrollToFirstInvalidInput(): void {
        setTimeout(() => {
            const formElement = this._viewContainerRef?.element?.nativeElement?.querySelector('form');
            const firstInvalidElementGroup = formElement?.querySelector('.is-error, .is-invalid');

            if (firstInvalidElementGroup) {
                const firstInvalidElement = firstInvalidElementGroup.querySelector('input, .select, textarea') as HTMLElement;

                if (firstInvalidElement) {
                    const bodyRect = document.body.getBoundingClientRect().top;
                    const elementPosition = firstInvalidElement?.getBoundingClientRect().top - bodyRect;
                    const offsetPosition = elementPosition - 100;

                    if (!document.body.hasAttribute('data-scroll-lock-saved-inline-overflow-y-property')) {
                        window.scrollTo({
                            top: offsetPosition,
                            behavior: 'auto',
                        });
                    }

                    setTimeout(() => {
                        firstInvalidElement.focus();
                    });
                }
            }
        });
    }
}

/**
 * submit actions
 * success/error events
 * getFormState
 * connect
 *
 */
