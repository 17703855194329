import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { TPrivacyConsents, TPrivacyConsentType } from '../interfaces/privacy-consents.interface';

// eslint-disable-next-line @typescript-eslint/ban-types
declare const gtag: Function | undefined;

@Injectable({
    providedIn: 'root',
})
export class PrivacyConsentsService {
    public types: TPrivacyConsentType[] = ['analytics', 'personalization', 'ad', 'functionality', 'security'];

    constructor(private cookies: CookieService) {}

    public enableAllPrivacyConsents(value?: boolean): void {
        if (value === undefined) {
            value = true;
        }

        for (const consentType of this.types) {
            this.cookies.set(consentType + '_storage', value ? 'granted' : 'denied', { path: '/', expires: 365 });
        }

        this.sendConsents();
    }

    public setConsents(consents: TPrivacyConsents): void {
        for (const consentsKey in consents) {
            this.cookies.set(consentsKey + '_storage', consents[consentsKey as TPrivacyConsentType], { path: '/', expires: 365 });
        }

        this.sendConsents();
    }

    public getConsents(): TPrivacyConsents {
        const consents: Partial<TPrivacyConsents> = {};

        for (const consentType of this.types) {
            consents[consentType] = this.cookies.get(consentType + '_storage') as 'granted' | 'denied';
        }

        return consents as TPrivacyConsents;
    }

    public areAllConsentSaved(): boolean {
        for (const consentType of this.types) {
            if (this.cookies.get(consentType + '_storage') === '') {
                return false;
            }
        }

        return true;
    }

    public sendConsents(): void {
        const consents: { [key: string]: string } = {};

        for (const cookieType of this.types) {
            consents[cookieType + '_storage'] = this.cookies.get(cookieType + '_storage') || 'denied';
        }

        if (gtag) {
            gtag('consent', 'update', consents);
        }
    }
}
