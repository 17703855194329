@if (hasPrefix()) {
    <div class="esc-form-field-input__prefix" [ngClass]="{ 'is-default': true }">
        <ng-content select="[escPrefix]"></ng-content>
    </div>
}

<div
    (click)="onInputClick($event)"
    class="esc-form-field-input-item"
    [ngClass]="{
        'has-prefix': hasPrefix(),
        'has-suffix': hasSuffix()
    }">
    <ng-content select="[escInputPrefix]"></ng-content>
    <ng-content></ng-content>
    <ng-content select="[escInputSuffix]"></ng-content>
</div>

@if (hasSuffix()) {
    <div class="esc-form-field-input__suffix">
        <ng-content select="[escSuffix]"></ng-content>
    </div>
}
