import { computed, Directive, ElementRef, inject, input } from '@angular/core';
import { EscFormFieldControl, EscFormFieldControlNativeInput } from './form-field-control.directive';
import { NgControl } from '@angular/forms';
import { DisabledStateDirective } from './disabled-state.directive';

@Directive({
    selector: `input[escInput], textarea[escInput]`,
    exportAs: 'escInput',
    host: {
        '[class.is-textarea]': '_isTextarea',
        '[id]': 'id',
        '[attr.id]': 'id',
        '(keydown)': 'handleKeyDown($event)',
    },
    hostDirectives: [
        {
            directive: DisabledStateDirective,
            inputs: ['escDisabled'],
        },
    ],
    providers: [
        { provide: EscFormFieldControlNativeInput, useExisting: EscInputDirective },
        { provide: EscFormFieldControl, useExisting: EscInputDirective },
    ],
    standalone: true,
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class EscInputDirective extends EscFormFieldControlNativeInput {
    public readonly elementRef = inject(ElementRef<HTMLInputElement>, { optional: true, self: true });
    public readonly ngControl = inject(NgControl, { optional: true, self: true });
    public readonly disabledState = inject(DisabledStateDirective, { optional: true, self: true });
    _isTextarea = false;

    escDisabled = computed(() => {
        return this.disabledState?.escDisabled() || false;
    });

    constructor() {
        super();
    }

    onContainerClick(): void {
        setTimeout(() => {
            this.elementRef?.nativeElement.focus();
        });
    }

    _onInput() {
        // TODO: Add input masking/pattern
    }

    handleKeyDown($event: KeyboardEvent) {
        setTimeout(() => {});
    }
}
