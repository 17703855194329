import { BehaviorSubject, Subscription, Observable, isObservable, combineLatest } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { DataSourceSearch } from './data-source-search';
import { map } from 'rxjs/operators';
import { DataSourceSorter } from './data-source-sorter';
import { DataSourceFilter, IFilterGroup, IFilterGroupDTO, IFilterGroupItem, IFilterGroupItemDTO } from './data-source-filter';
import { DataSourceKeysFilter, IFilterKey, IFilterKeyDTO, IFilterKeyItem } from './data-source-keys-filter';

export type TSortDirection = 'ASC' | 'DESC';

export class EscDataSource<T> extends DataSource<T> {
    private _keysFilterQuery: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private _keysFilter: DataSourceKeysFilter<T>;

    private _filterQuery: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    private _filter: DataSourceFilter<T>;

    private _searchQuery: BehaviorSubject<string> = new BehaviorSubject<string>('');
    private _search: DataSourceSearch<T>;

    private _sortQuery: BehaviorSubject<{ name?: string; direction: TSortDirection }> = new BehaviorSubject({
        direction: 'ASC' as TSortDirection,
    });

    private _sorter: DataSourceSorter<T>;

    private _subject: BehaviorSubject<T[] | null> = new BehaviorSubject<T[] | null>(null);
    private _renderSubject: BehaviorSubject<T[] | null> = new BehaviorSubject<T[] | null>(null);
    private _renderSubscription!: Subscription;
    private _dataSubscription!: Subscription;

    public get search$(): Observable<string> {
        return this._searchQuery;
    }

    public get filter$(): Observable<boolean> {
        return this._filterQuery;
    }

    public get keysFilter$(): Observable<boolean> {
        return this._keysFilterQuery;
    }

    public get sort$(): Observable<{ name?: string; direction: 'ASC' | 'DESC' }> {
        return this._sortQuery;
    }

    public get currentSortValue(): { name?: string; direction: 'ASC' | 'DESC' } {
        return this._sortQuery.value;
    }

    public get currentValue(): T[] | null {
        return this._renderSubject.value;
    }

    public get currentSearchQuery(): string {
        return this._searchQuery.value;
    }

    public get length(): number {
        return this._renderSubject.value ? this._renderSubject.value.length : 0;
    }

    public get fullLength(): number {
        return this._subject.value ? this._subject.value.length : 0;
    }

    constructor(data?: Observable<T[]> | T[]) {
        super();
        this._search = new DataSourceSearch();
        this._filter = new DataSourceFilter();
        this._keysFilter = new DataSourceKeysFilter();
        this._sorter = new DataSourceSorter();

        this._updateRenderSubscription();

        if (data) {
            if (isObservable(data)) {
                this._dataSubscription = data.subscribe(d => {
                    this._subject.next(d);
                });
            } else {
                this._subject.next(data as T[]);
            }
        }
    }

    public next(data: T[] | Observable<T[]>) {
        if (this._dataSubscription) {
            this._dataSubscription.unsubscribe();
        }

        if (isObservable(data)) {
            this._dataSubscription = data.subscribe(d => {
                this._subject.next(d);
            });
        } else {
            this._subject.next(data as T[]);
        }
    }

    public connect(): Observable<T[]> {
        return this._renderSubject.asObservable() as Observable<T[]>;
    }

    public disconnect(): void {}

    public destroy(): void {
        this._subject.next(null);
        this._subject.unsubscribe();

        if (this._renderSubscription) {
            this._renderSubscription.unsubscribe();
        }

        if (this._dataSubscription) {
            this._dataSubscription.unsubscribe();
        }
    }

    /**
     * Keys Filtering
     */
    public keysFilter(): void {
        this._keysFilterQuery.next(true);
    }

    public defineFilterKey(key: IFilterKeyDTO<T>): void {
        this._keysFilter.defineFilterKey(key);
    }

    public defineFilterKeyValues(id: string, values: IFilterKeyItem[]): void {
        this._keysFilter.defineFilterKeyValues(id, values);
    }

    public getFilterKey(id: string): IFilterKey<T> {
        return this._keysFilter.getFilterKey(id);
    }

    public clearFilterKey(id: string): void {
        return this._keysFilter.clearFilterKeys(id);
    }

    public clearAllFilterKeys(): void {
        return this._keysFilter.clearAllFilterKeys();
    }

    public getActiveFilterKeys(): IFilterKey<T>[] {
        return this._keysFilter.getActiveFilters();
    }

    public isKeyFiltering(): boolean {
        return this._keysFilter.isFiltering();
    }

    public getEnabledKeyFilterNames(): Array<string> {
        return this._keysFilter.getActiveFilters().map(s => {
            return `<b>${s.title}:</b> ${s.selectedKeys.map(k => k.id).join(' <b>lub</b>')}`;
        });
    }

    /**
     * Filtering
     */
    public filter(): void {
        this._filterQuery.next(true);
    }

    public toggleFilter(groupName: string, itemName: string, enable?: boolean): void {
        this._filter.toggleFilter(groupName, itemName, enable);
        this.filter();
    }

    public defineFilterGroup(group: IFilterGroupDTO<T>): void {
        this._filter.defineFilterGroup(group);
        this.filter();
    }

    public defineFilterGroupItem(groupName: string, item: IFilterGroupItemDTO<T>): void {
        this._filter.defineFilterGroupItem(groupName, item);
        this.filter();
    }

    public getFilterGroup(groupName: string): IFilterGroup<T> {
        return this._filter.getFilterGroup(groupName);
    }

    public getFilterGroupItems(groupName: string): Array<IFilterGroupItem<T>> {
        return this._filter.getFilterItems(groupName);
    }

    public getEnabledFilters(): Array<IFilterGroupItem<T>> {
        return this._filter.getEnabledFilters();
    }

    public getEnabledFilterNames(): Array<string> {
        return this._filter.getEnabledFilterGroups().map(s => {
            return `<b>${s.title}: </b>${s.fields
                .filter(f => f.enabled)
                .map(k => k.title)
                .join(s.filterCompare === 'or' ? ' <b>lub</b> ' : ' <b>oraz</b> ')}`;
        });
    }

    public clearFilters(): void {
        this._filter.clearFilters();
        this.filter();
    }

    public isFiltering(): boolean {
        return this._filter.isFiltering();
    }

    /**
     * Searching
     */
    public search(query: string): void {
        this._searchQuery.next(query);
    }

    public defineSearchItemKeys(keys: Array<keyof T>) {
        this._search.parseItemKeys = keys;
        this._searchQuery.next(this._searchQuery.value);
    }

    public defineSearchParseFunc(fn: (item: T) => string[]) {
        this._search.setCustomParseFunc(fn);
        this._searchQuery.next(this._searchQuery.value);
    }

    /* */

    /**
     * Sorting
     */
    public sort(name: string, direction?: 'ASC' | 'DESC'): void {
        if (!direction) {
            if (this.currentSortValue.name === name) {
                direction = this.currentSortValue.direction === 'DESC' ? 'ASC' : 'DESC';
            } else {
                direction = 'ASC';
            }
        }

        this._sortQuery.next({ name, direction });
    }

    public addSortType(name: string, fn: (a: T, b: T) => number) {
        this._sorter.addSortType(name, fn);
    }

    /* */

    private _updateRenderSubscription(): void {
        const filteredData = combineLatest([this._subject, this.filter$]).pipe(
            map(([data]) => {
                return this._filter.filter(data);
            })
        );

        const keysFilteredData = combineLatest([filteredData, this.keysFilter$]).pipe(
            map(([data]) => {
                return this._keysFilter.filter(data);
            })
        );

        const searchedData = combineLatest([keysFilteredData, this.search$]).pipe(
            map(([data, query]) => {
                return this._search.search(data, query);
            })
        );

        const sortedData = combineLatest([searchedData, this.sort$]).pipe(
            map(([data, query]) => {
                return query && query.name ? this._sorter.sortBy(data, query.name, query.direction || undefined) : data;
            })
        );

        if (this._renderSubscription) {
            this._renderSubscription.unsubscribe();
        }

        this._renderSubscription = new Subscription();

        this._renderSubscription = sortedData.subscribe(data => {
            this._renderSubject.next(data);
        });
    }
}
