import { OptionComponent } from './option.component';
import { EscSelectionModel } from '../../../../data-and-collections/classes/esc-selection-model';
import { InjectionToken, Signal } from '@angular/core';

export interface EscOptionParentComponent<T> {
    multiple: Signal<boolean>;
    selectionModel: EscSelectionModel<T>;
    onOptionKeydown(event: KeyboardEvent, option: OptionComponent<T>): void;
    onOptionClick(event: MouseEvent, option: OptionComponent<T>): void;
}

export const ESC_OPTION_PARENT_COMPONENT = new InjectionToken<EscOptionParentComponent<any>>('ESC_OPTION_PARENT_COMPONENT');
