import { Pipe, PipeTransform } from '@angular/core';
import { getPolishPlural } from '../classes/functions';

// Source: https://medium.com/@thunderroid/angular-date-ago-pipe-minutes-hours-days-months-years-ago-c4b5efae5fe5

@Pipe({
    name: 'escDateAgo',
    pure: true,
    standalone: true,
})
export class DateAgoPipe implements PipeTransform {
    transform(value: string | Date, short: boolean = false, daysOnly: boolean = false): any {
        if (value) {
            const current = new Date();
            const valueDate = new Date(value);

            if (daysOnly) {
                current.setHours(0, 0, 0, 0);
                valueDate.setHours(0, 0, 0, 0);
            }
            let seconds = Math.floor((+current - +valueDate) / 1000);
            const isNegative = seconds < 0;

            seconds = Math.abs(seconds);

            if (seconds < 29 && !daysOnly) return 'Teraz';

            const intervals = [
                { elapsed: 31536000, names: ['rok', 'lat'] },
                { elapsed: 2592000, names: ['miesiąc', 'miesiące', 'miesięcy'] },
                { elapsed: 604800, names: ['tydzień', 'tygodnie', 'tygodni'] },
                { elapsed: 86400, names: ['dzień', 'dni'] },
                { elapsed: 3600, names: ['godzina', 'godziny', 'godzin'] },
                { elapsed: 60, names: ['minuta', 'minuty', 'minut'] },
                { elapsed: 1, names: ['sekunda', 'sekundy', 'sekund'] },
            ];

            let counter;
            let sufix = isNegative ? ' od dzisiaj' : ' temu';

            if (short) {
                sufix = '';
            }

            if (daysOnly) {
                counter = Math.ceil(seconds / intervals[3].elapsed);

                if (counter === 0) {
                    return 'dzisiaj';
                }

                return counter + ' ' + getPolishPlural(intervals[3].names, counter) + sufix; // singular (1 day ago)
            } else {
                for (const interval of intervals) {
                    counter = Math.floor(seconds / interval.elapsed);
                    if (counter > 0) {
                        return counter + ' ' + getPolishPlural(interval.names, counter) + sufix; // singular (1 day ago)
                    }
                }
            }
        }
        return value;
    }
}
