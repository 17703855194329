import { Component, input, InputSignal, OnInit, signal, WritableSignal } from '@angular/core';
import { NgIf, NgStyle } from '@angular/common';
import { IconComponent, TIconName } from '../icon/icon.component';

@Component({
    selector: 'esc-info-text',
    templateUrl: './info-text.component.html',
    styleUrls: ['./info-text.component.scss'],
    standalone: true,
    imports: [NgStyle, NgIf, IconComponent],
})
export class InfoTextComponent implements OnInit {
    public icon: WritableSignal<TIconName | null> = signal(null);
    public type: InputSignal<'info' | 'warning' | 'success' | 'error'> = input('info' as 'info' | 'warning' | 'success' | 'error');
    public alternative = input(false);
    public align: InputSignal<string> = input('flex-start');

    public ngOnInit(): void {
        if (!this.icon()) {
            switch (this.type()) {
                case 'warning':
                    this.icon.set('warning');
                    break;
                case 'success':
                    this.icon.set('check_circle');
                    break;
                default:
                    this.icon.set('info');
            }
        }
    }
}
