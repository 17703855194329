<div class="esc-tab-header esc-tab-group__header">
    <div class="esc-tab-header__content">
        <div class="esc-tab-header__links">
            @for (tab of tabs?.toArray(); track tab.tabId; let i = $index) {
                <div
                    class="esc-tab-group__link esc-tab-link"
                    [ngClass]="{
                        'is-active': selectedTab?.tabId === tab.tabId
                    }">
                    <button
                        (click)="handleClick(tab)"
                        [escTooltip]="tab.escDisabled ? tab.disabledTooltip : ''"
                        [escTooltipClass]="'is-multiline'"
                        class="esc-button is-shadowed"
                        [ngClass]="{ 'is-disabled': tab.escDisabled }"
                        [type]="'button'">
                        {{ tab.label }}
                    </button>
                </div>
            }
        </div>

        <div class="esc-tab-header__buttons">
            <ng-content></ng-content>
        </div>
    </div>
</div>

<div class="esc-tab-group__content" #tabContent>
    <ng-template [cdkPortalOutlet]="selectedPortal"></ng-template>
</div>
