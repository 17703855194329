<esc-modal-content>
    <div class="flex is-flex-align-start">
        <div class="confirm-modal-icon is-{{ data().type }}">
            <esc-icon [icon]="icon" fill="true"></esc-icon>
        </div>
        <div class="flex-col">
            <p class="confirm-modal-title" [innerHTML]="data().title"></p>
            <p [innerHTML]="data().text" class="text-color-grey-700 text-body-small"></p>
        </div>
    </div>
</esc-modal-content>
<esc-modal-footer>
    <div class="confirm-modal-buttons">
        <button class="link is-underline" (click)="close(false)">Anuluj</button>
        <button class="esc-button is-{{ data().type }}" (click)="close(true)" [innerHTML]="data().buttonText"></button>
    </div>
</esc-modal-footer>
