import {
    ChangeDetectorRef,
    Component,
    contentChild,
    DestroyRef,
    Directive,
    effect,
    EventEmitter,
    Injector,
    model,
    ModelSignal,
    OnDestroy,
    Output,
    TemplateRef,
    viewChild,
    ViewContainerRef,
} from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { FlexibleConnectedPositionStrategyOrigin, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
    selector: 'ng-template[escOverlayContent]',
    standalone: true,
})
export class OverlayContentDirective {
    constructor(public template: TemplateRef<unknown>) {}
}

@Component({
    selector: 'esc-overlay-panel',
    templateUrl: './overlay-panel.component.html',
    standalone: true,
})
export class OverlayPanelComponent implements OnDestroy {
    open = model(false);
    defaultCloseEvents = model(true);
    connectedTo = model(undefined as FlexibleConnectedPositionStrategyOrigin | undefined);

    closeClick = new Subject<void>();

    private _openEffect = effect(
        () => {
            if (this.open()) {
                this._openDropdown();
            } else {
                this._destroyDropdown();
            }

            this._changeDetector.detectChanges();
        },
        { allowSignalWrites: true }
    );

    contentTemplateRef = viewChild(TemplateRef);
    overlayContentDirective = contentChild(OverlayContentDirective);

    @Output() closed = new EventEmitter<void>();

    private overlayRef?: OverlayRef;
    private dropdownClosingActionsSub = Subscription.EMPTY;

    constructor(
        private overlay: Overlay,
        private viewContainerRef: ViewContainerRef,
        private _cd: ChangeDetectorRef,
        private _destroyRef: DestroyRef,
        private _injector: Injector,
        private _changeDetector: ChangeDetectorRef
    ) {}

    public toggleDropdown(): void {
        this.open.set(!this.open());
    }

    private _openDropdown(): void {
        const c = this.connectedTo();

        if (!c) {
            return;
        }

        this.overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(c)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top',
                        offsetY: 4,
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top',
                        offsetY: 4,
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom',
                        offsetY: -4,
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom',
                        offsetY: -4,
                    },
                ]),
        });

        // this.overlayRef
        //     .outsidePointerEvents()
        //     .pipe(takeUntilDestroyed(this._destroyRef))
        //     .subscribe(s => {});

        const templateRef = this.overlayContentDirective() ? this.overlayContentDirective()?.template : this.contentTemplateRef();

        const templatePortal = new TemplatePortal(
            templateRef as TemplateRef<unknown>,
            this.viewContainerRef,
            null,
            Injector.create({ parent: this._injector, providers: [{ provide: OverlayPanelComponent, useValue: this }] })
        );

        this.overlayRef.hostElement.classList.add('esc-overlay-host');
        this.overlayRef.hostElement.classList.add('is-hidden');

        this.overlayRef.attach(templatePortal);

        setTimeout(() => {
            this.overlayRef?.hostElement.classList.remove('is-hidden');
        });

        this.dropdownClosingActionsSub = this.closingActions()
            .pipe(takeUntilDestroyed(this._destroyRef))
            ?.subscribe(() => {
                this.closeClick.next();
                if (this.defaultCloseEvents()) {
                    this.open.set(false);
                }
            });
    }

    public closingActions(): Observable<MouseEvent | void> {
        if (this.overlayRef) {
            const backdropClick$ = this.overlayRef.backdropClick();
            const detachment$ = this.overlayRef.detachments();

            return merge(backdropClick$, detachment$);
        }

        return of();
    }

    private _destroyDropdown(): void {
        if (!this.overlayRef) {
            return;
        }

        this.overlayRef.hostElement.classList.add('is-hidden');

        this.dropdownClosingActionsSub.unsubscribe();

        setTimeout(() => {
            this.overlayRef?.detach();
        }, 200);

        this._cd.detectChanges();
        this.closed.emit();
    }

    ngOnDestroy(): void {
        if (this.overlayRef) {
            this.overlayRef.dispose();
        }
    }
}
