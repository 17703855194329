import { Component, ContentChild, Directive, ElementRef, forwardRef, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { CdkDrag, CdkDragDrop, CdkDragExit, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subject, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from '../../../common/directives/tooltip.directive';

interface DragDropItemTemplateContext<T> {
    $implicit: T;
}

@Directive({
    selector: '[escDragCursor]',
    standalone: true,
})
export class DragCursorDirective implements OnInit, OnDestroy {
    private unsubscribe$: Subject<void> = new Subject();

    constructor(private cdkDrag: CdkDrag) {}

    public ngOnInit(): void {
        this.cdkDrag.started.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            window.document.body.classList.add('is-dragging');
        });

        this.cdkDrag.ended.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
            window.document.body.classList.remove('is-dragging');
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

@Directive({ selector: '[escDragDropItem]', standalone: true })
export class DragDropItemDirective<T> {
    @Input('escDragDropItemType') typeCheck!: T;

    constructor(
        public template: TemplateRef<{ $implicit: T }>,
        public element: ElementRef
    ) {}

    static ngTemplateContextGuard<T>(dir: DragDropItemTemplateContext<T>, ctx: unknown): ctx is DragDropItemTemplateContext<T> {
        return true;
    }
}

@Component({
    selector: 'esc-drag-drop-list-input',
    templateUrl: './drag-drop-list-input.component.html',
    styleUrls: ['./drag-drop-list-input.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, DragDropModule, TooltipDirective, DragCursorDirective],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DragDropListInputComponent),
            multi: true,
        },
    ],
})
export class DragDropListInputComponent<T extends { id: number }> implements ControlValueAccessor {
    @Input({ required: true }) public label!: string;
    @Input({ required: true }) public dragDropId!: string;
    @Input() public connectedTo: string[] = [];
    @Input() public disabledFunc: (item: T) => boolean = () => false;
    @Input() public tooltipFunc: (item: T) => string = () => '';
    public value: T[] = [];

    private _propagateChange: (t: T[]) => unknown = () => {};
    @ContentChild(DragDropItemDirective, { static: false }) public templateItem!: DragDropItemDirective<T>;

    constructor() {}

    public drop(event: CdkDragDrop<T[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }

        this._propagateChange(this.value);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public exited(_event: CdkDragExit<T[]>): void {
        this._propagateChange(this.value);
    }

    public remove(item: T) {
        const items = this.value ? [...this.value].filter(r => r.id !== item.id) : [];
        this.writeValue(items);
    }

    public writeValue(value: T[]): void {
        this.value = value;
        this._propagateChange(value);
    }

    public registerOnChange(fn: () => unknown): void {
        this._propagateChange = fn;
    }

    public registerOnTouched(): void {
        // throw new Error('Method not implemented.');
    }

    public setDisabledState(): void {
        // this.isDisabled = isDisabled;
        // throw new Error('Method not implemented.');
    }
}
