import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IRoleFranchise, IUserRole } from '../models/user.interface';
import { ApiClientService } from '../services/api.service';
import { IProfileDTO, IProfile, IProfileRole } from '../models/profile.interface';
import { IResults } from '../models/results.interface';
import { ILoginResponse } from '../models/login-response.interface';
import { UsersService } from 'apps/early-stage-office/src/app/core/services/users.service';

export type TLoginDTO = {
    email: string;
    password: string;
};

export type TPasswordResetDTO = {
    email: string;
};

export type TPasswordChangeDTO = {
    oldPassword?: string;
    token?: string;
    password: string;
    passwordConfirmation: string;
};

export type TPhoneChangeDTO = {
    phone: string;
};

@Injectable({
    providedIn: 'root',
})
export class ProfileService {
    public static isRoleActive(role: IUserRole): boolean {
        if (!role) {
            return false;
        }

        switch (role.role) {
            case 'ROLE_ADMIN':
            case 'ROLE_SUPER_ADMIN':
            case 'ROLE_CENTRAL_ADMINISTRATION':
                return true;
            case 'ROLE_LECTOR':
                if (role.roleFranchises?.some(rf => rf.status)) {
                    return true;
                }
                break;
            case 'ROLE_DOS':
            case 'ROLE_PAYMENTS':
            case 'ROLE_FRANCHISEE':
            case 'ROLE_LEADER':
            case 'ROLE_SECRETARIAT':
                if (role.roleFranchises && role.roleFranchises.length > 0) {
                    return true;
                }
                break;
        }
    }

    constructor(
        private api: ApiClientService,
        private users: UsersService
    ) {}

    public resetPassword(data: TPasswordResetDTO): Observable<void> {
        return this.api.post('auth/request-new-password', data);
    }

    public setPassword(data: TPasswordChangeDTO): Observable<ILoginResponse> {
        return this.api.post<IResults<ILoginResponse>>('auth/first-password', data).pipe(
            map(response => {
                return {
                    ...response.results,
                };
            })
        );
    }

    public changePassword(data: TPasswordChangeDTO): Observable<void> {
        return this.api.post(data.token ? 'auth/reset-password' : 'profile/password', data);
    }

    public changePhone(data: TPhoneChangeDTO): Observable<void> {
        return this.api.post('profile/phone', data);
    }

    public getProfile(): Observable<IProfile> {
        return this.api.get<IResults<IProfileDTO>>('profile').pipe(map(response => this.parseProfile(response.results)));
    }

    public parseProfile(profileDto: IProfileDTO): IProfile {
        const userDto = profileDto.user;
        const profile: IProfile = {
            user: this.users.parse(userDto),
            roles: userDto.roles,
            profileRoles: [],
            hasMultipleFranchises: this.hasMultipleFranchises(profileDto),
        };

        for (const role of profile.roles) {
            profile.profileRoles.push(...this.getProfileRolesFromRole(role));
        }

        return profile;
    }

    protected getRoleRoute(role: IUserRole, franchise?: IRoleFranchise): string[] {
        switch (role.role) {
            case 'ROLE_SUPER_ADMIN':
            case 'ROLE_CENTRAL_ADMINISTRATION':
                return ['/superadmin'];
            case 'ROLE_FRANCHISEE':
            case 'ROLE_LEADER':
            case 'ROLE_SECRETARIAT':
                if (franchise) {
                    return ['/franczyza/' + franchise?.franchise?.id + '/'];
                } else {
                    return undefined;
                }
            case 'ROLE_PAYMENTS':
                if (franchise) {
                    return ['/platnosci/' + franchise?.franchise?.id + '/'];
                } else {
                    return undefined;
                }
            case 'ROLE_DOS':
                return ['/metodyk'];
            case 'ROLE_LECTOR':
                return ['/lektor'];
        }
    }

    protected getProfileRolesFromRole(role: IUserRole): IProfileRole[] {
        switch (role.role) {
            case 'ROLE_SUPER_ADMIN':
            case 'ROLE_CENTRAL_ADMINISTRATION':
            case 'ROLE_DOS':
            case 'ROLE_LECTOR':
                break;
            case 'ROLE_FRANCHISEE':
            case 'ROLE_LEADER':
            case 'ROLE_SECRETARIAT':
            case 'ROLE_PAYMENTS':
                if (!role.roleFranchises) {
                    break;
                }

                const roles: IProfileRole[] = [];

                for (const f of role.roleFranchises) {
                    roles.push({
                        ...role,
                        route: this.getRoleRoute(role, f),
                        franchise: f,
                    });
                }

                return roles;
        }

        return [
            {
                ...role,
                route: this.getRoleRoute(role),
            },
        ];
    }

    protected hasMultipleFranchises(dto: IProfileDTO): boolean {
        const franchises: number[] = [];

        for (const role of dto.user.roles) {
            switch (role.role) {
                case 'ROLE_SUPER_ADMIN':
                case 'ROLE_CENTRAL_ADMINISTRATION':
                case 'ROLE_DOS':
                case 'ROLE_LECTOR':
                    break;
                case 'ROLE_FRANCHISEE':
                case 'ROLE_LEADER':
                case 'ROLE_SECRETARIAT':
                case 'ROLE_PAYMENTS':
                    if (!role.roleFranchises) {
                        break;
                    }

                    for (const f of role.roleFranchises) {
                        if (!franchises.includes(f?.franchise?.id)) {
                            franchises.push(f?.franchise?.id);
                        }
                    }
            }
        }

        return franchises.length > 1;
    }
}
