import { Component, ElementRef, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'esc-dropdown-select-all',
    templateUrl: './dropdown-select-all.component.html',
    styleUrls: ['./dropdown-select-all.component.scss'],
    standalone: true,
    imports: [CommonModule],
    host: {
        class: 'esc-dropdown-option',
        '(click)': '_clicked.next($event)',
        '(keydown)': '_focused.next($event)',
        role: 'option',
        '[attr.aria-selected]': 'isSelected()',
        '[attr.tabindex]': '-1',
        //        '[attr.aria-disabled]': 'disabled',
        //        '[class.cdk-option-active]': 'isActive()',
        //        '(focus)': '_handleFocus()',
    },
})
export class DropdownSelectAllComponent<T> {
    readonly _clicked = new Subject<MouseEvent>();
    readonly _focused = new Subject<KeyboardEvent>();
    protected readonly dropdown: DropdownComponent<T> = inject(DropdownComponent);

    public readonly element: HTMLElement = inject(ElementRef).nativeElement;
    public isSelected(): boolean {
        return this.dropdown.isSelectedAll();
    }
}
