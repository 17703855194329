import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'esc-form-field-sub-title',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './form-field-sub-title.component.html',
    host: {
        class: 'esc-form-field-sub-title',
    },
})
export class FormFieldSubTitleComponent {}
