import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * @deprecated Use destroyRef instead
 */
@Component({
    template: '',
})
export abstract class WithDestroyComponent implements OnDestroy {
    protected destroy$: Subject<boolean> = new Subject<boolean>();

    public ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
