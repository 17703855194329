import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Injectable()
export class NotificationRef {
    private readonly _afterClosing = new Subject<any>();
    public afterClosing: Observable<any> = this._afterClosing.asObservable();

    public close(result?: any) {
        this._afterClosing.next(result);
    }
}
