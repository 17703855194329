<div class="dookies-info">
    <div class="container">
        <div class="dookies-info__container">
            <div class="dookies-info__box">
                <p class="dookies-info__title">Pliki cookies</p>
                <p class="dookies-info__text" data-scroll-lock-scrollable>
                    Nasza strona internetowa używa plików cookies. Za ich pomocą zbieramy informacje, które mogą stanowić dane osobowe. Wykorzystujemy je w
                    celach personalizacyjnych, funkcjonalnych, analitycznych, bezpieczeństwa i reklamowych oraz aby utrzymać Twoją sesję po zalogowaniu do
                    konta. Klikając w „Akceptuję wszystkie” wyrażasz zgodę na przetwarzanie danych osobowych w pełnym zakresie. Możesz wybrać swoje ustawienia
                    dotyczące plików cookies, w tym odrzucić wszystkie inne niż niezbędne pliki cookies (konieczne do korzystania ze strony internetowej, które
                    jednak nie powodują przetwarzania Twoich danych osobowych), klikając w „Ustawienia preferencji”. Pamiętaj, że w każdej chwili, możesz
                    zmienić swoje ustawienia dotyczące plików cookies. Szczegółowe informacje, w tym dotyczące zakresu przetwarzania Twoich danych osobowych
                    znajdziesz w naszej Polityce prywatności.
                </p>
            </div>

            <div class="dookies-info__buttons">
                <button class="dookies-info__button esc-button is-primary" type="button" (click)="clickAcceptAll()">Akceptuję wszystkie</button>
                <button class="dookies-info__button esc-button is-secondary" type="button" (click)="clickOpenCookiesSettingsModal()">
                    Ustawienia preferencji
                </button>
                <button class="dookies-info__button link is-underline" type="button" (click)="clickRejectAll()">Odrzuć wszystkie</button>
            </div>
        </div>
    </div>

    <button class="dookies-info__close esc-button is-shadowed" (click)="modalRef.close()" [attr.aria-label]="'Zamknij'">
        <esc-icon icon="close_small" large />
    </button>
</div>
