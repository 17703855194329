import { trigger, animate, transition, style, query, group, animateChild } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('.2s', style({ opacity: 1 }))]),
    transition(':leave', [animate('.2s', style({ opacity: 0 }))]),
]);

export const collapseAnimation = trigger('collapseAnimation', [
    transition(':enter', [style({ height: 0 }), animate('.2s', style({ height: '*' }))]),
    transition(':leave', [animate('.2s', style({ height: 0 }))]),
]);

export const blockInitialRenderAnimation = trigger('blockInitialRenderAnimation', [transition(':enter', [])]);

export const fromLeftAnimation = trigger('fromLeftAnimation', [
    transition(':enter', [style({ transform: 'translateX(-500px)' }), animate('.2s', style({ transform: 'translateX(0)' }))]),
    transition(':leave', [animate('.2s', style({ transform: 'translateX(-500px)' }))]),
]);

export const fromRightAnimation = trigger('fromRightAnimation', [
    transition(':enter', [style({ transform: 'translateX(500px)' }), animate('.2s', style({ transform: 'translateX(0)' }))]),
    transition(':leave', [animate('.2s', style({ transform: 'translateX(500px)' }))]),
]);

export const fromTopAnimation = trigger('fromTopAnimation', [
    transition(':enter', [style({ transform: 'translateY(-100%)', opacity: '0' }), animate('.2s', style({ transform: 'translateY(0)', opacity: '1' }))]),
    transition(':leave', [animate('.2s', style({ transform: 'translateY(-100%)', opacity: '0' }))]),
]);

export const fromBottomAnimation = trigger('fromBottomAnimation', [
    transition(':enter', [style({ transform: 'translateY(100%)' }), animate('.2s', style({ transform: 'translateY(0)' }))]),
    transition(':leave', [animate('.2s', style({ transform: 'translateY(100%)' }))]),
]);

export const headerSideAnimation = trigger('headerSideAnimation', [
    transition(':enter', [style({ transform: 'translateX(100%)' }), animate('.2s', style({ transform: 'translateX(0)' }))]),
    transition(':leave', [animate('.2s', style({ transform: 'translateX(100%)' }))]),
]);

export const headerProfileAnimation = trigger('headerProfileAnimation', [
    transition(':enter', [style({ height: 0, overflow: 'hidden' }), animate('.2s', style({ height: '*' }))]),
    transition(':leave', [style({ overflow: 'hidden' }), animate('.2s', style({ height: 0 }))]),
]);

export const routerTransition = trigger('routerTransition', [
    transition('isLeft <=> *', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
        query(':enter, :leave', animateChild(), { optional: true }),
        group([
            // block executes in parallel
            query(
                ':enter',
                [
                    style({ transform: 'translateX(100%)', opacity: '0', zIndex: 9999 }),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(0%)', opacity: '1' })),
                ],
                { optional: true }
            ),
            query(
                ':leave',
                [
                    style({ transform: 'translateX(0%)', opacity: '1', zIndex: 9999 }),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(100%)', opacity: '0' })),
                    animateChild(),
                ],
                { optional: true }
            ),
        ]),
    ]),
    transition('* <=> isLeft', [
        query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
        query(':enter, :leave', animateChild(), { optional: true }),
        group([
            // block executes in parallel
            query(':enter', [style({ transform: 'translateX(100%)', zIndex: 9999 }), animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))], {
                optional: true,
            }),
            query(':leave', [style({ transform: 'translateX(0%)', zIndex: 9999 }), animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)' }))], {
                optional: true,
            }),
        ]),
    ]),
]);
