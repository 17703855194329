// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class NotificationConfig {
    public text: string;
    public duration?: number = 6000;
    public type?: 'success' | 'warning' | 'info' = 'success';
    public position?: 'top' | 'bottom' = 'top';

    constructor(config?: NotificationConfig) {
        if (!config) {
            return;
        }

        for (const key in config) {
            if (config.hasOwnProperty(key)) {
                this[key] = config[key];
            }
        }
    }
}
