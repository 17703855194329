import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'escFloor',
    standalone: true,
})
export class FloorPipe implements PipeTransform {
    /**
     *
     * @param value
     * @returns {number}
     */
    transform(value: number): number {
        return Math.floor(value);
    }
}
