import { booleanAttribute, Component, computed, input, InputSignal, InputSignalWithTransform, Signal, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { customIcons, TCustomIconName } from './svg-custom';
import { TMaterialSymbol } from './material-symbol.type';

export type TIconName = TMaterialSymbol | TCustomIconName;

@Component({
    selector: 'esc-icon',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './icon.component.html',
    styleUrl: './icon.component.scss',
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'esc-icon',
        '[class.is-filled]': 'fill()',
        '[class.is-icon-small]': 'small()',
        '[class.is-icon-large]': 'large()',
    },
})
export class IconComponent {
    iconType = input<'font' | 'svg'>('font');

    public icon: InputSignal<TIconName> = input.required();
    public fill: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute });
    public small: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute });
    public large: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute });

    public iconHtml: Signal<SafeHtml> = computed(() => {
        return this._sanitizer.bypassSecurityTrustHtml(customIcons[this.icon() as TCustomIconName]);
    });

    constructor(private _sanitizer: DomSanitizer) {}
}
