import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';

@Directive({
    selector: '[escTabDef]',
    standalone: true,
})
export class TabDirective implements OnInit {
    @Input({ required: true }) public tabId!: string;
    @Input({ required: true }) public label!: string;
    @Input() public supLabel?: string;
    @Input() public supDisabled: boolean = false;
    @Input() public escHidden: boolean = false;
    @Input() public escDisabled: boolean = false;
    @Input() public disabledTooltip?: string;

    private _contentPortal!: TemplatePortal;

    get content(): TemplatePortal {
        return this._contentPortal;
    }

    constructor(
        public template: TemplateRef<unknown>,
        public viewContainer: ViewContainerRef
    ) {}

    public ngOnInit(): void {
        this._contentPortal = new TemplatePortal(this.template, this.viewContainer);
    }
}
