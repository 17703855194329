import { CdkCellDef } from '@angular/cdk/table';
import { Directive, Input, TemplateRef } from '@angular/core';
import { IRowContext } from '../components/table/table.component';
import { EscDataSource } from '../../data-and-collections/classes/data-source/data-source';

@Directive({
    selector: '[escCellDef]',
    providers: [{ provide: CdkCellDef, useExisting: CellDefDirective }],
    standalone: true,
})
export class CellDefDirective<T> extends CdkCellDef {
    @Input() escCellDefDataSource!: EscDataSource<T>;

    constructor(public override template: TemplateRef<IRowContext<T>>) {
        super(template);
    }

    // ngTemplateContextGuard flag to help with the Language Service
    static ngTemplateContextGuard<T>(dir: CellDefDirective<T>, ctx: unknown): ctx is { $implicit: T; index: number } {
        return true;
    }
}
