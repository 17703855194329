<div class="esc-table-filter-group is-{{ type }}">
    <button
        class="esc-table-filter-group-button is-{{ type }}"
        type="button"
        *ngFor="let item of filterItems"
        (click)="onButtonClick(item)"
        [ngClass]="{ 'is-active': item.enabled }">
        <span class="esc-table-filter-group-button__title" [innerHTML]="item.title"> </span>
        <span class="esc-table-filter-group-button__counter">({{ item.counter }})</span>
    </button>
</div>
