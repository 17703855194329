import { Component, DestroyRef, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IFilterGroup, IFilterGroupItem } from '../../classes/data-source/data-source-filter';
import { EscDataSource } from '../../classes/data-source/data-source';
import { SelectComponent, SelectPrintValueDirective } from '../../../forms/components/select/select.component';
import { OptionComponent } from '../../../forms/components/select/option/option.component';

@Component({
    selector: 'esc-data-source-filter-select',
    standalone: true,
    imports: [CommonModule, SelectComponent, OptionComponent, SelectPrintValueDirective],
    templateUrl: './data-source-filter-select.component.html',
    styleUrl: './data-source-filter-select.component.scss',
})
export class DataSourceFilterSelectComponent<T> {
    //
    private _destroyRef = inject(DestroyRef);

    dataSource = input.required<EscDataSource<T>>();
    filterId = input.required<string>();

    protected filterItems!: IFilterGroupItem<T>[];
    protected filterGroup!: IFilterGroup<T>;

    selectedItems: string[] = [];

    public ngOnInit(): void {
        this.dataSource()
            .connect()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.filterGroup = this.dataSource().getFilterGroup(this.filterId());
                this.filterItems = this.dataSource().getFilterGroupItems(this.filterId());
            });
    }

    onValueChange(event: { value: IFilterGroupItem<T>[] }) {
        const items = event.value?.map(v => v.id) || [];
        const itemsToUncheck = this.selectedItems.filter(i => items.indexOf(i) === -1);

        for (const item of itemsToUncheck) {
            this.dataSource().toggleFilter(this.filterId(), item, false);
        }

        for (const item of items) {
            this.dataSource().toggleFilter(this.filterId(), item, true);
        }

        this.selectedItems = items;
    }
}
