import { Directive, inject, input } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { EscToastsService } from '../components/toasts-container/toasts.service';

@Directive({
    selector: '[escCopyToClipboard]',
    standalone: true,
    host: {
        '(click)': 'copy(escCopyToClipboard())',
    },
})
export class CopyToClipboardDirective {
    private _clipboard = inject(Clipboard);
    private _toasts = inject(EscToastsService);

    escCopyToClipboard = input('');
    confirmText = input('Treść została skopiowana');

    public copy(text: string): void {
        this._clipboard.copy(text);

        setTimeout(() => {
            this._toasts.add({
                type: 'success',
                text: this.confirmText(),
            });
        }, 100);
    }
}
