import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { fromTopAnimation } from 'apps/early-stage-office/src/app/core/animations';
import { NotificationConfig } from '../../services/notification-config';
import { NotificationRef } from '../../services/notification-ref';
import { NgIf } from '@angular/common';
import { IconComponent } from '@early-stage/components';

@Component({
    selector: 'es-notification-container',
    templateUrl: './notification-container.component.html',
    styleUrls: ['./notification-container.component.scss'],
    animations: [fromTopAnimation],
    standalone: true,
    imports: [NgIf, IconComponent],
})
export class NotificationContainerComponent implements OnInit, OnDestroy {
    public wasClosed: boolean = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public timeout: any;
    public closePromise: Promise<void>;

    private closeSubsription: Subscription;

    constructor(
        public config: NotificationConfig,
        public ref: NotificationRef
    ) {}

    public ngOnInit() {
        this.getCloseSubscription();
    }

    private getCloseSubscription(): void {
        const close$ = new Observable(observer => {
            setTimeout(() => observer.next(), this.config.duration);
            setTimeout(() => observer.next(), this.config.duration + 200);
        });

        this.closeSubsription = close$.subscribe(() => {
            if (!this.wasClosed) {
                this.wasClosed = true;
            } else {
                this.close();
            }
        });
    }

    private close(): void {
        this.ref.close();
    }

    public closeClick(): void {
        this.wasClosed = true;
    }

    public ngOnDestroy(): void {
        if (this.closeSubsription) {
            this.closeSubsription.unsubscribe();
        }
    }
}
