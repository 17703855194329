import { computed, DestroyRef, Directive, ElementRef, inject } from '@angular/core';
import { EscFormFieldControl, EscFormFieldControlNativeInput } from './form-field-control.directive';
import { NgControl } from '@angular/forms';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DisabledStateDirective } from './disabled-state.directive';
import { OwlDateTimeInputDirective } from '@danielmoncada/angular-datetime-picker';

@Directive({
    selector: `input[escDateInput]`,
    exportAs: 'escDateInput',
    host: {
        '[id]': 'id',
        '[attr.id]': 'id',
        '[attr.readonly]': 'true',
        '(keydown)': 'handleKeyDown($event)',
    },
    hostDirectives: [
        {
            directive: DisabledStateDirective,
            inputs: ['escDisabled'],
        },
    ],
    providers: [
        { provide: EscFormFieldControlNativeInput, useExisting: EscDateInputDirective },
        { provide: EscFormFieldControl, useExisting: EscDateInputDirective },
    ],
    standalone: true,
})
export class EscDateInputDirective extends EscFormFieldControlNativeInput {
    public readonly elementRef = inject(ElementRef<HTMLInputElement>, { optional: true, self: true });

    private _destroyRef = inject(DestroyRef);
    private _elementRef = inject(ElementRef);
    private datetimeInput = inject(OwlDateTimeInputDirective, { optional: true });

    public readonly ngControl = inject(NgControl, { optional: true, self: true });
    public readonly disabledState = inject(DisabledStateDirective, { optional: true, self: true });

    escDisabled = computed(() => {
        return this.disabledState?.escDisabled() || false;
    });

    constructor() {
        super();
    }

    public ngAfterViewInit() {
        this.datetimeInput?.dtPicker.afterPickerClosed.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(s => {
            setTimeout(() => {
                // this._focusChanged(true);
                this._elementRef.nativeElement.focus();
            });
        });
    }

    public onContainerClick(): void {
        this.datetimeInput?.dtPicker.open();
    }

    public handleKeyDown(event: KeyboardEvent): void {
        if (!this.datetimeInput?.dtPicker.opened) {
            switch (event.key) {
                case 'Enter':
                case 'ArrowUp':
                case 'ArrowDown':
                    this.datetimeInput?.dtPicker.open();
            }
        }
    }
}
