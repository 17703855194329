<label
    #label
    class="esc-radio-wrapper"
    [class.is-radio-hidden]="radioHidden"
    [ngClass]="radioClass"
    [class.is-checked]="checked"
    [class.is-disabled]="escDisabled"
    [class.is-border-type]="isBorderType()">
    <div class="esc-radio">
        <input
            #input
            class="radio"
            type="radio"
            [name]="name"
            [value]="value"
            [class.is-checked]="checked"
            [class.is-disabled]="escDisabled"
            [required]="required"
            [disabled]="escDisabled"
            (change)="_onInteractionEvent($event)" />
    </div>

    <div class="esc-radio-wrapper__content">
        <ng-content></ng-content>
    </div>
</label>
