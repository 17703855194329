import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Login, Logout, SetIsLoggedIn, SetLogin, SetToken } from './auth.actions';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from '../../authentication/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { GetProfile, ResetProfileState } from '../profile/profile.actions';
import { LoadRolesIfEmpty, LoadSchoolYears } from '../dictionary/dictionary.actions';
import { Navigate } from '@ngxs/router-plugin';
import { Injectable } from '@angular/core';
import { ResetLayoutState } from '../layout/layout.actions';
import { NotificationService } from 'apps/early-stage-office/src/app/utility-modules/notification/services/notification.service';

export interface IAuthStateModel {
    token: string;
    isLoggedIn: boolean;
}

@State<IAuthStateModel>({
    name: 'auth',
    defaults: {
        token: null,
        isLoggedIn: false,
    },
})
@Injectable()
export class AuthState {
    constructor(
        private auth: AuthenticationService,
        private notification: NotificationService
    ) {}

    @Selector()
    public static getState(state: IAuthStateModel) {
        return state;
    }

    @Selector()
    public static token(state: IAuthStateModel) {
        return state.token;
    }

    @Selector()
    public static loggedIn(state: IAuthStateModel) {
        return state.isLoggedIn;
    }

    @Action(Logout)
    public async logout({ dispatch, patchState }: StateContext<IAuthStateModel>, { payload }: Logout) {
        if (!payload || payload.sendLogout) {
            await this.auth.logout().toPromise();
        }

        dispatch(new ResetLayoutState());
        dispatch(new ResetProfileState());

        patchState({
            token: null,
            isLoggedIn: false,
        });

        if (payload && payload.notify) {
            this.notification.add({ text: payload.notify, type: 'warning' });
        }

        if (payload && payload.navigate) {
            dispatch(new Navigate(['/logowanie']));
        }

        return true;
    }

    @Action(Login)
    public login({ dispatch }: StateContext<IAuthStateModel>, { loginDTO }: Login) {
        return this.auth.login(loginDTO).pipe(
            tap(response => {
                dispatch(new SetLogin({ login: response }));
            }),
            catchError((error: HttpErrorResponse) => throwError(error))
        );
    }

    @Action(SetLogin)
    public async setLogin({ dispatch }: StateContext<IAuthStateModel>, { payload }: SetLogin) {
        const data = payload.login;

        dispatch(new SetToken({ token: data.apiToken }));
        dispatch(new SetIsLoggedIn({ isLoggedIn: true }));
        await dispatch(new LoadRolesIfEmpty()).toPromise();
        await dispatch(new LoadSchoolYears()).toPromise();
        await dispatch(new GetProfile()).toPromise();
    }

    @Action(SetToken)
    public setToken(ctx: StateContext<IAuthStateModel>, { payload }: SetToken) {
        ctx.patchState({
            token: payload.token,
        });
    }

    @Action(SetIsLoggedIn)
    public setIsLoggedIn(ctx: StateContext<IAuthStateModel>, { payload }: SetIsLoggedIn) {
        ctx.patchState({
            isLoggedIn: payload.isLoggedIn,
        });
    }
}
