import { CDK_ROW_TEMPLATE, CdkHeaderRow, CdkTableModule } from '@angular/cdk/table';
import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'esc-header-row',
    template: CDK_ROW_TEMPLATE,
    host: {
        class: 'esc-header-row esc-table__header-row',
        role: 'row',
    },
    // See note on CdkTable for explanation on why this uses the default change detection strategy.
    // tslint:disable-next-line:validate-decorators
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None,
    exportAs: 'escHeaderRow',
    providers: [{ provide: CdkHeaderRow, useExisting: HeaderRowComponent }],
    standalone: true,
    imports: [CdkTableModule],
})
export class HeaderRowComponent extends CdkHeaderRow {}
