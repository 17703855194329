import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CellDirective } from '../../directives/cell.directive';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from '../../../common/directives/tooltip.directive';

@Component({
    selector: 'esc-cell-button',
    templateUrl: './cell-button.component.html',
    styleUrls: ['./cell-button.component.scss'],
    host: {
        class: 'esc-cell esc-cell-button esc-table__cell esc-table__cell-button',
    },
    encapsulation: ViewEncapsulation.None,
    exportAs: 'escCellButton',
    providers: [{ provide: CellDirective, useExisting: CellButtonComponent }],
    standalone: true,
    imports: [CommonModule, RouterModule, TooltipDirective],
})
export class CellButtonComponent extends CellDirective {
    @Input() public isDisabled = false;
    @Input() public isReadonly = false;
    @Input() public iconOnly = false;
    @Input() public escCellDisabledTooltip?: string;
    @Input() public escCellTooltip?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() public escRouterLink?: string | any[];
    @Output() public escClick: EventEmitter<void> = new EventEmitter<void>();

    public onButtonClick(): void {
        if (!this.isDisabled) {
            this.escClick.emit();
        }
    }
}
