import { Directive } from '@angular/core';
import { CdkCell } from '@angular/cdk/table';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'esc-cell',
    host: {
        class: 'esc-cell esc-content-cell esc-table__cell',
    },
    standalone: true,
})
export class CellDirective extends CdkCell {}
