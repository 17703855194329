import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastComponent } from './toast/toast.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { EscToastsStateService } from './toasts-state.service';

export const fadeAnimation = trigger('fadeAnimation', [
    transition(':enter', [style({ height: 0, transform: 'translateY(0)' }), animate('300ms ease-in-out', style({ height: '*', transform: 'translateY(0)' }))]),
    transition(':leave', [
        style({ opacity: 1, height: '*' }),
        animate('300ms ease-in-out', style({ opacity: 0 })),
        animate('200ms ease-in-out', style({ height: 0 })),
    ]),
]);

@Component({
    selector: 'esc-toasts-container',
    standalone: true,
    imports: [CommonModule, ToastComponent],
    templateUrl: './toasts-container.component.html',
    styleUrl: './toasts-container.component.scss',
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'esc-toasts-container',
    },
    animations: [fadeAnimation],
})
export class ToastsContainerComponent {
    constructor(public toasts: EscToastsStateService) {}
}
