<form>
    <div escModalHeader>
        <ng-content select="esc-form-in-modal-header"></ng-content>
    </div>
    <div escModalContent>
        <div class="esc-modal__loading" [@fadeAnimation] *ngIf="formActions.formState() === 'loading'">
            <span class="loader-icon is-large" aria-label="Loading" [@fadeAnimation]></span>
            @if (loaderText()) {
                <p>{{ loaderText() }}</p>
            }
        </div>

        <ng-content select="esc-form-in-modal-content"></ng-content>

        <!--            <es-form-error [errorObservable]="singleErrorObservable"></es-form-error>-->
    </div>
    <div escModalFooter>
        <div class="flex is-flex-space-between">
            <div>
                <button type="button" class="link is-underline" (click)="close()">Anuluj</button>
            </div>
            <div class="form-buttons">
                <ng-content select="[additional-buttons]"></ng-content>
                <div #submitContainer class="relative" [escTooltip]="disabledTooltip()" [escTooltipEnabled]="disabled()">
                    <button type="submit" class="esc-button" [ngClass]="{ 'is-disabled': buttonDisabled() }" [disabled]="buttonDisabled()">
                        {{ submitText() }}
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="esc-modal__overlay" *ngIf="formActions.formState() === 'loading'" [@fadeAnimation]></div>
</form>
