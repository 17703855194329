import { booleanAttribute, Component, input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

const fadeAnimation = trigger('fadeAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('.2s', style({ opacity: 1 }))]),
    transition(':leave', [animate('.2s', style({ opacity: 0 }))]),
]);

@Component({
    selector: 'esc-card',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './card.component.html',
    styleUrl: './card.component.scss',
    animations: [fadeAnimation],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: 'esc-card',
    },
})
export class CardComponent {
    loading = input(false, { transform: booleanAttribute });
}
