import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiClientService } from '../services/api.service';
import { ILoginResponse } from '../models/login-response.interface';
import { map } from 'rxjs/operators';
import { IResults } from '../models/results.interface';

export type TLoginDTO = {
    email: string;
    password: string;
};

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(private api: ApiClientService) {}

    public login(data: TLoginDTO): Observable<ILoginResponse> {
        return this.api.post<IResults<ILoginResponse>>('auth/login', data).pipe(
            map(response => {
                return {
                    ...response.results,
                };
            })
        );
    }

    public logout(): Observable<void> {
        return this.api.get<void>('profile/logout').pipe(
            map(() => {
                return undefined;
            })
        );
    }

    public isTokenValid(payload: { apiToken: string }): Observable<boolean> {
        return this.api.post<IResults<boolean>>('auth/check-api-token', payload).pipe(map(data => data.results));
    }
}
