import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

export type TValidationMessages = {
    required: string;
    minlength: string;
    min: string;
    [key: string]: string;
};

export type TErrorMessage = {
    key: string;
    text: string;
};

@Injectable({
    providedIn: 'root',
})
export class ValidationMessagesService {
    private messages: TValidationMessages = {
        required: 'To pole jest wymagane',
        minlength: 'To pole wymaga minimalnie %length% znaków',
        maxlength: 'To pole wymaga maksymalnie %length% znaków',
        min: 'Minimalna wartość to %length%',
        max: 'Maksymalna wartość to %length%',
        email: 'Podaj prawidłowy adres e-mail',
        pattern: 'Podaj wartość we właściwym formacie',
    };

    public parseMessage(errors: ValidationErrors | null | undefined, customMessages: Partial<TValidationMessages> = {}): TErrorMessage[] {
        if (!errors) {
            return [];
        }

        const errorMessages: TErrorMessage[] = [];
        const messages = Object.assign({}, this.messages, customMessages);

        for (const key in errors) {
            const error = errors[key];
            let message = messages[key];

            switch (key) {
                case 'minlength':
                case 'maxlength':
                    message = message.replace(/%length%/g, error.requiredLength);
                    break;
                case 'min':
                    message = message.replace(/%length%/g, error.min);
                    break;
                case 'max':
                    message = message.replace(/%length%/g, error.max);
                    break;
            }

            errorMessages.push({ key, text: message || error });
        }

        return errorMessages;
    }
}
