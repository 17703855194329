import { Component, computed, inject, model, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor } from '@angular/forms';
import { DisabledStateDirective } from '../../directives/disabled-state.directive';
import { EscFormFieldControl, EscFormFieldControlWithValueAccessor } from '../../directives/form-field-control.directive';

@Component({
    selector: 'esc-switch',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './switch.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrl: './switch.component.scss',
    providers: [{ provide: EscFormFieldControl, useExisting: SwitchComponent }],
    host: {
        class: 'esc-switch',
        '[id]': 'id',
        '[attr.id]': 'id',
        '[attr.aria-checked]': 'value()',
        '(keydown)': '_handleKeyDown($event)',
        '(click)': '_handleClick()',
    },
    hostDirectives: [
        {
            directive: DisabledStateDirective,
            inputs: ['escDisabled'],
        },
    ],
})
export class SwitchComponent extends EscFormFieldControlWithValueAccessor<boolean> implements ControlValueAccessor {
    public readonly disabledState = inject(DisabledStateDirective, { optional: true, self: true });
    escDisabled = computed(() => {
        return this.disabledState?.escDisabled() || false;
    });
    //
    value = model(false);

    constructor() {
        super();
    }

    writeValue(v: boolean): void {
        this.value.set(v);
    }

    _handleKeyDown(event: KeyboardEvent) {
        if (this.disabledState?.escDisabled()) {
            return;
        }

        if (event.key === 'Enter') {
            this._handleClick();
        }
    }

    _handleClick() {
        if (this.disabledState?.escDisabled()) {
            return;
        }

        this.value.set(!this.value());
    }
}
