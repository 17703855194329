import { AfterViewInit, Directive, ElementRef, Inject, Input, NgZone, OnDestroy, Optional, ViewContainerRef } from '@angular/core';
import {
    ConnectedPosition,
    ConnectionPositionPair,
    FlexibleConnectedPositionStrategy,
    HorizontalConnectionPos,
    OriginConnectionPosition,
    Overlay,
    OverlayConnectionPosition,
    OverlayRef,
    ScrollDispatcher,
    VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { TooltipComponent } from '../components/tooltip/tooltip.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { ITooltipDefaultOptions, LONG_PRESS_DELAY, TOOLTIP_DEFAULT_OPTIONS, TTooltipPosition, UNBOUNDED_ANCHOR_GAP } from '../classes/tooltip-options';
import { from, fromEvent, mergeMap, Subject, take, takeUntil } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { ESCAPE } from '@angular/cdk/keycodes';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
    selector: '[escTooltip]',
    standalone: true,
})
export class TooltipDirective implements OnDestroy, AfterViewInit {
    private _overlayRef: OverlayRef | undefined;
    private _tooltipInstance?: TooltipComponent;
    private _portal: ComponentPortal<TooltipComponent> | undefined;
    private _position?: TTooltipPosition;
    private _currentPosition?: TTooltipPosition;
    private _disabled = false;
    private _disabledFunc = () => false;
    private _tooltipClass?: string | string[] | Set<string> | { [key: string]: unknown };
    private _showDelay = 0;
    private _hideDelay = 0;
    private _document?: Document;
    private _touchstartTimeout?: ReturnType<typeof setTimeout>;
    private readonly _destroyed = new Subject<void>();
    private _viewInitialized = false;
    private _eventsInitialized = false;

    @Input('escTooltip')
    public get message(): string | undefined | null {
        return this._message;
    }

    public set message(value: string | undefined | null) {
        if (this._message === value) {
            return;
        }

        this._message = value != null ? String(value).trim() : '';

        if (!this._message && this._isTooltipVisible()) {
            this.hide(0);
        } else {
            this._updateTooltipMessage();

            if (this._viewInitialized) {
                this._setupEventsIfNeeded();
            }
        }
    }

    private _width: number | null = null;

    @Input('escTooltipWidth')
    public get width(): number | null {
        return this._width;
    }

    public set width(value: number | null) {
        if (this._width === value) {
            return;
        }

        this._width = value;

        if (this._tooltipInstance) {
            this._tooltipInstance.width = value;
            this._tooltipInstance.markForCheck();
        }
    }

    private _message = '';

    @Input('escTooltipPosition')
    public get position(): TTooltipPosition | undefined {
        return this._position;
    }

    public set position(v: TTooltipPosition) {
        if (v !== this._position) {
            this._position = v;
            //
        }
    }

    @Input('escTooltipEnabled')
    public set enabled(value: boolean) {
        this.disabled = !value;
    }

    @Input('escTooltipDisabled')
    public get disabled(): boolean {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;

        if (this._disabled) {
            this.hide(0);
        } else {
            this._setupEventsIfNeeded();
        }
    }

    @Input('escTooltipDisabledFunc')
    public get disabledFunc(): () => boolean {
        return this._disabledFunc;
    }

    public set disabledFunc(value: () => boolean) {
        this._disabledFunc = value;

        if (this._disabledFunc()) {
            this.hide(0);
        } else {
            this._setupEventsIfNeeded();
        }
    }

    @Input('escTooltipClass')
    get tooltipClass(): string | string[] | Set<string> | { [key: string]: unknown } | undefined {
        return this._tooltipClass;
    }

    set tooltipClass(value: string | string[] | Set<string> | { [key: string]: unknown }) {
        this._tooltipClass = value;
        if (this._tooltipInstance && this._tooltipClass) {
            this._setTooltipClass(this._tooltipClass);
        }
    }

    @Input('escTooltipShowDelay')
    public get showDelay(): number {
        return this._showDelay;
    }

    public set showDelay(value: number) {
        this._showDelay = value;
    }

    @Input('escTooltipHideDelay')
    public get hideDelay(): number {
        return this._hideDelay;
    }

    public set hideDelay(value: number) {
        this._hideDelay = value;

        if (this._tooltipInstance) {
            // this._tooltipInstance._mouseLeaveHideDelay = this._hideDelay;
        }
    }

    constructor(
        private _elementRef: ElementRef<HTMLElement>,
        private _overlay: Overlay,
        private _platform: Platform,
        @Optional() @Inject(TOOLTIP_DEFAULT_OPTIONS) private _defaultOptions: ITooltipDefaultOptions,
        private _scrollDispatcher: ScrollDispatcher,
        private _viewContainerRef: ViewContainerRef,
        private sanitizer: DomSanitizer,
        private _ngZone: NgZone
    ) {
        if (_defaultOptions) {
            this._showDelay = _defaultOptions.showDelay;
            this._hideDelay = _defaultOptions.hideDelay;

            if (_defaultOptions.position) {
                this.position = _defaultOptions.position;
            }
        }
    }

    public ngAfterViewInit(): void {
        this._viewInitialized = true;
        this._setupEventsIfNeeded();
    }

    public ngOnDestroy() {
        clearTimeout(this._touchstartTimeout);

        if (this._overlayRef) {
            this._overlayRef.dispose();
            this._tooltipInstance = undefined;
        }

        this._destroyed.next();
        this._destroyed.complete();
    }

    public show(): void {
        if (this.disabled || this.disabledFunc() || !this.message || this._isTooltipVisible()) {
            return;
        }

        const overlayRef = this._createOverlay();
        this._detach();
        this._portal = this._portal || new ComponentPortal(TooltipComponent, this._viewContainerRef);
        const instance = (this._tooltipInstance = overlayRef.attach(this._portal).instance);
        instance._triggerElement = this._elementRef.nativeElement;
        instance._mouseLeaveHideDelay = this._hideDelay;
        instance.width = this._width;

        instance
            .afterHidden()
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => this._detach());

        this._updateTooltipMessage();
        if (this._tooltipClass) {
            this._setTooltipClass(this._tooltipClass);
        }
        instance.show(this._showDelay);
    }

    /** Hides the tooltip after the delay in ms, defaults to tooltip-delay-hide or 0ms if no input */
    hide(delay: number = this.hideDelay): void {
        const instance = this._tooltipInstance;

        if (instance) {
            if (instance.isVisible()) {
                instance.hide(delay);
            } else {
                instance.cancelPendingAnimations();
                this._detach();
            }
        }
    }

    /** Shows/hides the tooltip */
    // toggle(): void {
    //     this._isTooltipVisible() ? this.hide() : this.show();
    // }

    private _setupEventsIfNeeded() {
        if (this._eventsInitialized || !this._message || this._disabled || this._disabledFunc()) {
            return;
        }

        this._eventsInitialized = true;

        if (this._platformSupportsMouseEvents()) {
            fromEvent(this._elementRef.nativeElement, 'mouseenter')
                .pipe(takeUntil(this._destroyed))
                .subscribe(() => {
                    this.show();
                });

            fromEvent(this._elementRef.nativeElement, 'mouseleave')
                .pipe(takeUntil(this._destroyed))
                .subscribe(() => {
                    // this.hide();
                    // const newTarget = (e as MouseEvent).relatedTarget as Node | null;
                    // if (!newTarget || !this._overlayRef?.overlayElement.contains(newTarget)) {
                    this.hide();
                    // }
                });

            // fromEvent(this._elementRef.nativeElement, 'wheel').pipe(takeUntil(this._destroyed)).subscribe((e) => {
            //     const instance = this._tooltipInstance;
            //     if (instance) {
            //         if (instance.isVisible()) {
            //             instance.hide(this.hideDelay);
            //         } else {
            //             instance.cancelPendingAnimations();
            //             this._detach();
            //         }
            //     }
            // });
        } else {
            // this._disableNativeGesturesIfNecessary();

            fromEvent(this._elementRef.nativeElement, 'touchstart')
                .pipe(takeUntil(this._destroyed))
                .subscribe(() => {
                    clearTimeout(this._touchstartTimeout);
                    this._touchstartTimeout = setTimeout(() => this.show(), LONG_PRESS_DELAY);
                });
            //
            // fromEvent(this._elementRef.nativeElement, 'touchmove').pipe(takeUntil(this._destroyed)).subscribe((e) => {
            //     clearTimeout(this._touchstartTimeout);
            //     this.hide(0);
            // });

            from(['touchend', 'touchcancel'])
                .pipe(
                    mergeMap(event => fromEvent(this._elementRef.nativeElement, event)),
                    takeUntil(this._destroyed)
                )
                .subscribe(() => {
                    clearTimeout(this._touchstartTimeout);
                    this.hide(this._defaultOptions.touchendHideDelay);
                });
        }
    }

    private _platformSupportsMouseEvents() {
        return !this._platform.IOS && !this._platform.ANDROID;
    }

    private _isTooltipVisible(): boolean {
        return !!this._tooltipInstance; // && this._tooltipInstance.isVisible();
    }

    private _createOverlay(): OverlayRef {
        if (this._overlayRef) {
            const existingStrategy = this._overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;

            if (!origin && existingStrategy._origin instanceof ElementRef) {
                return this._overlayRef;
            }

            this._detach();
        }

        const scrollableAncestors = this._scrollDispatcher.getAncestorScrollContainers(this._elementRef);

        // Create connected position strategy that listens for scroll events to reposition.
        const strategy = this._overlay
            .position()
            .flexibleConnectedTo(this._elementRef)
            .withTransformOriginOn(`.esc-tooltip`)
            .withFlexibleDimensions(false)
            .withScrollableContainers(scrollableAncestors);

        strategy.positionChanges.pipe(takeUntil(this._destroyed)).subscribe(change => {
            this._updateCurrentPositionClass(change.connectionPair);

            if (this._tooltipInstance) {
                if (change.scrollableViewProperties.isOverlayClipped && this._tooltipInstance.isVisible()) {
                    this._ngZone.run(() => this.hide(0));
                }
            }
        });

        this._overlayRef = this._overlay.create({
            direction: 'ltr',
            positionStrategy: strategy,
            panelClass: `esc-tooltip-panel`,
            scrollStrategy: this._overlay.scrollStrategies.reposition(),
        });

        this._updatePosition(this._overlayRef);

        this._overlayRef
            .detachments()
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => this._detach());

        this._overlayRef
            .outsidePointerEvents()
            .pipe(takeUntil(this._destroyed))
            .subscribe(() => this._tooltipInstance?.handleBodyInteraction(this._platformSupportsMouseEvents() ? 0 : this._defaultOptions.touchendHideDelay));

        this._overlayRef
            .keydownEvents()
            .pipe(takeUntil(this._destroyed))
            .subscribe(event => {
                if (this._isTooltipVisible() && event.keyCode === ESCAPE) {
                    event.preventDefault();
                    event.stopPropagation();
                    this._ngZone.run(() => this.hide(0));
                }
            });

        if (this._defaultOptions?.disableTooltipInteractivity) {
            this._overlayRef.addPanelClass(`esc-tooltip-panel-non-interactive`);
        }

        return this._overlayRef;
    }

    private _detach() {
        if (this._overlayRef && this._overlayRef.hasAttached()) {
            this._overlayRef.detach();
        }

        this._tooltipInstance = undefined;
    }

    private _updateCurrentPositionClass(connectionPair: ConnectionPositionPair): void {
        const { overlayY, originX, originY } = connectionPair;
        let newPosition: TTooltipPosition;

        if (overlayY === 'center') {
            newPosition = originX === 'start' ? 'left' : 'right';
        } else {
            newPosition = overlayY === 'bottom' && originY === 'top' ? 'above' : 'below';
        }

        const overlayRef = this._overlayRef;

        if (overlayRef) {
            const classPrefix = `esc-tooltip-panel-`;
            overlayRef.removePanelClass(classPrefix + this._currentPosition);
            overlayRef.addPanelClass(classPrefix + newPosition);
        }

        this._currentPosition = newPosition;
    }

    private _updatePosition(overlayRef: OverlayRef) {
        const position = overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
        const origin = this._getOrigin();
        const overlay = this._getOverlayPosition();

        position.withPositions([this._addOffset({ ...origin.main, ...overlay.main }), this._addOffset({ ...origin.fallback, ...overlay.fallback })]);
    }

    _getOrigin(): { main: OriginConnectionPosition; fallback: OriginConnectionPosition } {
        const isLtr = true;
        const position = this.position;
        let originPosition: OriginConnectionPosition;

        if (position == 'above' || position == 'below') {
            originPosition = { originX: 'center', originY: position == 'above' ? 'top' : 'bottom' };
        } else if (position == 'before' || (position == 'left' && isLtr) || (position == 'right' && !isLtr)) {
            originPosition = { originX: 'start', originY: 'center' };
        } else if (position == 'after' || (position == 'right' && isLtr) || (position == 'left' && !isLtr)) {
            originPosition = { originX: 'end', originY: 'center' };
        }

        const { x, y } = this._invertPosition(originPosition!.originX, originPosition!.originY);

        return {
            main: originPosition!,
            fallback: { originX: x, originY: y },
        };
    }

    /** Returns the overlay position and a fallback position based on the user's preference */
    _getOverlayPosition(): { main: OverlayConnectionPosition; fallback: OverlayConnectionPosition } {
        const isLtr = true;
        const position = this.position;
        let overlayPosition: OverlayConnectionPosition;

        if (position == 'above') {
            overlayPosition = { overlayX: 'center', overlayY: 'bottom' };
        } else if (position == 'below') {
            overlayPosition = { overlayX: 'center', overlayY: 'top' };
        } else if (position == 'before' || (position == 'left' && isLtr) || (position == 'right' && !isLtr)) {
            overlayPosition = { overlayX: 'end', overlayY: 'center' };
        } else if (position == 'after' || (position == 'right' && isLtr) || (position == 'left' && !isLtr)) {
            overlayPosition = { overlayX: 'start', overlayY: 'center' };
        }

        const { x, y } = this._invertPosition(overlayPosition!.overlayX, overlayPosition!.overlayY);

        return {
            main: overlayPosition!,
            fallback: { overlayX: x, overlayY: y },
        };
    }

    private _invertPosition(x: HorizontalConnectionPos, y: VerticalConnectionPos) {
        if (this.position === 'above' || this.position === 'below') {
            if (y === 'top') {
                y = 'bottom';
            } else if (y === 'bottom') {
                y = 'top';
            }
        } else {
            if (x === 'end') {
                x = 'start';
            } else if (x === 'start') {
                x = 'end';
            }
        }

        return { x, y };
    }

    private _addOffset(position: ConnectedPosition): ConnectedPosition {
        const offset = UNBOUNDED_ANCHOR_GAP;
        const isLtr = true;

        if (position.originY === 'top') {
            position.offsetY = -offset;
        } else if (position.originY === 'bottom') {
            position.offsetY = offset;
        } else if (position.originX === 'start') {
            position.offsetX = isLtr ? -offset : offset;
        } else if (position.originX === 'end') {
            position.offsetX = isLtr ? offset : -offset;
        }

        return position;
    }

    private _updateTooltipMessage() {
        // Must wait for the message to be painted to the tooltip so that the overlay can properly
        // calculate the correct positioning based on the size of the text.
        if (this._tooltipInstance) {
            if (this.message) {
                this._tooltipInstance.message = this.sanitizer.bypassSecurityTrustHtml(this.message);
            }
            this._tooltipInstance.markForCheck();

            this._ngZone.onMicrotaskEmpty.pipe(take(1), takeUntil(this._destroyed)).subscribe(() => {
                if (this._tooltipInstance) {
                    this._overlayRef!.updatePosition();
                }
            });
        }
    }

    private _setTooltipClass(tooltipClass: string | string[] | Set<string> | { [key: string]: unknown }) {
        if (this._tooltipInstance) {
            this._tooltipInstance.tooltipClass = tooltipClass;
            this._tooltipInstance.markForCheck();
        }
    }
}
