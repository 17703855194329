import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'esc-validation-text',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './validation-text.component.html',
    styleUrl: './validation-text.component.scss',
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class]': '"esc-validation-text is-" + type',
    },
})
export class ValidationTextComponent {
    @Input() public type: 'error' | 'success' | 'default' = 'default';
}
