import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EscDataSource } from '../../../data-and-collections/classes/data-source/data-source';

@Component({
    selector: 'esc-header-cell-action-sort',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './header-cell-action-sort.component.html',
    styleUrl: './header-cell-action-sort.component.scss',
})
export class HeaderCellActionSortComponent<T> {
    @Input({ required: true }) public dataSource!: EscDataSource<T>;
    @Input({ required: true }) public key!: string;

    public get isCurrentSortBy(): boolean {
        return this.dataSource ? this.dataSource.currentSortValue.name === this.key : false;
    }

    public get direction(): 'ASC' | 'DESC' | null {
        return this.dataSource && this.isCurrentSortBy ? this.dataSource.currentSortValue.direction : null;
    }

    public onSortButtonClick(): void {
        this.sort();
    }

    public sort(): void {
        if (this.dataSource && this.key) {
            this.dataSource.sort(this.key);
        }
    }
}
