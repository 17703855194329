import { Directive } from '@angular/core';
import { CdkFooterRowDef } from '@angular/cdk/table';

@Directive({
    selector: '[escFooterRowDef]',
    providers: [{ provide: CdkFooterRowDef, useExisting: FooterRowDefDirective }],
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ['columns: escFooterRowDef', 'sticky: escFooterRowDefSticky'],
    standalone: true,
})
export class FooterRowDefDirective extends CdkFooterRowDef {}
