import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgStyle } from '@angular/common';

/**
 * @deprecated Use `esc-icon` component
 */
@Component({
    selector: 'es-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgStyle, InlineSVGModule],
})
export class IconComponent {
    static icons: string[] = [];
    public get pathUrl(): string {
        let addonPath: string = '';

        switch (this.type) {
            case 'color':
                addonPath = 'colored/';
                break;

            default:
                break;
        }

        return 'assets/icons/' + addonPath;
    }

    @Input() public icon: string;
    @Input() public width: number;
    @Input() public height: number;
    @Input() public fill: string;
    @Input() public hoverFill: string;
    @Input() public type: 'color' = null;

    @HostBinding('class.is-full')
    @Input()
    public fullWidth: boolean = false;

    constructor() {
        IconComponent.icons.push(this.icon);
    }
}
