import { booleanAttribute, ChangeDetectorRef, Directive, effect, inject, input, InputSignalWithTransform } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    standalone: true,
    host: {
        '[class.is-disabled]': 'escDisabled()',
        '[attr.aria-disabled]': 'escDisabled()',
        '[attr.tabindex]': 'escDisabled() ? -1 : 0',
        '[attr.disabled]': 'null',
    },
})
export class DisabledStateDirective {
    //
    private _ngControl = inject(NgControl, { optional: true, self: true });
    private _cd = inject(ChangeDetectorRef);

    public escDisabled: InputSignalWithTransform<boolean, unknown> = input(false, { transform: booleanAttribute });

    constructor() {
        effect(() => {
            if (this.escDisabled()) {
                if (this._ngControl) {
                    this._ngControl.control?.disable();
                }
            } else {
                if (this._ngControl) {
                    this._ngControl.control?.enable();
                }
            }

            this._cd.detectChanges();
        });
    }
}
