import { ComponentRef } from '@angular/core';
import { lastValueFrom, Observable, Subject, takeUntil } from 'rxjs';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { IModalConfig } from './modal-config';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

let uniqueId = 0;

export class EscModalRef<T = unknown, R = unknown> {
    public componentInstance!: T;
    public componentRef!: ComponentRef<T> | null;
    protected id: string;

    readonly backdropClick: Observable<MouseEvent>;
    readonly keydownEvents: Observable<KeyboardEvent>;
    private readonly _afterClosedSubject = new Subject<R>();

    constructor(
        readonly overlayRef: OverlayRef,
        public readonly modalConfig: IModalConfig,
        private readonly _router: Router,
        private _overlay: Overlay
    ) {
        this.id = `esc-modal-ref-${uniqueId++}`;

        this.backdropClick = overlayRef.backdropClick();
        this.keydownEvents = overlayRef.keydownEvents();

        this._router.events
            .pipe(
                takeUntil(this._afterClosedSubject),
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                if (this.modalConfig.closeOnNavigation) {
                    this.close(undefined);
                }
                this.overlayRef.updateScrollStrategy(this._overlay.scrollStrategies.noop());
                this.overlayRef.updateScrollStrategy(this.modalConfig.scrollStrategy);
            });

        this.keydownEvents.pipe(takeUntil(this._afterClosedSubject)).subscribe(event => {
            if (event.keyCode === ESCAPE && this.modalConfig.closeOnEscapeKeydown && !hasModifierKey(event)) {
                event.preventDefault();
                this.close(undefined);
            }
        });

        this.backdropClick.pipe(takeUntil(this._afterClosedSubject)).subscribe(() => {
            if (this.modalConfig.closeOnBackdropClick) {
                this.close(undefined);
            }
        });

        overlayRef
            .detachments()
            .pipe(takeUntil(this._afterClosedSubject))
            .subscribe(() => {
                // Check specifically for `false`, because we want `undefined` to be treated like `true`.
                if (modalConfig.closeOnDispose === true) {
                    this.close(undefined);
                }
            });
    }

    public updatePosition(): this {
        this.overlayRef.updatePosition();
        return this;
    }

    /**
     * Updates the dialog's width and height.
     * @param width New width of the dialog.
     * @param height New height of the dialog.
     */
    public updateSize(width: string | number = '', height: string | number = ''): this {
        this.overlayRef.updateSize({ width, height });
        return this;
    }

    public close(result?: R | undefined): void {
        this._afterClosedSubject.next(result!);
        this._afterClosedSubject.complete();

        this.overlayRef.dispose();
    }

    public async result(): Promise<R> {
        return lastValueFrom(this._afterClosedSubject);
    }
}
