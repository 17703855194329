import { EscDataSource } from './data-source/data-source';
import { Observable, ReplaySubject } from 'rxjs';
import { computed, Signal, signal, WritableSignal } from '@angular/core';
import { isArray, isObject } from 'lodash';

export class EscSelectionModel<T> {
    private _selectionMap = new Map<T[keyof T] | T, T>();
    private _selectedArray: WritableSignal<T[]> = signal([]);

    //
    public selected: Signal<T[]> = this._selectedArray.asReadonly();
    public singleSelected: Signal<T> = computed(() => {
        return this._selectedArray()[0];
    });

    public selectedInAvailableCollection: Signal<T[]> = computed(() => {
        if (this.availableCollection) {
            const available = this.availableCollection();
            if (available) {
                return this._selectedArray().filter(s => available.some(c => this.compareSingle(s, c)));
            }
        }

        return this._selectedArray();
    });

    public allSelected: Signal<boolean> = computed(() => {
        if (this.availableCollection) {
            return this.compare(this.availableCollection(), this.selected());
        }

        return false;
    });

    constructor(
        private _trackByVariable: keyof T,
        public multiple: Signal<boolean> = signal(false),
        public readonly availableCollection?: Signal<T[]>
    ) {
        if (!this._trackByVariable) {
            this._trackByVariable = 'id' as keyof T;
        }
    }

    public setTrackByVariable(trackByVariable: keyof T): void {
        this._trackByVariable = trackByVariable;
    }

    public setSelection(selection: T[] | T) {
        if (!isArray(selection)) {
            selection = [selection];
        }

        if (!this.compare(selection, this.selected())) {
            this._selectionMap.clear();

            if (selection) {
                for (const v of selection) {
                    if (!v) {
                        continue;
                    }

                    if (!this.multiple()) {
                        this._selectionMap.clear();
                    }

                    this._selectionMap.set(v[this._trackByVariable] || v, v);
                }
            }

            this._selectedArray.set(Array.from(this._selectionMap.values()));
        }
    }

    public select(item: T): void {
        if (!this.isSelected(item)) {
            if (!this.multiple()) {
                this._selectionMap.clear();
            }

            this._selectionMap.set(item[this._trackByVariable] || item, item);
            this._selectedArray.set(Array.from(this._selectionMap.values()));
        }
    }

    public deselect(item: T): void {
        this._selectionMap.delete(item[this._trackByVariable] || item);
        this._selectedArray.set(Array.from(this._selectionMap.values()));
    }

    public toggle(value: T, shouldSelect?: boolean): void {
        if (shouldSelect === undefined) {
            shouldSelect = !this.isSelected(value);
        }
        if (shouldSelect) {
            this.select(value);
        } else {
            this.deselect(value);
        }
    }

    public clear(): void {
        this._selectionMap.clear();
        this._selectedArray.set([]);
    }

    public isSelected(item: T): boolean {
        return this._selectionMap.has(item[this._trackByVariable] || item);
    }

    public getSumOfVariable(variable: keyof T): number {
        return this._selectedArray()
            .map(c => c[variable])
            .reduce((a: number, b: T[keyof T]) => {
                return a + parseFloat(b as string);
            }, 0);
    }

    public compareSingle(a: T, b: T): boolean {
        if (!a || !b) {
            return a == b;
        }

        if (isObject(a) && isObject(b)) {
            return a[this._trackByVariable] === b[this._trackByVariable];
        } else {
            return a === b;
        }
    }

    public compare(a: T[] | T | undefined, b: T[] | undefined): boolean {
        if (!a || !b) {
            return a == b;
        }

        if (!isArray(a)) {
            a = [a];
        }

        if (a.length !== b.length) {
            return false;
        }

        for (const aItem of a) {
            if (!b.some(bItem => (bItem[this._trackByVariable] || bItem) === (aItem || aItem[this._trackByVariable]))) {
                return false;
            }
        }

        return true;
    }
}

export class EscSelectionModelDeprecated<T> {
    private _selection = new Map<T[keyof T], T>();
    private _cachedCollection: T[] | undefined;
    private _cachedAllCollection: T[] | undefined;
    private _availableCollection: T[] | undefined;
    private _changeSubject: ReplaySubject<ISelectionChangeEvent<T>> = new ReplaySubject<ISelectionChangeEvent<T>>(1);
    private _isSelectedAll: boolean | undefined;
    private _disableFunction: ((item: T) => boolean) | undefined;
    private _disableMessageFunction: ((item: T) => string | undefined) | undefined;

    public get selection(): T[] {
        if (!this._cachedCollection) {
            this._cachedCollection = Array.from(this._selection.values());
            this._cachedAllCollection = Array.from(this._selection.values());

            if (this._availableCollection) {
                this._cachedCollection = this._cachedCollection.filter(a => {
                    return this._availableCollection?.some(b => a[this._uniqueVariable] === b[this._uniqueVariable]);
                });
            }

            if (this._disableFunction) {
                this._cachedCollection = this._cachedCollection.filter(a => {
                    if (this.disableFunc) return !this.disableFunc(a);
                    return true;
                });
            }
        }

        return this._cachedCollection;
    }

    public set selection(value: T[]) {
        this.clear();

        if (value) {
            for (const v of value) {
                this._selection.set(v[this._uniqueVariable], v);
            }
        }

        this._cachedCollection = undefined;
        this._emitChange();
    }

    public set disableFunc(value: (item: T) => boolean) {
        this._disableFunction = value;

        this._cachedCollection = undefined;
    }

    public get disableFunc(): ((item: T) => boolean) | undefined {
        return this._disableFunction;
    }

    public set disableMessageFunc(value: (item: T) => string | undefined) {
        this._disableMessageFunction = value;
    }

    public get disableMessageFunc(): ((item: T) => string | undefined) | undefined {
        return this._disableMessageFunction;
    }

    public getSumOfVariable(variable: keyof T): number {
        return this.selection
            .map(c => c[variable])
            .reduce((a: number, b: T[keyof T]) => {
                return a + parseFloat(b as string);
            }, 0);
    }

    public getSumByFunc(func: (i: T) => number): number {
        return this.selection.reduce((a: number, b: T) => {
            return a + func(b);
        }, 0);
    }

    public get isSelectedAll(): boolean {
        if (this._isSelectedAll === undefined) {
            if (this._availableCollection) {
                this._isSelectedAll = this._availableCollection.length === this.selection.length;
            } else {
                this._isSelectedAll = true;
            }
        }

        return this._isSelectedAll;
    }

    public get changed(): Observable<ISelectionChangeEvent<T>> {
        return this._changeSubject.asObservable();
    }

    public get uniqueVariable(): keyof T {
        return this._uniqueVariable;
    }

    public get availableCollection(): T[] | undefined {
        return this._availableCollection;
    }

    public get availableCollectionLength(): number {
        return this._availableCollection?.length || 0;
    }

    constructor(
        public source: EscDataSource<T> | undefined,
        private readonly _uniqueVariable: keyof T,
        public multiple = true
    ) {
        if (!this._uniqueVariable) {
            this._uniqueVariable = 'id' as keyof T;
        }
        if (this.source) {
            // TODO: destroy subscription
            this.source.connect().subscribe(t => {
                let availableCollection: T[] = [];

                if (t) {
                    availableCollection = t.filter((_, i) => t.indexOf(_) === i);
                    if (this.disableFunc) {
                        availableCollection = availableCollection.filter(a => {
                            if (this.disableFunc) return !this.disableFunc(a);
                            return true;
                        });
                    }
                } else {
                    availableCollection = [];
                }

                if (!this._compareArray(availableCollection, this._availableCollection) && this.selection) {
                    this._availableCollection = availableCollection;

                    const cachedCollection = [...(this._cachedCollection || [])];
                    this._cachedCollection = undefined;

                    if (!this._compareArray(this.selection, cachedCollection)) {
                        this._emitChange();
                    }
                } else {
                    this._availableCollection = availableCollection;
                }
            });
        }
    }

    public select(value: T): void {
        if (!this.isSelected(value)) {
            this._selection.set(value[this._uniqueVariable], value);

            this._emitChange();
        }
    }

    public deselect(value: T): void {
        if (this.isSelected(value)) {
            this._selection.delete(value[this._uniqueVariable]);

            this._emitChange();
        }
    }

    public toggle(value: T, shouldSelect?: boolean): void {
        if (shouldSelect === undefined) {
            shouldSelect = !this.isSelected(value);
        }
        if (shouldSelect) {
            this.select(value);
        } else {
            this.deselect(value);
        }
    }

    public toggleAll(select?: boolean): void {
        if (this.isSelectedAll && select !== true) {
            this._selection.clear();
        } else {
            this._selection.clear();
            if (this._availableCollection) {
                for (const t of this._availableCollection) {
                    this._selection.set(t[this._uniqueVariable], t);
                }
            }
        }

        this._emitChange();
    }

    public clear(): void {
        this._selection.clear();
        this._emitChange();
    }

    public reverse() {
        if (this._availableCollection) {
            for (const t of this._availableCollection) {
                if (this._selection.has(t[this._uniqueVariable])) {
                    this._selection.delete(t[this._uniqueVariable]);
                } else {
                    this._selection.set(t[this._uniqueVariable], t);
                }
            }
        }

        this._emitChange();
    }

    public isSelected(value: T): boolean {
        return this._selection.has(value[this._uniqueVariable]);
    }

    public setSelection(...param: readonly T[]) {
        this._selection.clear();
        for (const p of param) {
            this.select(p);
        }

        this._emitChange();
    }

    private _emitChange(): void {
        this._cachedCollection = undefined;
        this._isSelectedAll = undefined;

        const collection = this.selection;
        this._changeSubject.next({
            collection,
            allCollection: this._cachedAllCollection || [],
            model: this,
        });
    }

    private _compareArray(a: T[] | undefined, b: T[] | undefined): boolean {
        if (!a || !b) {
            return a == b;
        }

        if (a.length !== b.length) {
            return false;
        }

        for (const aItem of a) {
            if (!b.some(bItem => bItem[this._uniqueVariable] === aItem[this._uniqueVariable])) {
                return false;
            }
        }

        return true;
    }
}

export interface ISelectionChangeEvent<T> {
    collection: T[];
    allCollection: T[];
    model: EscSelectionModelDeprecated<T>;
}
