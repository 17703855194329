import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { camelCase } from 'lodash';

const camelizeKeys = (obj: object): object => {
    if (Array.isArray(obj)) {
        return obj.map(v => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
        return Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: camelizeKeys((obj as any)[key]),
            }),
            {}
        );
    }
    return obj;
};

@Injectable()
export class SnakeToCamelInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request.clone({ body: request.body })).pipe(
            map(event => {
                if (event instanceof HttpResponse) {
                    return event.clone({
                        body: camelizeKeys(event.body),
                    });
                }

                return event;
            })
        );
    }
}
