import {
    AfterContentInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ContentChildren,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    QueryList,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalModule, TemplatePortal } from '@angular/cdk/portal';
import { startWith } from 'rxjs';
import { CommonModule, Location } from '@angular/common';
import { TabDirective } from './tab.directive';
import { TooltipDirective } from '../../../common/directives/tooltip.directive';

export interface ITabChangeEvent {
    tabId: string;
    tab: TabDirective;
    previousTab?: TabDirective;
}

@Component({
    selector: 'esc-tab-group',
    templateUrl: './tab-group.component.html',
    styleUrls: ['./tab-group.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
    standalone: true,
    imports: [CommonModule, TabDirective, PortalModule, TooltipDirective],
    host: {
        class: 'esc-tab-group',
        role: 'nav',
    },
})
export class TabGroupComponent implements AfterContentInit, OnDestroy {
    @ContentChildren(TabDirective, { descendants: true })
    private _allTabs!: QueryList<TabDirective>;
    @ViewChild('tabContent') private _tabContent!: ElementRef;

    protected tabs: QueryList<TabDirective> = new QueryList<TabDirective>();
    protected selectedTab!: TabDirective;
    protected selectedPortal!: TemplatePortal;

    protected get firstTab(): TabDirective | undefined {
        return this.tabs.find(a => !a.escDisabled);
    }

    //
    @Input() public tabGroupId!: string;
    @Input() public useParams = true;
    @Input() public initialTabId!: string;
    @Input() public canDeactivate: () => Promise<boolean> = async () => true;

    @Output() public tabChanged: EventEmitter<ITabChangeEvent> = new EventEmitter<ITabChangeEvent>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private location: Location,
        private cd: ChangeDetectorRef
    ) {}

    public ngAfterContentInit(): void {
        this._allTabs.changes.pipe(startWith(this._allTabs)).subscribe((tabs: QueryList<TabDirective>) => {
            this.tabs.reset(
                tabs.filter(() => {
                    return true; // todo
                })
            );
            this.tabs.notifyOnChanges();
        });

        let tabId: string | null = null;
        if (this.useParams && this.route.snapshot.queryParams['tab'] && this._getTabById(this.route.snapshot.queryParams['tab'])) {
            tabId = this.route.snapshot.queryParams['tab'];
        } else if (this.initialTabId) {
            tabId = this.initialTabId;
        } else if (this._getTabIdSnapshot()) {
            tabId = this._getTabIdSnapshot();
        }

        const tab = this._getTabById(tabId);

        if (tabId && tab && !tab.escDisabled) {
            this.setTab(tab);
        } else if (this.firstTab) {
            this.setTab(this.firstTab);
        }
    }

    public ngOnDestroy(): void {
        if (this.useParams) {
            this.location.replaceState(
                this.router
                    .createUrlTree(
                        [this.router.url.split('?')[0]], // Get uri
                        {
                            queryParams: { tab: null },
                            queryParamsHandling: 'merge',
                        } // Pass all parameters inside queryParamsObj
                    )
                    .toString()
            );
        }
    }

    protected handleClick(tab: TabDirective) {
        if (!tab.escDisabled) {
            this.setTab(tab);
        }
    }

    public setTabById(tab: string) {
        const c = this._getTabById(tab);
        if (c) {
            this.setTab(c);
        }
    }

    protected async setTab(tab: TabDirective) {
        if (!(await this.canDeactivate())) {
            return;
        }

        const previousTab = this.selectedTab;
        this._renderTab(tab);
        this._saveSnapshot();

        this.tabChanged.emit({
            previousTab,
            tab,
            tabId: tab.tabId,
        });
    }

    private _renderTab(tab: TabDirective): void {
        this.selectedTab = tab;
        this.selectedPortal = tab.content;

        this.cd.detectChanges();

        if (this.useParams) {
            this.router.navigate([], {
                queryParams: { tab: tab.tabId },
                replaceUrl: true,
                queryParamsHandling: 'merge',
            });
        }
    }

    private _getTabById(id: string | null): TabDirective | undefined {
        return this.tabs.find(t => t.tabId === id);
    }

    private _getTabIdSnapshot(): string | null {
        const snapshot = TabGroupComponent._getTabGroupsSnapshots();

        if (!snapshot || !this.tabGroupId) {
            return null;
        }

        return snapshot[this.tabGroupId] || null;
    }

    private _saveSnapshot(): void {
        if (this.tabGroupId) {
            const snapshot = TabGroupComponent._getTabGroupsSnapshots() || {};

            if (this.selectedTab) {
                snapshot[this.tabGroupId] = this.selectedTab.tabId;
                localStorage.setItem('esc.tabGroupsSnapshots', JSON.stringify(snapshot));
            }
        }
    }

    private static _getTabGroupsSnapshots(): { [key: string]: string } | null {
        const tabsSnapshotValue = localStorage.getItem('esc.tabGroupsSnapshots');
        if (!tabsSnapshotValue) {
            return null;
        }

        return JSON.parse(tabsSnapshotValue);
    }
}
