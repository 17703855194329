import { Component, computed, Directive, Input, input, InputSignal, Signal, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormActionsService } from '../../services/form-actions.service';
import { EscModalRef } from '../../../modal/classes/modal-ref';
import { TooltipDirective } from '../../../common/directives/tooltip.directive';
import { EscModalContentDirective, EscModalFooterDirective, EscModalHeaderDirective } from '../../../modal/directives/modal-directives';
import { animate, style, transition, trigger } from '@angular/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'esc-form-in-modal-content',
    host: {
        class: 'esc-form-in-modal__content',
    },
    standalone: true,
})
export class FormInModalContentDirective {}

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'esc-form-in-modal-header',
    host: {
        class: 'esc-form-in-modal__header',
    },
    standalone: true,
})
export class FormInModalHeaderDirective {}

const fadeAnimation = trigger('fadeAnimation', [
    transition(':enter', [style({ opacity: 0 }), animate('.2s', style({ opacity: 1 }))]),
    transition(':leave', [animate('.2s', style({ opacity: 0 }))]),
]);

@Component({
    selector: 'esc-form-in-modal',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, TooltipDirective, EscModalFooterDirective, EscModalHeaderDirective, EscModalContentDirective],
    templateUrl: './form-in-modal.component.html',
    styleUrl: './form-in-modal.component.scss',
    animations: [fadeAnimation],
    host: {
        class: 'esc-form-in-modal',
    },
    encapsulation: ViewEncapsulation.None,
})
export class FormInModalComponent {
    public closeAfterSuccess: InputSignal<boolean> = input(true);
    public disabled: InputSignal<boolean> = input(false);
    public disabledTooltip: InputSignal<string> = input('');
    public submitText: InputSignal<string> = input('Zapisz');
    loaderText = input('');

    public buttonDisabled: Signal<boolean> = computed(() => {
        return this.disabled() || this.formActions.formState() === 'loading' || this.formActions.formState() === 'success';
    });

    @Input() public formGroup!: FormGroup;

    constructor(
        public formActions: FormActionsService,
        private _modalRef: EscModalRef
    ) {
        this.formActions.submitSuccess$.pipe(takeUntilDestroyed()).subscribe(() => {
            setTimeout(() => {
                if (this.closeAfterSuccess()) {
                    this._modalRef.close(true);
                }
            });
        });
    }

    public close(): void {
        this._modalRef.close();
    }
}
