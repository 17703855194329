import { Component, Host, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccordionComponent } from '../accordion.component';
import { ENTER, hasModifierKey, SPACE } from '@angular/cdk/keycodes';

@Component({
    selector: 'esc-accordion-header',
    standalone: true,
    imports: [CommonModule],
    encapsulation: ViewEncapsulation.None,
    templateUrl: './accordion-header.component.html',
    styleUrl: './accordion-header.component.scss',

    host: {
        class: 'esc-accordion-header',
        role: 'button',
        '[attr.id]': 'accordion.headerId',
        '[attr.tabindex]': 'disabled ? -1 : 0',
        '[attr.aria-controls]': 'accordionId',
        '[attr.aria-expanded]': 'expanded',
        '[attr.aria-disabled]': 'disabled',
        '[class.is-expanded]': 'expanded',
        '(click)': 'toggle()',
        '(keydown)': 'keydown($event)',
    },
})
export class AccordionHeaderComponent {
    get disabled(): boolean {
        return this.accordion.disabled;
    }

    get expanded(): boolean {
        return this.accordion.expanded();
    }

    get accordionId(): string {
        return this.accordion.id;
    }

    constructor(@Host() public readonly accordion: AccordionComponent) {}

    public toggle(): void {
        if (!this.disabled) {
            this.accordion.toggle();
        }
    }

    public keydown(event: KeyboardEvent) {
        switch (event.keyCode) {
            // Toggle for space and enter keys.
            case SPACE:
            case ENTER:
                if (!hasModifierKey(event)) {
                    event.preventDefault();
                    this.toggle();
                }

                break;
            default:
                return;
        }
    }
}
