<div class="esc-form-field-input-item is-select" #container>
    <div class="select-value">
        <div class="truncate">
            @if (selectionModel.singleSelected() || printEmpty()) {
                @if (printValueDirective(); as directive) {
                    <ng-container
                        *ngTemplateOutlet="
                            directive.template;
                            context: { $implicit: selectionModel.selected(), items: selectionModel.selected(), item: selectionModel.selected()[0] }
                        "></ng-container>
                } @else {
                    {{ printValue() }}
                }
            }
        </div>
    </div>
    <esc-icon icon="expand_more"></esc-icon>
</div>

<esc-overlay-panel [connectedTo]="container" #dropdown>
    <div class="esc-select-inner" #selectInner [style.width.px]="dynamicContentWidth() ? null : containerWidth()">
        <div class="esc-select-inner__content">
            @if (useSearch()) {
                <esc-form-field-input>
                    <input
                        escInput
                        type="text"
                        name="s"
                        autocomplete="off"
                        #searchInput
                        [ngModel]="searchValue()"
                        (ngModelChange)="searchValue.set($event)"
                        (keydown)="onSearchKeyup($event)" />
                    <esc-icon [icon]="'search'" fill escInputSuffix></esc-icon>
                </esc-form-field-input>
            }
        </div>
        <div class="esc-select-inner__options">
            <ng-content></ng-content>
        </div>
        <div class="esc-select-inner__bottom-options"></div>
    </div>
</esc-overlay-panel>
