<esc-select (valueChange)="onValueChange($event)" [multiple]="true" [printEmpty]="true" [dynamicContentWidth]="true" [useSearch]="false">
    <ng-template escSelectPrintValue let-items="items" [source]="filterItems">
        <div class="flex is-gap-small">
            <p class="text-body-small">{{ filterGroup.title }}</p>
            <div class="esc-counter-badge" [class.is-hidden]="items.length <= 0">
                {{ items.length }}
            </div>
        </div>
    </ng-template>

    @for (item of filterItems; track item.id) {
        <esc-option [value]="item">
            <div class="flex is-gap-tiny">
                <div [innerHTML]="item.title"></div>
                <div class="text-color-grey-500">({{ item.counter }})</div>
            </div>
        </esc-option>
    }
</esc-select>
